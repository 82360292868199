import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormLabel,
  Input,
  Box,
  FormErrorMessage,
  FormControl,
  Select,
  FormHelperText,
} from '@chakra-ui/react';
import { PrivilegeContainer } from '../generic/PrivilegeContainer';
import { Entity, Operation } from '../../hooks/roles';
import { Controller, useForm } from 'react-hook-form';
import { ProjectSeller } from '../../types';
import { useMetadataActions } from '../../hooks/use-metadata-actions';
import { useTranslation } from 'react-i18next';
import { usePlanLimitations } from '../../hooks/plans';

type TProjectSellersModal = {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: FormValues) => void;
  projectSeller: ProjectSeller | null | undefined;
};

export type FormValues = {
  name: string;
  language: string;
};

const ProjectSellersModal = ({ title, isOpen, onClose, onSubmit, projectSeller }: TProjectSellersModal) => {
  const { t } = useTranslation(['common', 'project']);
  const {
    register,
    formState: { errors },
    formState,
    handleSubmit,
    control,
  } = useForm<FormValues>({});

  console.log('form', formState);

  const planLimitations = usePlanLimitations();
  const localizationLimit = !planLimitations?.LOCALIZATIONS as boolean;

  const _onSubmit = (data: FormValues) => {
    if (!data.language || localizationLimit) {
      data.language = 'cs';
    }
    onSubmit(data);
    onClose();
  };

  const { config } = useMetadataActions();
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={handleSubmit(_onSubmit)}>
            <Box pb={10}>
              <FormControl isInvalid={!!errors.name} mb={2}>
                <FormLabel htmlFor="name">{t('common:name.label')}</FormLabel>
                <Input
                  defaultValue={projectSeller?.name}
                  {...register('name', { required: true })}
                  placeholder="Name"
                />
                <FormErrorMessage>{t('common:name.required')}</FormErrorMessage>
              </FormControl>

              <Controller
                control={control}
                name={'language'}
                render={({ field }) => (
                  <FormControl mb={2} isInvalid={!!errors.language}>
                    <FormLabel htmlFor="language">{t('common:language.label')}</FormLabel>
                    <Select
                      defaultValue={projectSeller?.language || 'cs'}
                      {...field}
                      disabled={localizationLimit}
                      placeholder={
                        localizationLimit
                          ? t('common:language.select.limit')
                          : t('common:language.select.placeholder') /*"Select language"*/
                      }
                    >
                      {config?.languages.map((language: { name: string; code: string }) => (
                        <option key={language.code} value={language.code}>
                          {language.name}
                        </option>
                      ))}
                    </Select>
                    {localizationLimit && <FormHelperText>{t('common:language.help-text')}</FormHelperText>}
                    <FormErrorMessage>{t('common:language.required')}</FormErrorMessage>
                  </FormControl>
                )}
              />
            </Box>

            <ModalFooter>
              <Button variant="ghost" mr={3} onClick={onClose}>
                {t('common:close.label') /*"Close"*/}
              </Button>
              <PrivilegeContainer entity={Entity.PROJECTS} operation={Operation.UPDATE}>
                <Button type="submit" colorScheme="blue">
                  {t('common:save.label') /*"Save"*/}
                </Button>
              </PrivilegeContainer>
            </ModalFooter>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ProjectSellersModal;
