import { Flex, Heading, Box, Text, Link } from '@chakra-ui/react';
import { useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { SignUpForm } from '../components/forms';
import { SoftCard } from '../components/generic/SoftCard';

import { DefaultLayout } from '../layouts';
import { useAuthContext } from '../providers';
import { useTranslation } from 'react-i18next';

export const SignUpPage: React.FC = () => {
  const { t } = useTranslation(['unauth', 'common']);
  const { signUpWithEmailAndPassword } = useAuthContext();
  const navigate = useNavigate();
  const { user } = useAuthContext();

  useEffect(() => {
    if (user) {
      navigate('/');
    }
  }, [user, navigate]);

  const onSubmit = async ({
    email,
    password,
    firstName,
    lastName,
  }: {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
  }) => {
    await signUpWithEmailAndPassword({ email, password, firstName, lastName });
  };

  return (
    <DefaultLayout displayLogo>
      <Flex width="100%" alignSelf="center" flexGrow={1} justifyContent="center" alignItems="center">
        <Box w={460}>
          <SoftCard>
            <Flex alignItems="center" flexDir="column">
              <Heading mb={2} size="md" paddingBottom={4}>
                {t('common:sign-up.label')}
              </Heading>

              <Flex paddingTop={4} paddingBottom={4} flexDir="row" width="100%" alignItems="center" />

              <Box w="100%">
                <SignUpForm onSubmit={onSubmit} />
              </Box>

              <Box mt={5}>
                <Text fontSize="sm">
                  {t('unauth:already-have-account')}{' '}
                  <Link textDecoration="underline" textUnderlineOffset={1.5} to="/sign-in" as={RouterLink}>
                    {t('common:sign-in.label')} {/* Sign In */}
                  </Link>
                </Text>
              </Box>
            </Flex>
          </SoftCard>
        </Box>
      </Flex>
    </DefaultLayout>
  );
};
