import { Table, Tbody, Tr, Td, TableContainer } from '@chakra-ui/react';
import { Insurance } from '../../../types/shipment';
import { useTranslation } from 'react-i18next';

type InsuranceSectionProps = {
  data: Insurance;
};

export const InsuranceSection: React.FC<InsuranceSectionProps> = ({ data }) => {
  /** Tabs view of parcels with basic data and tracking */
  const { t } = useTranslation(['shipment', 'common']);

  const { value, currency } = data;
  return (
    <TableContainer>
      <Table size="sm">
        <Tbody>
          <Tr>
            <Td width="10%">{t('common:amount.label')}</Td>
            <Td>
              {value} {currency}
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </TableContainer>
  );
};
