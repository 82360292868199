import { Flex, Box, Text, useToast, Link } from '@chakra-ui/react';
import { PasswordAuthenticationForm } from '../components/forms';
import { LinkWithQuery } from '../components/generic/LinkWithQuery';
import { SoftCard } from '../components/generic/SoftCard';

import { DefaultLayout } from '../layouts';
import { useAppConfigContext, useAuthContext } from '../providers';
import { useTranslation } from 'react-i18next';

export const SignInPage: React.FC = () => {
  const { signInWithEmailAndPassword } = useAuthContext();
  const toast = useToast();
  const { config } = useAppConfigContext();
  const { t } = useTranslation('unauth');
  const onSignIn = async ({ email, password }: { email: string; password: string }) => {
    try {
      await signInWithEmailAndPassword({ email, password });
    } catch (err: any) {
      toast({
        title: t('error.failed-signin'), // 'Failed to sign in',
        description: err.message,
        status: 'error',
      });
    }
  };

  return (
    <DefaultLayout displayLogo>
      <Flex width="100%" alignSelf="center" flexGrow={1} justifyContent="center" alignItems="center">
        <Box w={460}>
          <SoftCard>
            <Flex alignItems="center" flexDir="column">
              <Box w="100%">
                <PasswordAuthenticationForm onSubmit={onSignIn} />
              </Box>

              <Box mt={5}>
                <Text fontSize="sm">
                  {t('new-to', { public_name: config.public_name })}{' '}
                  <Link textDecoration="underline" textUnderlineOffset={1.5} to="/sign-up" as={LinkWithQuery}>
                    {t('create-account')} {/* Create account */}
                  </Link>
                </Text>
              </Box>
            </Flex>
          </SoftCard>
        </Box>
      </Flex>
    </DefaultLayout>
  );
};
