import { useCallback } from 'react';
import { ProjectSeller } from '../types';
import { useApiActions } from './api-actions';
import { useProjectsContext } from '../providers';

type CreateProjectSellerResponse = {
  seller: ProjectSeller;
};

type UpdateProjecSellertResponse = {
  seller: ProjectSeller;
};

export const useProjectSellersActions = () => {
  const { executeApiAction } = useApiActions();
  const { selectedProject } = useProjectsContext();

  const createProjectSeller = async ({ name, language }: { name: string; language: string }) =>
    await executeApiAction({
      action: async ({ client }) =>
        (
          await client
            .post(`projects/${selectedProject?.id}/sellers/`, { json: { name, language } })
            .json<CreateProjectSellerResponse>()
        ).seller,
      errorMessage: 'Failed to create project seller',
      successMessage: 'Project seller created',
    });

  const updateProjectSeller = async ({ id, name, language, customLayout }: Partial<ProjectSeller>) =>
    await executeApiAction({
      action: ({ client }) =>
        client
          .put(`projects/${selectedProject?.id}/sellers/${id}`, { json: { name, language, customLayout } })
          .json<UpdateProjecSellertResponse>(),
      errorMessage: 'Failed to update project seller',
      successMessage: 'Project seller saved',
    });

  const deleteProjectSeller = async (id: string) =>
    await executeApiAction({
      action: ({ client }) => client.delete(`projects/${selectedProject?.id}/sellers/${id}`),
      errorMessage: 'Failed to delete project seller',
      successMessage: 'Project seller deleted',
    });

  return {
    createProjectSeller: useCallback(createProjectSeller, [executeApiAction, selectedProject]),
    updateProjectSeller: useCallback(updateProjectSeller, [executeApiAction, selectedProject]),
    deleteProjectSeller: useCallback(deleteProjectSeller, [executeApiAction, selectedProject]),
  };
};
