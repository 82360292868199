import { InputGroup, IconButton } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { MdAdd } from 'react-icons/md';
import { Entity, Operation } from '../../hooks/roles';
import { PrivilegeContainer } from '../generic/PrivilegeContainer';

interface FormProps {
  projectId: string;
}

type CreateProjectSellersProps = {
  onSubmit: (data: FormProps) => void;
};

export const CreateProjectSellers: React.FC<CreateProjectSellersProps> = ({ onSubmit }) => {
  const { handleSubmit } = useForm<FormProps>();
  return (
    <PrivilegeContainer entity={Entity.PROJECT_USERS} operation={Operation.CREATE}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputGroup size="md">
          <IconButton aria-label="Create project sellers" icon={<MdAdd />} px={6} size="md" type="submit" />
        </InputGroup>
      </form>
    </PrivilegeContainer>
  );
};
