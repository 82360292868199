import _ from 'lodash';
import { Input, FormControl, FormLabel, Button, Select } from '@chakra-ui/react';
import { Controller, useFormContext, WatchObserver } from 'react-hook-form';
import { FormCard } from './FormCard';
import { FormNestedCardModal } from './FormNestedCardModal';
import { usePrivileges } from '../../../../hooks/roles';
import { FormCommodityValues, FormShipmentValues } from '../../../../pages/project-shipment-detail-edit';
import { useMetadataActions } from '../../../../hooks/use-metadata-actions';
import { useTranslation } from 'react-i18next';

export const CustomsSection = () => {
  const { t } = useTranslation(['shipment', 'common']);
  const privileges = usePrivileges();
  const {
    setValue,
    watch,
    getValues,
    register,
    control,
    formState: { errors },
  } = useFormContext<FormShipmentValues>();
  const { config } = useMetadataActions();
  console.log('config', config);
  return (
    <FormCard heading={t('shipment:customs.label') /* Customs */} collapsible defaultOpen={!!!watch('customs')}>
      <FormControl mb={2}>
        <FormLabel>{t('shipment:customs.description.label') /* Description */}</FormLabel>
        <Input
          disabled={!privileges.PROJECT_SHIPMENTS.UPDATE}
          {...register('customs.description', { required: false })}
          placeholder={t('shipment:customs.description.label') /* Description */}
        />
      </FormControl>
      <FormControl mb={2}>
        <FormLabel>{t('shipment:customs.total-value.label') /* Total value */}</FormLabel>
        <Input
          disabled={!privileges.PROJECT_SHIPMENTS.UPDATE}
          {...register('customs.totalValue', { required: false })}
          placeholder={t('shipment:customs.total-value.label') /* Total value */}
        />
      </FormControl>

      <Controller
        control={control}
        name={'customs.currency'}
        render={({ field }) => (
          <FormControl mb={6}>
            <FormLabel>{t('common:currency.label') /* Currency */}</FormLabel>
            <Select {...field} placeholder={t('common:currency.placeholder') /* Select currency */}>
              {config?.currencies.map((currency: { name: string; code: string }) => (
                <option key={currency.code} value={currency.code}>
                  {currency.name}
                </option>
              ))}
            </Select>
          </FormControl>
        )}
      />
      {watch('customs.commodities')?.map((commodity, index) => (
        <FormNestedCardModal
          heading={`${watch(`customs.commodities.${index}.sku`)}`}
          key={index}
          name={`customs.commodities.${index}` as unknown as WatchObserver<FormShipmentValues>}
          errors={errors?.customs?.commodities?.[index]}
          onRemove={async () => {
            const currentCommodities = getValues('customs.commodities');
            const updatedCommodities = currentCommodities?.filter((_, i) => i !== index);
            setValue('customs.commodities', updatedCommodities);
          }}
        >
          <FormControl
            mb={2}
            isInvalid={errors?.customs?.commodities ? !!errors.customs.commodities[index]?.sku : false}
          >
            <FormLabel>{t('shipment:customs.commodities.sku.label') /* SKU */}</FormLabel>
            <Input
              disabled={!privileges.PROJECT_SHIPMENTS.UPDATE}
              {...register(`customs.commodities.${index}.sku`, { required: true })}
              placeholder={t('shipment:customs.commodities.sku.label') /* SKU */}
            />
          </FormControl>
          <FormControl
            mb={2}
            isInvalid={errors?.customs?.commodities ? !!errors.customs.commodities[index]?.title : false}
          >
            {' '}
            <FormLabel>{t('shipment:customs.commodities.title.label') /* Title */}</FormLabel>
            <Input
              disabled={!privileges.PROJECT_SHIPMENTS.UPDATE}
              {...register(`customs.commodities.${index}.title`, { required: true })}
              placeholder={t('shipment:customs.commodities.title.label') /* Title */}
            />
          </FormControl>
          <FormControl
            mb={2}
            isInvalid={errors?.customs?.commodities ? !!errors.customs.commodities[index]?.quantity : false}
          >
            {' '}
            <FormLabel>{t('shipment:customs.commodities.quantity.label') /* Quantity */}</FormLabel>
            <Input
              disabled={!privileges.PROJECT_SHIPMENTS.UPDATE}
              {...register(`customs.commodities.${index}.quantity`, { required: true })}
              placeholder={t('shipment:customs.commodities.quantity.label') /* Quantity */}
            />
          </FormControl>
          <FormControl
            mb={2}
            isInvalid={errors?.customs?.commodities ? !!errors.customs.commodities[index]?.hsCode : false}
          >
            {' '}
            <FormLabel>{t('shipment:customs.commodities.hs-code.label') /* HS code */}</FormLabel>
            <Input
              disabled={!privileges.PROJECT_SHIPMENTS.UPDATE}
              {...register(`customs.commodities.${index}.hsCode`, { required: true })}
              placeholder={t('shipment:customs.commodities.hs-code.label') /* HS code */}
            />
          </FormControl>
          <FormControl
            mb={2}
            isInvalid={errors?.customs?.commodities ? !!errors.customs.commodities[index]?.weight : false}
          >
            <FormLabel>{t('shipment:customs.commodities.weight.label') /* Weight */}</FormLabel>
            <Input
              disabled={!privileges.PROJECT_SHIPMENTS.UPDATE}
              {...register(`customs.commodities.${index}.weight`, { required: true })}
              placeholder={t('shipment:customs.commodities.weight.label') /* Weight */}
            />
          </FormControl>
          <FormControl
            mb={2}
            isInvalid={errors?.customs?.commodities ? !!errors.customs.commodities[index]?.originCountry : false}
          >
            {' '}
            <FormLabel>{t('shipment:customs.commodities.origin-country.label') /* Origin country */}</FormLabel>
            <Input
              disabled={!privileges.PROJECT_SHIPMENTS.UPDATE}
              {...register(`customs.commodities.${index}.originCountry`, { required: true })}
              placeholder={t('shipment:customs.commodities.origin-country.label') /* Origin country */}
            />
          </FormControl>
          <FormControl
            mb={2}
            isInvalid={errors?.customs?.commodities ? !!errors.customs.commodities[index]?.value : false}
          >
            {' '}
            <FormLabel>{t('shipment:customs.commodities.value.label') /*Value */}</FormLabel>
            <Input
              disabled={!privileges.PROJECT_SHIPMENTS.UPDATE}
              {...register(`customs.commodities.${index}.value`, { required: true })}
              placeholder={t('shipment:customs.commodities.value.label') /*Value */}
            />
          </FormControl>

          <Controller
            control={control}
            name={`customs.commodities.${index}.currency`}
            render={({ field }) => (
              <FormControl
                mb={2}
                isInvalid={errors?.customs?.commodities ? !!errors.customs.commodities[index]?.currency : false}
              >
                <FormLabel>{t('common:currency.label') /*Currency */}</FormLabel>
                <Select
                  disabled={!privileges.PROJECT_SHIPMENTS.UPDATE}
                  {...field}
                  placeholder={t('common:currency.placeholder') /*Select currency */}
                >
                  {config?.currencies.map((currency: { name: string; code: string }) => (
                    <option key={currency.code} value={currency.code}>
                      {currency.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </FormNestedCardModal>
      ))}

      <Button
        onClick={() => {
          // setValue(`customs.commodities.${getValues('customs.commodities')?.length || 0}`, {} as FormCommodityValues)
          const currentCommodities = getValues('customs.commodities');
          const emptyCommoditiy = {} as FormCommodityValues;
          if (_.isEmpty(currentCommodities)) {
            setValue('customs.commodities', [emptyCommoditiy]);
            return;
          }
          const updatedCommodities = [...currentCommodities, emptyCommoditiy];
          setValue('customs.commodities', updatedCommodities);
        }}
      >
        {t('shipment:customs.commodities.add-commodity.label') /*Add commodity */}
      </Button>
    </FormCard>
  );
};
