import React from 'react';
import { Input, Button, Text } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';

import { AuthenticatedLayout } from '../layouts';
import { MdAdd, MdChevronRight } from 'react-icons/md';
import { PageHeading } from '../components/generic/PageHeading';
import { SoftCard } from '../components/generic/SoftCard';
import { useProjectActions } from '../hooks';
import { useTranslation } from 'react-i18next';

type FormValues = {
  name: string;
};

export const CreateProjectPage: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();
  const { t } = useTranslation(['common', 'project']);
  const { createProject } = useProjectActions();
  const onSubmit = async ({ name }: { name: string }) => {
    await createProject({ name });
  };

  return (
    <AuthenticatedLayout sidebar={false}>
      <PageHeading title={t('project:create-project.label') /*"Create Project"*/} icon={MdAdd} />
      <SoftCard>
        <Text fontSize="lg" mb={4}>
          {t('project:create-project.description')}
        </Text>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            size={'lg'}
            placeholder={t('project:create-project.placeholder')}
            /*"Enter your project name"*/ {...register('name', { required: true })}
          />
          {errors?.name && <p>{t('common:name.required')}</p>}
          <Button size="lg" rightIcon={<MdChevronRight />} type="submit" mt={4}>
            {t('common:continue.label')}
          </Button>
        </form>
      </SoftCard>
    </AuthenticatedLayout>
  );
};
