import { InputGroup, IconButton } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { LuPackagePlus } from 'react-icons/lu';
import { Entity, Operation } from '../../hooks/roles';
import { PrivilegeContainer } from '../generic/PrivilegeContainer';

interface FormProps {
  projectId: string;
}

type CreateProjectShipmentButtonProps = {
  onSubmit: () => void;
};

export const CreateProjectShipmentButton: React.FC<CreateProjectShipmentButtonProps> = ({ onSubmit }) => {
  const { handleSubmit } = useForm<FormProps>();
  return (
    <PrivilegeContainer entity={Entity.PROJECT_SHIPMENTS} operation={Operation.CREATE}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputGroup size="md">
          <IconButton aria-label="Create Project Shipment" icon={<LuPackagePlus />} px={6} size="md" type="submit" />
        </InputGroup>
      </form>
    </PrivilegeContainer>
  );
};
