import { useCallback } from 'react';
import { Shipment } from '../types/shipment';
import { useApiActions } from './api-actions';
import { FilterOpearator } from '../components/project-shipments/list/ThFilter';

export type ListShipmentPagination = {
  page: number;
  pageSize: number;
  offset: number;
};

export type ListShipmentResponse = {
  shipments: Shipment[];
  pagination: {
    page: number;
    pageSize: number;
    offset: number;
    dataLength: number;
    total: number;
    links: {
      first: string;
      previous: string | null;
      next: string | null;
      last: string;
    };
  };
};

export type ShipmentDetailResponse = {
  shipment: Shipment;
};

export type UpdateShipmentResponse = {
  shipment: Shipment;
};

export type CreateShipmentResponse = {
  shipment: Shipment;
};

export type StringResponseByCarrier = {
  responsesByCarrier: { [carrier: string]: string };
};

export type ShipResponseByCarrier = {
  responsesByCarrier: {
    [carrier: string]: {
      id: string;
      status: string;
      message?: string;
    }[];
  };
};

export type TFilter = {
  [key: string]: { value: string | string[] | number | Date | Date[] | boolean; operator?: FilterOpearator };
};

export type TPagination = {
  limit: number;
  page: number;
};

const convertFilters = (filters: TFilter) => {
  let convertedFilters: {
    [key: string]: string;
  } = {};
  // if there are filters, we need to add them to the query string of the request in the format of `?filter[key]=value`
  // with other query params (page, pageSize)
  // if there are no filters, we just use the page and pageSize query params

  if (filters) {
    // convert to new keys with filter[key] format
    filters = Object.keys(filters).reduce((acc, key) => {
      if (filters) {
        return {
          ...acc,
          [`filter[${key}|${filters[key].operator}]`]: filters[key].value,
        };
      }
      return acc;
    }, {});

    // convert all values to string
    convertedFilters = Object.keys(filters).reduce((acc, key) => {
      if (filters) {
        return {
          ...acc,
          [key]: filters[key].toString() as string,
        };
      }
      return acc;
    }, {});
  }

  return convertedFilters;
};

export const useProjectShipmentActions = () => {
  const { executeApiAction } = useApiActions();

  const getProjectShipments = (projectId: string, page: number, limit: number, filters?: TFilter) =>
    executeApiAction<ListShipmentResponse>({
      action: async ({ client }) => {
        const queryParams = {
          ...(filters ? convertFilters(filters) : {}),
          page: `${page}`,
          pageSize: `${limit}`,
        };

        // convert to query string
        const queryString = new URLSearchParams(queryParams).toString();
        console.log(queryString);
        return await client.get(`projects/${projectId}/shipments?${queryString}`).json<ListShipmentResponse>();
      },
      onSuccess: (res: ListShipmentResponse) => {
        return res;
      },
    });
  const getShipment = (projectId: string, shipmentId: string) =>
    executeApiAction<Shipment>({
      action: async ({ client }) =>
        (await client.get(`projects/${projectId}/shipments/${shipmentId}`).json<ShipmentDetailResponse>()).shipment,
      onSuccess: (res: Shipment) => {
        return res;
      },
    });
  const updateShipment = async (projectId: string, shipmentId: string, data: Partial<Shipment>) =>
    await executeApiAction({
      action: ({ client }) =>
        client.put(`projects/${projectId}/shipments/${shipmentId}/`, { json: data }).json<UpdateShipmentResponse>(),
      errorMessage: 'Failed to update shipment',
      successMessage: 'Shipment saved',
    });
  const createShipment = async (projectId: string, data: Partial<Shipment>) =>
    await executeApiAction({
      action: ({ client }) =>
        client.post(`projects/${projectId}/shipments/`, { json: data }).json<CreateShipmentResponse>(),
      errorMessage: 'Failed to update shipment',
      successMessage: 'Shipment saved',
    });

  const deleteShipment = async (projectId: string, shipmentId: string) =>
    await executeApiAction({
      action: ({ client }) => client.delete(`projects/${projectId}/shipments/${shipmentId}`),
      errorMessage: 'Failed to delete shipment',
      successMessage: 'Shipment deleted',
    });

  const generateShipmentLabels = async (projectId: string, shipmentIds: string[]) =>
    await executeApiAction({
      action: async ({ client }) =>
        client
          .post(`projects/${projectId}/shipments/carrier/label`, {
            json: {
              shipments: shipmentIds.map((id) => {
                return { id };
              }),
            },
          })
          .json<StringResponseByCarrier>(),
      errorMessage: 'Failed to generate labels',
      successMessage: 'Labels generated',
    });

  const generateShipmentWaybill = async (projectId: string, shipmentIds: string[]) =>
    await executeApiAction({
      action: async ({ client }) =>
        (
          await client.post(`projects/${projectId}/shipments/carrier/waybill`, {
            json: {
              shipments: shipmentIds.map((id) => {
                return { id };
              }),
            },
          })
        ).json<StringResponseByCarrier>(),
      errorMessage: 'Failed to generate waybills',
      successMessage: 'Waybills generated',
    });

  const shipShipment = async (projectId: string, shipmentIds: string[]) =>
    await executeApiAction({
      action: async ({ client }) =>
        (
          await client.post(`projects/${projectId}/shipments/carrier/ship`, {
            json: {
              shipments: shipmentIds.map((id) => {
                return { id };
              }),
            },
          })
        ).json<ShipResponseByCarrier>(),
      errorMessage: 'Failed to ship shipments',
      successMessage: 'Shipments sent',
    });

  const pickupShipment = async (projectId: string, shipmentIds: string[]) =>
    await executeApiAction({
      action: async ({ client }) =>
        (
          await client.post(`projects/${projectId}/shipments/carrier/pickup`, {
            json: {
              shipments: shipmentIds.map((id) => {
                return { id };
              }),
            },
          })
        ).json<ShipResponseByCarrier>(),
      errorMessage: 'Failed to ship shipments',
      successMessage: 'Shipments sent',
    });

  const statusShipment = async (projectId: string, shipmentIds: string[]) =>
    await executeApiAction({
      action: async ({ client }) =>
        (
          await client.post(`projects/${projectId}/shipments/carrier/status`, {
            json: {
              shipments: shipmentIds.map((id) => {
                return { id };
              }),
            },
          })
        ).json<ShipResponseByCarrier>(),
      errorMessage: 'Failed to update statuses',
      successMessage: 'Shipment statuses updated',
    });

  return {
    getProjectShipments: useCallback(getProjectShipments, [executeApiAction]),
    getShipment: useCallback(getShipment, [executeApiAction]),
    updateShipment: useCallback(updateShipment, [executeApiAction]),
    createShipment: useCallback(createShipment, [executeApiAction]),
    deleteShipment: useCallback(deleteShipment, [executeApiAction]),
    generateShipmentLabels: useCallback(generateShipmentLabels, [executeApiAction]),
    generateShipmentWaybill: useCallback(generateShipmentWaybill, [executeApiAction]),
    shipShipment: useCallback(shipShipment, [executeApiAction]),
    pickupShipment: useCallback(pickupShipment, [executeApiAction]),
    statusShipment: useCallback(statusShipment, [executeApiAction]),
  };
};
