import { Payment, Recipient } from '../../types/shipment';
import { ProjectSeller } from '../../types';
import { email as emailContent } from './template';

type TContext = {
  email: {
    title: string;
    content: string;
  };
  recipient: {
    name: string;
    pickupPoint: string;
    address: string;
  };
  trackingNumber: string;
  trackingLink: string;
  shipmentId: string;
  payment: {
    info: string;
  };
  locale: string;
  config: {
    primaryColor: string;
    logoUrl: string;
    bannerUrl: string;
    bannerLink: string;
    storeName: string;
    storeUrl: string;
    storeEmail: string;
    storePhone: string;
  };
};

export class TrackingEmailGenerator {
  private trackingEmail = '';
  private contextObject: TContext = {} as TContext;
  private variableMapping: { [key: string]: string } = {
    email_title: 'email.title',
    email_content: 'email.content',
    logo: 'config.logoUrl',
    sender_name: 'recipient.name',
    order_number: 'shipmentId',
    tracking_number: 'trackingNumber',
    payment_info: 'payment.info',
    delivery_address: 'recipient.address',
    tracking_link: 'trackingLink',
    banner_url: 'config.bannerUrl',
    store_name: 'config.storeName',
    primary_color: 'config.primaryColor',
    store_url: 'config.storeUrl',
    store_email: 'config.storeEmail',
    store_phone: 'config.storePhone',
  };

  private replaceVariables() {
    Object.keys(this.variableMapping).forEach((key) => {
      const valuePath = this.variableMapping[key].split('.');
      let value = this.contextObject as any;
      valuePath.forEach((path: string) => {
        value = value[path];
      });
      this.trackingEmail = this.trackingEmail.replace(new RegExp(`{{${key}}}`, 'g'), value || '');
    });
  }

  constructor({
    email,
    recipient,
    trackingNumber,
    trackingLink,
    shipmentId,
    payment,
    locale,
    seller,
  }: {
    email: {
      title: string;
      content: string;
    };
    recipient: Recipient;
    trackingNumber: string;
    trackingLink: string;
    shipmentId: string;
    payment: Payment;
    locale: string;
    seller?: ProjectSeller;
  }) {
    this.trackingEmail = emailContent();
    this.contextObject = {
      recipient: {
        name: `${recipient.firstName} ${recipient.lastName}`,
        pickupPoint: recipient.pickupPoint || '',
        address: `${recipient.street} ${recipient.houseNumber}, ${recipient.city}, ${recipient.postalCode}`,
      },
      trackingNumber,
      trackingLink,
      shipmentId,
      payment: {
        info: `${payment.amount} ${payment.currency}`,
      },
      config: {
        primaryColor: seller?.customLayout?.primaryColor || '',
        logoUrl: seller?.customLayout?.logo_light || '',
        bannerUrl: seller?.customLayout?.banner || '',
        bannerLink: seller?.customLayout?.bannerLink || '',
        storeName: seller?.name || '',
        storeUrl: seller?.customLayout?.contactInfo?.url || '',
        storeEmail: seller?.customLayout?.contactInfo?.email || '',
        storePhone: seller?.customLayout?.contactInfo?.phone || '',
      },
      locale,
      email: email,
    };
    this.replaceVariables(); // Perform replacements upon initialization
  }

  public getHtml(): string {
    return this.trackingEmail;
  }
}
