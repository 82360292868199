import React, { useEffect, useState } from 'react';
import { Grid, GridItem, Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react';

import { AuthenticatedLayout } from '../layouts';
import { IoMdEye } from 'react-icons/io';

import { PageHeading } from '../components/generic/PageHeading';
import { useProjectShipmentActions } from '../hooks';
import { useParams } from 'react-router-dom';
import { Shipment } from '../types/shipment';
import { useLoadingContext, useProjectsContext } from '../providers';
import { useProjectShippingCarrierActions } from '../hooks/project-shipping-carrier-actions';
import { ProjectShippingCarrierRequest } from '../types';
import { FormCard } from '../components/project-shipments/detail/create/FormCard';
import {
  InsuranceSection,
  PaymentSection,
  GeneralSection,
  CustomsSection,
  ShipperAddressSection,
  RecipientAddressSection,
  ParcelsSection,
} from '../components/project-shipments/view';
import { useTranslation } from 'react-i18next';

export const ProjectShipmentDetailView: React.FC = () => {
  const { t } = useTranslation(['shipment', 'common']);
  const { shipmentId, id: projectId } = useParams();
  const [shipment, setShipment] = useState<Shipment | null>(null);
  const [projectShippingCarriers, setProjectShippingCarriers] = useState<ProjectShippingCarrierRequest[] | null>(null);

  const { setLoading } = useLoadingContext();
  const { listProjectShippingCarriers } = useProjectShippingCarrierActions();
  const { getShipment } = useProjectShipmentActions();
  const { selectedProject: project } = useProjectsContext();
  console.log('shipment', shipment);
  useEffect(() => {
    const getShipmentDetail = async () => {
      if (shipmentId !== 'new' && project) {
        const data = await getShipment(project.id, shipmentId as string);
        setShipment((data as Shipment) || null);
      }
    };
    const getProjectShippingCarriers = async () => {
      if (project) {
        const data = await listProjectShippingCarriers(project.id);
        setProjectShippingCarriers(data);
      }
    };
    setLoading(true);
    getProjectShippingCarriers();
    getShipmentDetail();
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, shipmentId, project]);

  return (
    <AuthenticatedLayout sidebar={false}>
      <Breadcrumb mb={4}>
        <BreadcrumbItem>
          <BreadcrumbLink href={`/projects/${projectId}/shipments`}>{t('shipment:list.title')}</BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink href="#">{shipment ? shipment.reference : ''}</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <PageHeading title={shipment ? shipment.reference : ''} icon={IoMdEye} />
      <Grid
        // h="200px"
        mb={10}
        width="100%"
        gap={6}
        templateColumns={{
          base: 'repeat(1, 1fr)',
          md: 'repeat(5, 1fr)',
        }}
      >
        <GridItem colSpan={3}>
          <FormCard heading={t('shipment:general.label') /**General */}>
            {shipment && <GeneralSection data={shipment} projectShippingCarriers={projectShippingCarriers} />}
          </FormCard>
          <FormCard heading={`${t('shipment:pracels.label') /**Parcels */} (${shipment?.parcels?.length})`}>
            {shipment?.parcels && <ParcelsSection data={shipment.parcels} />}
          </FormCard>
          {shipment?.customs && (
            <FormCard heading={t('shipment:customs.label') /**Customs */} collapsible defaultOpen={false}>
              <CustomsSection data={shipment.customs} />
            </FormCard>
          )}
        </GridItem>
        <GridItem
          colSpan={{
            base: 3,
            md: 2,
          }}
        >
          <FormCard heading={t('shipment:recipient.ship-to') /**Recipient */}>
            {shipment?.recipient && <RecipientAddressSection data={shipment.recipient} />}
          </FormCard>
          <FormCard heading={t('shipment:shipper.ship-from') /**Shipper */} collapsible defaultOpen={false}>
            {shipment?.shipper && <ShipperAddressSection data={shipment.shipper} />}
          </FormCard>
          <FormCard heading={t('shipment:payment.label') /**Payment */}>
            {shipment?.payment && <PaymentSection data={shipment.payment} />}
          </FormCard>
          <FormCard heading={t('shipment:insurance.label') /**Insurance */}>
            {shipment?.insurance && <InsuranceSection data={shipment.insurance} />}
          </FormCard>
        </GridItem>
      </Grid>
    </AuthenticatedLayout>
  );
};
