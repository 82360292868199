import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useProjectsContext } from '../providers';
import { Project } from '../types';
import { useApiActions } from './api-actions';
import { FormShipperValues } from '../pages';

export type ListProjectsResponse = {
  projects: Project[];
};

type CreateProjectResponse = {
  project: Project;
};

type UpdateProjectResponse = {
  project: Project;
};

type RegenerateApiKeyresponse = {
  apiKey: string;
};

export const useProjectActions = () => {
  const navigate = useNavigate();
  const { projects, selectedProject, setSelectedProject, setProjects } = useProjectsContext();
  const { executeApiAction } = useApiActions();

  const listProjects = () =>
    executeApiAction<Project[]>({
      action: async ({ client }) => (await client.get('projects').json<ListProjectsResponse>()).projects,
      onSuccess: (res: Project[]) => {
        setProjects(res);
      },
    });

  const createProject = async ({ name }: { name: string }) =>
    await executeApiAction({
      action: async ({ client }) =>
        (
          await client.post('projects', { json: { name } }).json<CreateProjectResponse>()
        ).project,
      onSuccess: (project: Project) => {
        setProjects([...projects, project]);
        setSelectedProject(project);
        navigate(`/projects/${project.id}/dashboard`);
      },
      errorMessage: 'Failed to create project',
      successMessage: 'Project created',
    });

  const updateProject = async ({ id, name }: Partial<Project>) =>
    await executeApiAction({
      action: ({ client }) => client.put(`projects/${id}`, { json: { name } }).json<UpdateProjectResponse>(),
      onSuccess: ({ project }: UpdateProjectResponse) => {
        if (project.id === selectedProject?.id) {
          setSelectedProject(project);
        }
        setProjects(projects.map((p) => (p.id === project.id ? project : p)));
      },
      errorMessage: 'Failed to update project',
      successMessage: 'Project saved',
    });

  const deleteProject = async (id: string) =>
    await executeApiAction({
      action: ({ client }) => client.delete(`projects/${id}`),
      onSuccess: () => {
        setProjects(projects.filter((p) => p.id !== id));
        if (selectedProject?.id === id) {
          setSelectedProject(null);
        }

        navigate('/');
      },
      errorMessage: 'Failed to delete project',
      successMessage: 'Project deleted',
    });

  const regenerateApiKey = (id: string) =>
    executeApiAction({
      action: async ({ client }) =>
        await client
          .post(`projects/${id}/api-token`)
          .json<RegenerateApiKeyresponse>()
          .then((res) => res.apiKey),
    });

  const generateSignedS3Url = async (projectId: string, name: string): Promise<string | null> => {
    return executeApiAction({
      action: async ({ client }) =>
        await client
          .post(`projects/${projectId}/generate-signed-url`, { json: { name } })
          .json<{ url: string }>()
          .then((res) => res.url),
    });
  };

  const getProjectShipper = async (projectId: string): Promise<FormShipperValues | null> => {
    return executeApiAction({
      action: async ({ client }) =>
        await client
          .get(`projects/${projectId}/shipper`)
          .json<{
            shipper: FormShipperValues;
          }>()
          .then((res) => res.shipper),
    });
  };

  const createProjectShipper = async (projectId: string, shipper: Partial<FormShipperValues>) => {
    return executeApiAction({
      action: async ({ client }) => await client.post(`projects/${projectId}/shipper`, { json: shipper }).json(),
    });
  };

  const editProjectShipper = async (projectId: string, shipper: Partial<FormShipperValues>) => {
    return executeApiAction({
      action: async ({ client }) => await client.put(`projects/${projectId}/shipper`, { json: shipper }).json(),
    });
  };

  type ProjectStats = {
    parcels: number;
    delivered: number;
    returned: number;
    recipients: number;
  };

  const getProjectStats = async (projectId: string, dateFrom: string, dateTo: string) => {
    return executeApiAction({
      action: async ({ client }) =>
        await client
          .get(`projects/${projectId}/stats`, { searchParams: { dateFrom, dateTo } })
          .json<{ current: ProjectStats; previous: ProjectStats }>(),
    });
  };

  return {
    listProjects: useCallback(listProjects, [setProjects, executeApiAction]),
    createProject: useCallback(createProject, [setProjects, setSelectedProject, executeApiAction, navigate, projects]),
    updateProject: useCallback(updateProject, [
      setProjects,
      setSelectedProject,
      selectedProject,
      executeApiAction,
      projects,
    ]),
    deleteProject: useCallback(deleteProject, [
      setProjects,
      setSelectedProject,
      selectedProject,
      executeApiAction,
      projects,
      navigate,
    ]),
    regenerateApiKey: useCallback(regenerateApiKey, [executeApiAction]),
    generateSignedS3Url: useCallback(generateSignedS3Url, [executeApiAction]),
    getProjectShipper: useCallback(getProjectShipper, [executeApiAction]),
    createProjectShipper: useCallback(createProjectShipper, [executeApiAction]),
    editProjectShipper: useCallback(editProjectShipper, [executeApiAction]),
    getProjectStats: useCallback(getProjectStats, [executeApiAction]),
  };
};
