import { Flex, Heading } from '@chakra-ui/react';
import { DefaultLayout } from '../layouts';
import { useTranslation } from 'react-i18next';

export const NotFoundPage = () => {
  const { t } = useTranslation('common');
  return (
    <DefaultLayout>
      <Flex w="100%" h="100%" alignItems="center" justifyContent="center">
        <Heading>{t('common:404.title') /* 404 - Page not found */}</Heading>
      </Flex>
    </DefaultLayout>
  );
};
