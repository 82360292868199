import React, { useContext, useEffect, useState, createContext } from 'react';
import { LoadingPage } from '../pages';
import { useUserActions } from '../hooks';
import _ from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import { useLoadingContext } from './loading-provider';
import { User } from '../types';
import { useAuthActions } from '../hooks/authentication-actions';

type SignInProps = {
  email: string;
  password: string;
};

type SignUpProps = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
};

type ConfirmSignUpProps = {
  email: string;
  code: string;
};

type ResendSignUpProps = {
  email: string;
};

type ResetPasswordProps = {
  email: string;
};

type CompleteResetPasswordProps = {
  email: string;
  code: string;
  password: string;
};

interface AuthenticationProviderProps {
  openHostedUI: () => Promise<void>;
  signInWithEmailAndPassword: (props: SignInProps) => Promise<void>;
  signUpWithEmailAndPassword: (props: SignUpProps) => Promise<void>;
  resendSignUp: (props: ResendSignUpProps) => Promise<void>;
  confirmSignUp: (props: ConfirmSignUpProps) => Promise<void>;
  signOut: () => Promise<void>;
  resetPassword: (props: ResetPasswordProps) => Promise<void>;
  completeResetPassword: (props: CompleteResetPasswordProps) => Promise<void>;
  user: User | null;
}

const AuthContext = createContext<AuthenticationProviderProps | null>(null);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [initialized, setinitialized] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const { detail } = useUserActions();
  const {
    signIn,
    signUp,
    verificationSignUp,
    resendVerificationSignUp,
    forgotPassword,
    forgotPasswordSubmit,
    removeTokens,
    currentSession,
  } = useAuthActions();
  const navigate = useNavigate();
  const location = useLocation();
  const { setLoading } = useLoadingContext();
  const toast = useToast();
  // const [searchParams] = useSearchParams();

  const hasActiveSession = async () => {
    try {
      await currentSession();
      return true;
    } catch (err: unknown) {
      return false;
    }
  };

  const handleSignIn = _.debounce(async () => {
    const hasSession = await hasActiveSession();
    if (!hasSession) {
      setinitialized(true);
      return;
    }
    const res = await detail();
    if (res) {
      setUser(res);
    }
    setinitialized(true);
  }, 250);

  const onLoad = async () => {
    await handleSignIn();
  };

  useEffect(() => {
    onLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openHostedUI = async () => {
    try {
      setLoading(true);
    } catch (err: any) {
      toast({
        title: 'Failed to start login',
        description: err.message,
        status: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const signInWithEmailAndPassword = async ({ email, password }: SignInProps) => {
    try {
      setLoading(true);
      await signIn({ email, password });
      handleSignIn();
    } catch (err: any) {
      if (err.name === 'AUTHENTICATION_USER_NOT_VERIFIED') {
        navigate(
          {
            pathname: '/sign-up/confirm',
            search: location.search,
          },
          {
            state: {
              email,
            },
          }
        );
      }
      toast({
        title: 'Sign in failed',
        description: err.message,
        status: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const signUpWithEmailAndPassword = async ({ email, password, firstName, lastName }: SignUpProps) => {
    try {
      setLoading(true);
      const res = await signUp({
        email: email,
        password: password,
        firstName: firstName,
        lastName: lastName,
      });

      if (res && !res.verified) {
        navigate(
          {
            pathname: '/sign-up/confirm',
            search: location.search,
          },
          {
            state: {
              email,
            },
          }
        );
      }
    } catch (err: any) {
      toast({
        title: 'Sign up failed',
        description: err.message,
        status: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const resetPassword = async ({ email }: ResetPasswordProps) => {
    try {
      setLoading(true);
      await forgotPassword(email);
      toast({
        title: 'Success',
        description: 'Instructions has been sent to your email',
        status: 'success',
      });
      navigate(
        {
          pathname: '/reset-password/verify',
          search: location.search,
        },
        {
          state: {
            email,
          },
        }
      );
    } catch (err: any) {
      toast({
        title: 'Failed to reset password',
        description: err.message,
        status: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const completeResetPassword = async ({ email, code, password }: CompleteResetPasswordProps) => {
    try {
      setLoading(true);
      await forgotPasswordSubmit(email, code, password);
      toast({
        title: 'Success',
        description: 'Your password has been reset',
        status: 'success',
      });
      navigate({
        pathname: '/sign-in',
        search: location.search,
      });
    } catch (err: any) {
      toast({
        title: 'Failed to reset password',
        description: err.message,
        status: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const confirmSignUp = async ({ email, code }: ConfirmSignUpProps) => {
    try {
      setLoading(true);
      await verificationSignUp({ email, code });
      toast({
        title: 'Success',
        description: 'Your account has been verified',
        status: 'success',
      });
      navigate({
        pathname: '/sign-in',
        search: location.search,
      });
    } catch (err: any) {
      toast({
        title: 'Verification failed',
        description: err.message,
        status: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const resendSignUp = async ({ email }: ResendSignUpProps) => {
    try {
      setLoading(true);
      await resendVerificationSignUp({ email });
    } catch (err: any) {
      toast({
        title: 'Verification failed',
        description: err.message,
        status: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const signOut = async () => {
    try {
      setLoading(true);
      removeTokens();
      setUser(null);
    } catch (err: any) {
      toast({
        title: 'Sign out failed',
        description: err.message,
        status: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        openHostedUI,
        signInWithEmailAndPassword,
        signUpWithEmailAndPassword,
        confirmSignUp,
        resendSignUp,
        signOut,
        resetPassword,
        completeResetPassword,
        user,
      }}
    >
      {initialized ? children : <LoadingPage />}
    </AuthContext.Provider>
  );
};

export const useAuthContext = (): AuthenticationProviderProps => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('Failed to load auth context. Make sure you are consuming the context within a provider block');
  }

  return context;
};
