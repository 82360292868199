import { useEffect, useState } from 'react';
import { useApiActions } from './api-actions';

export const useMetadataActions = () => {
  const [config, setConfig] = useState<any>(null);
  const { executeApiAction } = useApiActions();

  useEffect(() => {
    if (!config) {
      getAppConfig();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAppConfig = async () => {
    if (!config) {
      executeApiAction<any>({
        action: async ({ publicClient }) => {
          const configRes = await publicClient.get('metadata/app-config').json<any>();
          setConfig(configRes);
          return configRes;
        },
      });
    }
    return config;
  };

  return {
    config,
  };
};
