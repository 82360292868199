import { MdLocalShipping } from 'react-icons/md';
import { PageHeading } from '../components/generic/PageHeading';
import { SoftCard } from '../components/generic/SoftCard';
import { AuthenticatedLayout } from '../layouts';
import { useProjectsContext } from '../providers';
import { CreateProjectShipmentButton } from '../components/project-shipments/CreateProjectShipmentButton';
import { useNavigate } from 'react-router-dom';
import ProjectShipmentList from '../components/project-shipments/list/ProjectShipmentList';
import { useTranslation } from 'react-i18next';
import { usePrivileges } from '../hooks/roles';

export const ProjectShipments: React.FC = () => {
  const { t } = useTranslation('shipment');
  const { selectedProject: project } = useProjectsContext();
  const navigate = useNavigate();
  const privileges = usePrivileges();

  if (!project) return null;

  const onCreateShipment = () => {
    // redirect to create shipment page /projects/:projectId/shipments/create
    if (!project) return;
    navigate(`/projects/${project.id}/shipments/new`);
  };

  return (
    <AuthenticatedLayout>
      <PageHeading
        title={t('shipment:list.title') /**Shipments*/}
        icon={MdLocalShipping}
        cta={<CreateProjectShipmentButton onSubmit={onCreateShipment} />}
        privilege={privileges.PROJECT_SHIPMENTS.CREATE}
      />
      <SoftCard>
        <ProjectShipmentList />
      </SoftCard>
    </AuthenticatedLayout>
  );
};
