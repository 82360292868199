import { useAuthContext, useProjectsContext } from '../providers';

export enum Entity {
  PROJECTS = 'PROJECTS',
  PROJECT_CARRIERS = 'PROJECT_CARRIERS',
  PROJECT_SELLERS = 'PROJECT_SELLERS',
  LOCALIZATIONS = 'LOCALIZATIONS',
  API_KEYS = 'API_KEYS',
}

export enum Type {
  NUMERIC = 'NUMERIC',
  BOOLEAN = 'BOOLEAN',
}

enum Plans {
  BASIC = 'BASIC',
  PLUS = 'PLUS',
  PREMIUM = 'PREMIUM',
}

type Limitations = Record<Plans, Record<Entity, boolean | number>>;

const subscriptionLimitations: Limitations = {
  BASIC: {
    PROJECTS: 1,
    PROJECT_CARRIERS: 1,
    PROJECT_SELLERS: 1,
    LOCALIZATIONS: false,
    API_KEYS: false,
  },
  PLUS: {
    PROJECTS: 1,
    PROJECT_CARRIERS: 2,
    PROJECT_SELLERS: 2,
    LOCALIZATIONS: true,
    API_KEYS: true,
  },
  PREMIUM: {
    PROJECTS: Infinity,
    PROJECT_CARRIERS: Infinity,
    PROJECT_SELLERS: Infinity,
    LOCALIZATIONS: true,
    API_KEYS: true,
  },
};

const useCurrentPlan = () => {
  const { selectedProject } = useProjectsContext();
  const { user } = useAuthContext();
  if (selectedProject) {
    return selectedProject?.owner?.planId as Plans;
  } else {
    return user?.planId as Plans;
  }
};

export const usePlanLimitations = () => {
  const currentPlan = useCurrentPlan();

  return subscriptionLimitations[currentPlan];
};
