import React from 'react';
import { Box, Button, Flex, Heading, SimpleGrid, Text, VStack } from '@chakra-ui/react';
import { MdAddShoppingCart, MdPerson, MdOutlineOpenInNew } from 'react-icons/md';

import { AuthenticatedLayout } from '../layouts';
import { SoftCard } from '../components/generic/SoftCard';
import { PageHeading } from '../components/generic/PageHeading';
import { useSubscriptionActions } from '../hooks';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../providers';
import { useTranslation } from 'react-i18next';

export const SettingsPage: React.FC = () => {
  const { t } = useTranslation('profile');
  const { user } = useAuthContext();
  const { manageSubscriptions } = useSubscriptionActions();
  const navigate = useNavigate();

  return (
    <AuthenticatedLayout sidebar={false}>
      <PageHeading title={t('profile:profile.label') /**Profile */} icon={MdPerson} />

      <SimpleGrid pt={2} minChildWidth="400px" width="100%" spacingX="40px" spacingY="20px">
        <Box>
          <Heading as="h2" size="md" mb={4}>
            {t('profile:personal-information.label') /**Personal information */}
          </Heading>
          <SoftCard>
            {user && (
              <>
                <VStack spacing={4} alignItems="flex-start">
                  <Box>
                    <Text fontWeight={700}>{t('common:person-name.label') /**Name */}</Text>
                    <Text size="md">
                      {user.firstName} {user.lastName}
                    </Text>
                  </Box>
                  <Box>
                    <Text fontWeight={700}>{t('common:email.label') /* Email */}</Text>
                    <Text noOfLines={2}>{user.email}</Text>
                  </Box>
                </VStack>
              </>
            )}
          </SoftCard>
        </Box>
        <Box>
          <Heading as="h2" size="md" mb={4}>
            {t('profile:billing.label') /**Billing */}
          </Heading>
          <SoftCard>
            <Flex justifyContent="space-between" alignItems="center">
              <Box>
                <Heading as="h3" size="sm" mb={2}>
                  {t('profile:billing.manage') /* Manage subscription */}
                </Heading>
                <Text>{t('profile:billing.action') /*   View, edit or cancel your subscription */}</Text>
              </Box>

              {user?.hasActiveSubscription ? (
                <Button rightIcon={<MdAddShoppingCart />} onClick={manageSubscriptions}>
                  {t('common:manage.label') /* Manage */}
                </Button>
              ) : (
                <Button leftIcon={<MdOutlineOpenInNew />} onClick={() => navigate('/onboarding')}>
                  {t('common:subscribe.label') /* Subscribe */}
                </Button>
              )}
            </Flex>
          </SoftCard>
        </Box>
      </SimpleGrid>
    </AuthenticatedLayout>
  );
};
