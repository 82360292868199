import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FormLabel, Heading, Input, Button, Box, Badge, SimpleGrid, Flex, Text } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { MdSettings } from 'react-icons/md';

import { AuthenticatedLayout } from '../layouts';
import { Project } from '../types';
import { PageHeading } from '../components/generic/PageHeading';
import { SoftCard } from '../components/generic/SoftCard';
import { DangerButton } from '../components/generic/DangerButton';
import { useProjectActions } from '../hooks/project-actions';
import { useProjectsContext } from '../providers';
import { Entity, Operation, usePrivileges } from '../hooks/roles';
import { PrivilegeContainer } from '../components/generic/PrivilegeContainer';
import { useTranslation } from 'react-i18next';
import { ShipperSettings } from '../components/project-shipments/ShipperSettings';

type FormValues = {
  name: string;
};

export const ProjectSettingsPage: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();
  const { t } = useTranslation(['common', 'project']);

  const navigate = useNavigate();

  const { selectedProject } = useProjectsContext();
  const privileges = usePrivileges();
  const [project] = useState<Project | null>(selectedProject);

  const { updateProject, deleteProject } = useProjectActions();
  const { id } = useParams();

  if (!id) return null;

  const onUpdateProject = (data: { name: string }) => updateProject({ ...data, id });

  const onDeleteProject = () => deleteProject(id);

  const onEditCarrierSettings = () => navigate(`/projects/${id}/settings/carrier-settings`);
  const onEditIntegrations = () => navigate(`/projects/${id}/settings/integrations`);
  const onEditProjectSellers = () => navigate(`/projects/${id}/settings/sellers`);

  return (
    <AuthenticatedLayout>
      <PageHeading title={t('project:project-settings.label') /*"Project Settings"*/} icon={MdSettings} />

      {project && (
        <SimpleGrid pt={2} minChildWidth="400px" width="100%" spacingX="40px" spacingY="20px">
          <Box>
            <Heading as="h2" size="md" mb={4}>
              {t('project:settings.label') /* Settings */}
            </Heading>

            <SoftCard>
              <Box>
                <form onSubmit={handleSubmit(onUpdateProject)}>
                  <FormLabel htmlFor="name">{t('common:name.label') /* Name */}</FormLabel>
                  <Input
                    disabled={!privileges.PROJECTS.UPDATE}
                    defaultValue={project.name}
                    {...register('name', { required: true })}
                  />
                  {errors?.name && <p>{t('common:name.required') /*Name is required */}</p>}
                  <PrivilegeContainer entity={Entity.PROJECTS} operation={Operation.UPDATE}>
                    <Button marginTop="1em" type="submit">
                      {t('common:save.label') /* Save */}
                    </Button>
                  </PrivilegeContainer>
                </form>
              </Box>
            </SoftCard>
          </Box>

          <Box>
            <Heading as="h2" size="md" mb={4}>
              {t('project:shipping-carrier-settings.label') /* Shipping Carrier Settings */}
            </Heading>

            <SoftCard>
              <Box>
                <Text variant="text">
                  {
                    t(
                      'project:shipping-carrier-settings.text'
                    ) /* Setup shipping carrier connections incl. authentication and project bias data.*/
                  }
                </Text>
                <Button marginTop="1em" type="submit" onClick={onEditCarrierSettings}>
                  {t('common:edit.label') /* Edit */}
                </Button>
              </Box>
            </SoftCard>
          </Box>

          <Box>
            <Heading as="h2" size="md" mb={4}>
              {t('project:shipper-settings.label') /* Shipper */}
            </Heading>

            <SoftCard>
              <Box>
                <Text variant="text">
                  {
                    t(
                      'project:shipper-settings.text'
                    ) /* Setup universal shipper which will be passed to the carrier as your contact and return information.*/
                  }
                </Text>
                <ShipperSettings />
              </Box>
            </SoftCard>
          </Box>

          <Box>
            <Heading as="h2" size="md" mb={4}>
              {t('project:sellers.label') /* Sellers */}
            </Heading>

            <SoftCard>
              <Box>
                <Text variant="text">
                  {t('project:sellers.text') /* Setup project's sellers to create branding for customer UI. */}
                </Text>
                <Button marginTop="1em" type="submit" onClick={onEditProjectSellers}>
                  {t('common:edit.label') /* Edit */}
                </Button>
              </Box>
            </SoftCard>
          </Box>

          <Box>
            <Heading as="h2" size="md" mb={4}>
              {t('project:integrations.label') /* Integrations */}
            </Heading>

            <SoftCard>
              <Box>
                <Text variant="text">{t('project:integrations.text') /* Setup API and 3rd party integrations. */}</Text>
                <Button marginTop="1em" type="submit" onClick={onEditIntegrations}>
                  {t('common:edit.label') /* Edit */}
                </Button>
              </Box>
            </SoftCard>
          </Box>

          <Box>
            <Heading as="h2" size="md" mb={4}>
              {t('project:project-info.label') /* Project info */}
            </Heading>

            <SoftCard>
              <Flex justifyContent="space-between" gap={2} alignItems="center" mt={4}>
                <Box>
                  <Heading as="h3" size="sm" mb={2}>
                    {t('project:project-plan.label') /* Plan */}
                  </Heading>
                  <Text>
                    {
                      t(
                        'project:project-plan.text'
                      ) /* Your current plan. The owner of the project can change plan in their user settings. */
                    }
                  </Text>
                </Box>
                <Badge fontSize="medium">{project.owner.planId}</Badge>
              </Flex>
            </SoftCard>
          </Box>

          <PrivilegeContainer entity={Entity.PROJECTS} operation={Operation.DELETE}>
            <Box>
              <Heading as="h2" size="md" mb={4}>
                {t('common:danger-zone.label') /**Danger zone */}
              </Heading>
              <SoftCard>
                <Flex justifyContent="space-between" gap={2} alignItems="center">
                  <Box>
                    <Heading as="h3" size="sm" mb={2}>
                      {t('project:delete-project.label') /* Delete project */}
                    </Heading>
                    <Text>
                      {
                        t(
                          'project:delete-project.text'
                        ) /* Once you delete a proeject, there is no going back. Please be certain. */
                      }
                    </Text>
                  </Box>

                  <DangerButton
                    onClick={onDeleteProject}
                    title={t('common:delete.label') /* Delete */}
                    body={
                      t('project:delete-project.dange-button.body') /*'Are you sure you want to delete this project?'*/
                    }
                  />
                </Flex>
              </SoftCard>
            </Box>
          </PrivilegeContainer>
        </SimpleGrid>
      )}
    </AuthenticatedLayout>
  );
};
