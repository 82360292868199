import { Text, Flex, Box, Heading, Link } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

import { DefaultLayout } from '../layouts';
import { SoftCard } from '../components/generic/SoftCard';
import { ResetPasswordForm } from '../components/forms/ResetPasswordForm';
import { useAuthContext } from '../providers';
import { useTranslation } from 'react-i18next';

export const ResetPasswordPage: React.FC = () => {
  const { t } = useTranslation(['unauth', 'common']);
  const { resetPassword } = useAuthContext();

  const onSubmit = async ({ email }: { email: string }) => {
    await resetPassword({ email });
  };

  return (
    <DefaultLayout displayLogo>
      <Flex width="100%" alignSelf="center" flexGrow={1} justifyContent="center" alignItems="center">
        <Box w={460}>
          <SoftCard>
            <Flex alignItems="center" flexDir="column">
              <Heading mb={2} size="md" paddingBottom={4}>
                {t('unauth:reset-password.title')}
              </Heading>
              <Text fontSize="sm">
                {
                  t(
                    'unauth:reset-password.text.request'
                  ) /* Enter the email address you signed up with and we'll send you instructions as how to reset your
                password. Or go back to{' '} */
                }{' '}
                <Link textDecoration="underline" textUnderlineOffset={1.5} to="/sign-in" as={RouterLink}>
                  {t('unauth:sign-in-page')} {/* sign in page */}
                </Link>{' '}
              </Text>

              <Flex paddingTop={4} paddingBottom={4} flexDir="row" width="100%" alignItems="center" />

              <Box w="100%">
                <ResetPasswordForm onSubmit={onSubmit} />
              </Box>
            </Flex>
          </SoftCard>
        </Box>
      </Flex>
    </DefaultLayout>
  );
};
