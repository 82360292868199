import { Box, Text } from '@chakra-ui/react';
import { Recipient } from '../../../../types/shipment';

const Address = ({ recipient }: { recipient: Recipient }) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="flex-start" px={2} py={2}>
      <Text fontSize="ml" as="b">
        {recipient?.pickupPoint ? `Výdejna` : `Doručovací adresa`}
      </Text>
      <Text fontSize="ml">
        {recipient?.firstName} {recipient?.lastName}
      </Text>
      {recipient?.pickupPoint ? (
        <Text fontSize="ml">{recipient?.pickupPoint}</Text>
      ) : (
        <>
          <Text fontSize="ml">
            {recipient?.street} {recipient?.houseNumber}
          </Text>
          <Text fontSize="ml">{recipient?.city}</Text>
          <Text fontSize="ml">{recipient?.postalCode}</Text>
          <Text fontSize="ml">{recipient?.country}</Text>
        </>
      )}
    </Box>
  );
};

export default Address;
