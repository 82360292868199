import {
  Input,
  FormControl,
  FormLabel,
  Button,
  Text,
  Divider,
  Alert,
  AlertIcon,
  Box,
  useDisclosure,
  ModalBody,
  ModalFooter,
  Modal,
  Flex,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Select,
} from '@chakra-ui/react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { usePrivileges } from '../../hooks/roles';
import { FormShipperValues } from '../../pages/project-shipment-detail-edit';
import { useEffect, useMemo, useState } from 'react';
import { useMetadataActions } from '../../hooks/use-metadata-actions';
import { useTranslation } from 'react-i18next';
import { useProjectsContext } from '../../providers';
import _ from 'lodash';
import { useProjectActions } from '../../hooks';

export const ShipperSettings = () => {
  /**
   * Shipper component for setting up the shipper details.
   */
  const { t } = useTranslation(['shipment', 'common']);
  const { selectedProject: project } = useProjectsContext();
  const { getProjectShipper, createProjectShipper, editProjectShipper } = useProjectActions();
  const privileges = usePrivileges();
  const { config } = useMetadataActions();
  const [existingShipper, setExistingShipper] = useState<FormShipperValues | null>(null);
  const {
    trigger,
    watch,
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormShipperValues>({
    defaultValues: {},
  });
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const fetchShipper = async () => {
      if (project) {
        const shipper = await getProjectShipper(project.id);
        setExistingShipper(shipper);
      }
    };
    fetchShipper();
  }, [project, getProjectShipper]);

  useEffect(() => {
    if (existingShipper) {
      reset(existingShipper);
    }
  }, [existingShipper, reset]);

  const shipperJson = JSON.stringify(watch());
  const isEmpty = useMemo(() => {
    // iterate over values and check if some is not empty
    const values = watch();
    if (!values) return true;
    return Object.values(values).every((value) => value === undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipperJson]);

  const onSubmit: SubmitHandler<FormShipperValues> = async (data: FormShipperValues) => {
    const shipper = _.omit(data, ['id', 'projectId', 'createdAt', 'shipmentId']);
    console.log('shipper', shipper, existingShipper);
    if (!project) return;
    if (existingShipper && Object.values(existingShipper).length > 0) {
      await editProjectShipper(project.id, shipper);
      return;
    }
    createProjectShipper(project.id as string, shipper);
  };

  const _onSubmit = (data: FormShipperValues) => {
    onSubmit(data);
  };

  return (
    <form onSubmit={handleSubmit(_onSubmit)}>
      <>
        <Flex mt={2} justifyContent={'space-between'}></Flex>
        {isEmpty ? (
          <Alert status="warning">
            <AlertIcon />
            {t('shipment:shipper.is-empty') /*Please specify valid shipper.*/}
          </Alert>
        ) : (
          <Box>
            <Text as="b">
              {[watch('firstName'), watch('lastName'), watch('companyName') || '']
                .filter((value: string) => value !== '')
                .join(', ')}
            </Text>
            <Text>
              {[watch('phone') || '', watch('email') || ''].filter((value: string) => value !== '').join(', ')}
            </Text>
            <Text>
              {[
                watch('street') || '',
                watch('houseNumber') || '',
                watch('city') || '',
                watch('postalCode') || '',
                watch('country') || '',
              ]
                .filter((value: string) => value !== '')
                .join(', ')}
            </Text>
          </Box>
        )}
        <Button marginTop="1em" type="submit" onClick={onOpen}>
          {t('common:edit.label') /* Edit */}
        </Button>
      </>
      <Modal isOpen={isOpen} onClose={onClose} closeOnEsc={false} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('shipment:shipper.ship-from') /* Ship from*/}</ModalHeader>
          <ModalBody>
            <Text as="b" mb={0} pt={2}>
              {t('shipment.address.contact.heading') /* Contact */}
            </Text>
            <Divider mb={4} mt={1} />
            <FormControl mb={2} isInvalid={!!errors?.firstName}>
              <FormLabel>{t('common:firstname.label') /* First name */}</FormLabel>
              <Input
                disabled={!privileges.PROJECT_SHIPMENTS.UPDATE}
                {...register('firstName', { required: true })}
                placeholder={t('common:firstname.label')}
              />
            </FormControl>
            <FormControl mb={2} isInvalid={!!errors?.lastName}>
              <FormLabel>{t('common:lastname.label') /* Last name */}</FormLabel>
              <Input
                disabled={!privileges.PROJECT_SHIPMENTS.UPDATE}
                {...register('lastName', { required: true })}
                placeholder={t('common:lastname.label')}
              />
            </FormControl>
            <FormControl mb={2} isInvalid={!!errors?.companyName}>
              <FormLabel>{t('common:company.label') /*Company*/}</FormLabel>
              <Input
                disabled={!privileges.PROJECT_SHIPMENTS.UPDATE}
                {...register('companyName', { required: false })}
                placeholder={t('common:name.label')}
              />
            </FormControl>
            <FormControl mb={2} isInvalid={!!errors?.email}>
              <FormLabel>{t('common:email.label') /* E-mail */}</FormLabel>
              <Input
                disabled={!privileges.PROJECT_SHIPMENTS.UPDATE}
                {...register('email', { required: true })}
                placeholder={t('common:email.label')}
              />
            </FormControl>
            <FormControl mb={8} isInvalid={!!errors?.phone}>
              <FormLabel>{t('common:phone.label') /*Phone*/}</FormLabel>
              <Input
                disabled={!privileges.PROJECT_SHIPMENTS.UPDATE}
                {...register('phone', { required: true })}
                placeholder="Phone"
              />
            </FormControl>

            <Text as="b" mb={2} pt={2}>
              {t('shipment:address.label') /* Address */}
            </Text>
            <Divider mb={4} mt={1} />
            <FormControl mb={2} isInvalid={!!errors?.street}>
              <FormLabel>{t('shipment:address.street.label') /* Street */}</FormLabel>
              <Input
                disabled={!privileges.PROJECT_SHIPMENTS.UPDATE}
                {...register('street', { required: true })}
                placeholder={t('shipment:address.street.label')}
              />
            </FormControl>
            <FormControl mb={2} isInvalid={!!errors?.houseNumber}>
              <FormLabel>{t('shipment:address.house-number.label') /* House number */}</FormLabel>
              <Input
                disabled={!privileges.PROJECT_SHIPMENTS.UPDATE}
                {...register('houseNumber', { required: true })}
                placeholder={t('shipment:address.house-number.label')}
              />
            </FormControl>
            <FormControl mb={2} isInvalid={!!errors?.city}>
              <FormLabel>{t('shipment:address.city.label') /*City*/}</FormLabel>
              <Input
                disabled={!privileges.PROJECT_SHIPMENTS.UPDATE}
                {...register('city', { required: true })}
                placeholder={t('shipment:address.city.label') /*City*/}
              />
            </FormControl>
            <FormControl mb={2} isInvalid={!!errors?.postalCode}>
              <FormLabel>{t('shipment:address.postal-code.label') /* Postal code */}</FormLabel>
              <Input
                disabled={!privileges.PROJECT_SHIPMENTS.UPDATE}
                {...register('postalCode', { required: true })}
                placeholder={t('shipment:address.postal-code.label')}
              />
            </FormControl>
            <Controller
              control={control}
              name={`country`}
              render={({ field }) => (
                <FormControl mb={2} isInvalid={!!errors?.country}>
                  <FormLabel>{t('common:country.label') /**Country */}</FormLabel>
                  <Select
                    disabled={!privileges.PROJECT_SHIPMENTS.UPDATE}
                    {...field}
                    placeholder={t('common:country.placeholder') /**Select country */}
                  >
                    {config?.countries.map((country: { name: string; code: string }) => (
                      <option key={country.code} value={country.code}>
                        {country.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              type="submit"
              onClick={async () => {
                await trigger();
                console.log('errors save', errors);
                if (!errors || Object.keys(errors).length === 0) {
                  await handleSubmit(_onSubmit)();
                  onClose();
                }
              }}
            >
              {t('common:save.label')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </form>
  );
};
