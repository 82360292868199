import { Table, Tbody, Tr, Td, TableContainer } from '@chakra-ui/react';
import { Recipient } from '../../../types/shipment';
import { useTranslation } from 'react-i18next';

type RecipientAddressSectionProps = {
  data: Recipient;
};

export const RecipientAddressSection: React.FC<RecipientAddressSectionProps> = ({ data }) => {
  /** Tabs view of parcels with basic data and tracking */
  const { t } = useTranslation(['shipment', 'common']);
  const {
    firstName,
    lastName,
    companyName,
    email,
    phone,
    street,
    houseNumber,
    city,
    country,
    postalCode,
    pickupPoint,
  } = data;
  return (
    <TableContainer>
      <Table size="sm">
        <Tbody>
          <Tr>
            <Td width="10%">{t('common:person-name.label')}</Td>
            <Td>
              {firstName} {lastName}
            </Td>
          </Tr>
          {companyName && (
            <Tr>
              <Td width="10%">{t('common:company.label')}</Td>
              <Td>{companyName}</Td>
            </Tr>
          )}
          <Tr>
            <Td width="10%">{t('common:email.label')}</Td>
            <Td>{email}</Td>
          </Tr>
          <Tr>
            <Td width="10%">{t('common:phone.label')}</Td>
            <Td>{phone}</Td>
          </Tr>
          {pickupPoint ? (
            <Tr>
              <Td width="10%">{t('shipment:pickup-point.label') /*Pickup point*/}</Td>
              <Td>{pickupPoint}</Td>
            </Tr>
          ) : (
            <>
              <Tr>
                <Td width="10%">{t('shipment:address.street.label') /* Street */}</Td>
                <Td>
                  {street} {houseNumber}
                </Td>
              </Tr>
              <Tr>
                <Td width="10%">{t('shipment:address.city.label') /* City */}</Td>
                <Td>{city}</Td>
              </Tr>
              <Tr>
                <Td width="10%">{t('shipment:address.postal-code.label') /* Postal code */}</Td>
                <Td>{postalCode}</Td>
              </Tr>
              <Tr>
                <Td width="10%">{t('common:country.label') /* Country */}</Td>
                <Td>{country}</Td>
              </Tr>
            </>
          )}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
