import { Heading, Collapse, useDisclosure, Text, Flex } from '@chakra-ui/react';
import { SoftCard } from '../../../generic/SoftCard';

type AddressModalProps = {
  heading: string;
  collapsible?: boolean;
  defaultOpen?: boolean;
  children?: React.ReactNode;
  border?: string;
};

export const FormCard = ({ heading, collapsible, defaultOpen = true, children, border }: AddressModalProps) => {
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: defaultOpen });

  return (
    <SoftCard mb={6} border={border}>
      <Flex justifyContent={'space-between'}>
        <Heading as="h2" size="md" mb={4}>
          {heading}
        </Heading>
        {collapsible ? (
          <Text
            as="u"
            size="sm"
            mb={4}
            sx={{
              cursor: 'pointer',
            }}
            onClick={onToggle}
          >
            {isOpen ? 'Hide' : 'Show'}
          </Text>
        ) : null}
      </Flex>
      <Collapse in={isOpen} animateOpacity>
        {children}
      </Collapse>
    </SoftCard>
  );
};
