import { Box, Badge, Text } from '@chakra-ui/react';
import { Payment as TPayment } from '../../../../types/shipment';

const Payment = ({ payment }: { payment: TPayment }) => {
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" px={2} py={2}>
      <Text fontSize="ml" as="b">
        {payment?.type === 'cashondelivery' ? `Platba na dobírku` : `Platba kartou`}
      </Text>
      {payment?.type === 'cashondelivery' ? (
        <Badge>
          {payment?.amount} {payment?.currency}
        </Badge>
      ) : null}
    </Box>
  );
};

export default Payment;
