import {
  Input,
  FormControl,
  FormLabel,
  Button,
  Text,
  Divider,
  Alert,
  AlertIcon,
  Box,
  useDisclosure,
  ModalBody,
  ModalFooter,
  Modal,
  Heading,
  Flex,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  RadioGroup,
  Stack,
  Radio,
  Select,
} from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';
import { usePrivileges } from '../../../../hooks/roles';
import { FormShipmentValues } from '../../../../pages/project-shipment-detail-edit';
import { SoftCard } from '../../../generic/SoftCard';
import { useEffect, useMemo, useState } from 'react';
import { useMetadataActions } from '../../../../hooks/use-metadata-actions';
import { useTranslation } from 'react-i18next';

type AddressType = 'ADDRESS' | 'PICKUPPOINT';

type RecipientSectionProps = {
  allowsPickupPoint: boolean;
};

export const RecipientSection = ({ allowsPickupPoint }: RecipientSectionProps) => {
  const { t } = useTranslation(['shipment', 'common']);
  const privileges = usePrivileges();
  const { config } = useMetadataActions();
  const {
    trigger,
    watch,
    register,
    unregister,
    control,
    setValue,
    formState: { errors },
  } = useFormContext<FormShipmentValues>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [addressType, setAddressType] = useState<AddressType>('ADDRESS');

  useEffect(() => {
    if (allowsPickupPoint && watch('recipient.pickupPoint')) {
      changeAddressType('PICKUPPOINT');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('recipient.pickupPoint')]);

  const changeAddressType = (event: string) => {
    if (!allowsPickupPoint) return;
    setAddressType(event as AddressType);
  };

  const recipientJson = JSON.stringify(watch('recipient'));
  const isEmpty = useMemo(() => {
    // iterate over values and check if some is not empty
    const values = watch('recipient');
    if (!values) return true;
    return Object.values(values).every((value) => value === undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recipientJson]);

  return (
    <>
      <SoftCard mb={6} border={!!errors.recipient ? '1px solid red' : undefined}>
        <Flex justifyContent={'space-between'}>
          <Heading as="h2" size="md" mb={4}>
            {t('shipment:recipient.ship-to') /* Ship to */}
          </Heading>
          <Text
            as="u"
            size="sm"
            mb={4}
            sx={{
              cursor: 'pointer',
            }}
            onClick={onOpen}
          >
            {t('common:edit.label') /* Edit */}
          </Text>
        </Flex>
        {isEmpty ? (
          <Alert status="warning">
            <AlertIcon />
            {t('shipment:recipient.is-empty') /*Please specify valid recipient.*/}
          </Alert>
        ) : (
          <Box>
            <Text as="b">
              {[`${watch('recipient.firstName')} ${watch('recipient.lastName')}`, watch('recipient.companyName') || '']
                .filter((value: string) => value !== '')
                .join(', ')}
            </Text>
            <Text>
              {[watch('recipient.phone') || '', watch('recipient.email') || '']
                .filter((value: string) => value !== '')
                .join(', ')}
            </Text>
            <Text>
              {addressType === 'ADDRESS'
                ? [
                    `${watch('recipient.street')} ${watch('recipient.houseNumber')}`,
                    watch('recipient.city') || '',
                    watch('recipient.postalCode') || '',
                    watch('recipient.country') || '',
                  ]
                    .filter((value: string) => value !== '')
                    .join(', ')
                : watch('recipient.pickupPoint') || ''}
            </Text>
          </Box>
        )}
      </SoftCard>
      <Modal isOpen={isOpen} onClose={onClose} closeOnEsc={false} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('shipment:recipient.ship-to') /* Ship to */}</ModalHeader>
          <ModalBody>
            <Text as="b" mb={0} pt={2}>
              {t('shipment.address.contact.heading') /* Contact */}
            </Text>
            <Divider mb={4} mt={1} />
            <FormControl mb={2} isInvalid={!!errors.recipient?.firstName}>
              <FormLabel>{t('common:firstname.label') /* First name */}</FormLabel>
              <Input
                disabled={!privileges.PROJECT_SHIPMENTS.UPDATE}
                {...register('recipient.firstName', { required: true })}
                placeholder={t('common:firstname.label')}
              />
            </FormControl>
            <FormControl mb={2} isInvalid={!!errors.recipient?.lastName}>
              <FormLabel>{t('common:lastname.label') /* Last name */}</FormLabel>
              <Input
                disabled={!privileges.PROJECT_SHIPMENTS.UPDATE}
                {...register('recipient.lastName', { required: true })}
                placeholder={t('common:lastname.label')}
              />
            </FormControl>
            <FormControl mb={2} isInvalid={!!errors.recipient?.companyName}>
              <FormLabel>{t('common:company.label') /*Company*/}</FormLabel>
              <Input
                disabled={!privileges.PROJECT_SHIPMENTS.UPDATE}
                {...register('recipient.companyName', { required: false })}
                placeholder={t('common:name.label')}
              />
            </FormControl>
            <FormControl mb={2} isInvalid={!!errors.recipient?.email}>
              <FormLabel>{t('common:email.label') /* E-mail */}</FormLabel>
              <Input
                disabled={!privileges.PROJECT_SHIPMENTS.UPDATE}
                {...register('recipient.email', { required: true })}
                placeholder={t('common:email.label')}
              />
            </FormControl>
            <FormControl mb={8} isInvalid={!!errors.recipient?.phone}>
              <FormLabel>{t('common:phone.label') /*Phone*/}</FormLabel>
              <Input
                disabled={!privileges.PROJECT_SHIPMENTS.UPDATE}
                {...register('recipient.phone', { required: true })}
                placeholder="Phone"
              />
            </FormControl>
            {allowsPickupPoint ? (
              <RadioGroup onChange={changeAddressType} value={addressType} mb={4}>
                <Stack direction="row">
                  <Radio value="ADDRESS">{t('shipment:address.label') /* Address */}</Radio>
                  <Radio value="PICKUPPOINT">{t('shipment:pickup-point.label') /*Pickup point*/}</Radio>
                </Stack>
              </RadioGroup>
            ) : null}

            {/* {addressType === 'ADDRESS' ? ( */}
            <span
              style={{
                display: addressType === 'ADDRESS' ? 'block' : 'none',
              }}
            >
              <Text as="b" mb={2} pt={2}>
                {t('shipment:address.label') /* Address */}
              </Text>
              <Divider mb={4} mt={1} />
              <FormControl mb={2} isInvalid={!!errors.recipient?.street}>
                <FormLabel>{t('shipment:address.street.label') /* Street */}</FormLabel>
                <Input
                  disabled={!privileges.PROJECT_SHIPMENTS.UPDATE}
                  {...register('recipient.street', {
                    required: addressType === 'ADDRESS' ? true : false,
                    shouldUnregister: false,
                  })}
                  placeholder={t('shipment:address.street.label')}
                />
              </FormControl>
              <FormControl mb={2} isInvalid={!!errors.recipient?.houseNumber}>
                <FormLabel>{t('shipment:address.house-number.label') /* House number */}</FormLabel>
                <Input
                  disabled={!privileges.PROJECT_SHIPMENTS.UPDATE}
                  {...register('recipient.houseNumber', {
                    required: addressType === 'ADDRESS' ? true : false,
                    shouldUnregister: false,
                  })}
                  placeholder={t('shipment:address.house-number.label')}
                />
              </FormControl>
              <FormControl mb={2} isInvalid={!!errors.recipient?.city}>
                <FormLabel>{t('shipment:address.city.label') /*City*/}</FormLabel>
                <Input
                  disabled={!privileges.PROJECT_SHIPMENTS.UPDATE}
                  {...register('recipient.city', {
                    required: addressType === 'ADDRESS' ? true : false,
                    shouldUnregister: false,
                  })}
                  placeholder={t('shipment:address.city.label')}
                />
              </FormControl>
              <FormControl mb={2} isInvalid={!!errors.recipient?.postalCode}>
                <FormLabel>{t('shipment:address.postal-code.label') /* Postal code */}</FormLabel>
                <Input
                  disabled={!privileges.PROJECT_SHIPMENTS.UPDATE}
                  {...register('recipient.postalCode', {
                    required: addressType === 'ADDRESS' ? true : false,
                    shouldUnregister: false,
                  })}
                  placeholder={t('shipment:address.postal-code.label')}
                />
              </FormControl>
              <Controller
                control={control}
                name={`recipient.country`}
                render={({ field }) => (
                  <FormControl mb={2} isInvalid={!!errors.recipient?.country}>
                    <FormLabel>{t('common:country.label') /**Country */}</FormLabel>
                    <Select
                      disabled={!privileges.PROJECT_SHIPMENTS.UPDATE}
                      {...field}
                      placeholder={t('common:country.placeholder') /**Select country */}
                    >
                      {config?.countries.map((country: { name: string; code: string }) => (
                        <option key={country.code} value={country.code}>
                          {country.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </span>
            <span
              style={{
                display: addressType === 'PICKUPPOINT' ? 'block' : 'none',
              }}
            >
              <Text as="b" mb={2} pt={2}>
                {t('shipment:address.label') /* Address */}
              </Text>
              <Divider mb={4} mt={1} />
              <FormControl mb={2} isInvalid={!!errors.recipient?.pickupPoint}>
                <FormLabel>{t('shipment:pickup-point.label') /*Pickup point*/}</FormLabel>
                <Input
                  disabled={!privileges.PROJECT_SHIPMENTS.UPDATE}
                  {...register('recipient.pickupPoint', {
                    required: addressType === 'PICKUPPOINT' ? true : false,
                    shouldUnregister: false,
                  })}
                  placeholder="ID"
                />
              </FormControl>
            </span>
            {/* )} */}
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={async () => {
                if (addressType === 'ADDRESS') {
                  unregister('recipient.pickupPoint');
                  setValue('recipient.pickupPoint', '');
                } else {
                  unregister('recipient.street');
                  unregister('recipient.houseNumber');
                  unregister('recipient.city');
                  unregister('recipient.postalCode');
                  unregister('recipient.country');
                  setValue('recipient.street', '');
                  setValue('recipient.houseNumber', '');
                  setValue('recipient.city', '');
                  setValue('recipient.postalCode', '');
                  setValue('recipient.country', '');
                }

                await trigger('recipient');
                console.log('errors save', errors.recipient);
                if (!errors.recipient || Object.keys(errors.recipient).length === 0) {
                  onClose();
                }
              }}
            >
              {t('common:save.label')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
