import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import {
  Input,
  Select,
  Grid,
  GridItem,
  FormControl,
  FormLabel,
  Button,
  InputGroup,
  InputRightElement,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  FormHelperText,
  Flex,
  useColorModeValue,
  Box,
} from '@chakra-ui/react';
import { useForm, Controller, FormProvider, FieldErrors, SubmitHandler } from 'react-hook-form';
import { MdOutlineRemoveCircle } from 'react-icons/md';

import { AuthenticatedLayout } from '../layouts';
import { MdAdd } from 'react-icons/md';
import { IoMdSave } from 'react-icons/io';
import { PageHeading } from '../components/generic/PageHeading';
import { useProjectShipmentActions } from '../hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { usePrivileges, Entity, Operation } from '../hooks/roles';
import { Shipment, ShipmentStatus } from '../types/shipment';
import { useLoadingContext, useProjectsContext, useProjectsSellersContext } from '../providers';
import { useProjectShippingCarrierActions } from '../hooks/project-shipping-carrier-actions';
import { ProjectShippingCarrierRequest } from '../types';
import { PrivilegeContainer } from '../components/generic/PrivilegeContainer';
import { FormCard } from '../components/project-shipments/detail/create/FormCard';
import { ParcelsSection } from '../components/project-shipments/detail/create/ParcelsSection';
import { CustomsSection } from '../components/project-shipments/detail/create/CustomsSection';
import { RecipientSection } from '../components/project-shipments/detail/create/RecipientSection';
import { DangerButton } from '../components/generic/DangerButton';
import { useMetadataActions } from '../hooks/use-metadata-actions';
import { useTranslation } from 'react-i18next';

export type FormShipperValues = {
  firstName: string;
  lastName: string;
  companyName?: string;
  email: string;
  phone: string;
  street: string;
  houseNumber: string;
  city: string;
  postalCode: string;
  country: string;
};
export type FormRecipientValues = {
  firstName: string;
  lastName: string;
  companyName?: string;
  email: string;
  phone: string;
  street: string;
  houseNumber: string;
  city: string;
  country: string;
  postalCode: string;
  pickupPoint: string;
};
export type FormParcelValues = {
  weight: number;
  height: number;
  width: number;
  length: number;
  referenceNumber: string;
};
type FormPaymentValues = {
  amount: number;
  currency: string;
  type: 'prepaid' | 'cashondelivery';
  paymentReference: string;
};
type FormInsuranceValues = {
  value: number;
  currency: string;
};
export type FormCommodityValues = {
  sku: string;
  title: string;
  quantity: number;
  hsCode: string;
  weight: number;
  originCountry: string;
  value: number;
  currency: string;
};
type FormCustomsValues = {
  description: string;
  totalValue: number;
  currency: string;
  commodities: FormCommodityValues[];
};

export type FormShipmentValues = {
  carrier: string;
  carrierService: string;
  reference: string;
  note?: string;
  seller: string;
  recipient: FormRecipientValues;
  parcels: FormParcelValues[];
  payment?: FormPaymentValues;
  insurance?: FormInsuranceValues;
  customs?: FormCustomsValues;
  carrierOptions: { [key: string]: string | string[] } | undefined;
};

export const ProjectShipmentDetailEdit: React.FC = () => {
  const { config } = useMetadataActions();
  const { t } = useTranslation(['shipment', 'common']);
  const { shipmentId, id: projectId } = useParams();
  const [shipment, setShipment] = useState<Shipment | null>(null);
  const { sellers } = useProjectsSellersContext();
  const [projectShippingCarriers, setProjectShippingCarriers] = useState<ProjectShippingCarrierRequest[] | null>(null);
  const [allowService, setAllowService] = useState<boolean>(true);
  const methods = useForm<FormShipmentValues>(
    shipmentId === 'new' ? {} : { defaultValues: shipment as FormShipmentValues }
  );
  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = methods;
  const { setLoading } = useLoadingContext();
  const privileges = usePrivileges();
  const { listProjectShippingCarriers } = useProjectShippingCarrierActions();
  const { getShipment, updateShipment, createShipment, deleteShipment } = useProjectShipmentActions();
  const { selectedProject: project } = useProjectsContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (shipment && shipment.status === ShipmentStatus.Sent) {
      // redirect to view page
      navigate(`/projects/${project?.id}/shipments/${shipmentId}/view`, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipment]);

  useEffect(() => {
    const getShipmentDetail = async () => {
      if (shipmentId !== 'new' && project) {
        const data = await getShipment(project.id, shipmentId as string);
        setShipment((data as Shipment) || null);
        reset(data as FormShipmentValues);
      }
    };
    const getProjectShippingCarriers = async () => {
      if (project) {
        const data = await listProjectShippingCarriers(project.id);
        setProjectShippingCarriers(data);
      }
    };
    setLoading(true);
    getProjectShippingCarriers();
    getShipmentDetail();
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, shipmentId, project]);

  useEffect(() => {
    // Check if the carrier has changed and allows pickup points and if so, reset the recipient
    const carrier = projectShippingCarriers?.find(
      (item) => item.shippingCarrier.id === watch('carrier')
    )?.shippingCarrier;
    if (watch('carrier') && carrier?.allowPickupPoint === false) {
      const recipient = getValues('recipient');
      setValue('recipient', {
        ...recipient,
        street: '',
        houseNumber: '',
        city: '',
        country: '',
        postalCode: '',
        pickupPoint: '',
      });
    }
    if (
      carrier?.allowPickupPointServiceCombinations === false &&
      watch('recipient.pickupPoint') !== null &&
      watch('recipient.pickupPoint') !== ''
    ) {
      setAllowService(false);
      setValue('carrierService', '');
    } else {
      setAllowService(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('carrier'), projectShippingCarriers]);

  const onSubmit: SubmitHandler<FormShipmentValues> = async (data: FormShipmentValues) => {
    console.log('data', data);
    if (!data.customs || !data.customs.commodities || data.customs.commodities.length === 0) {
      console.log('no customs');
      data.customs = undefined;
    }
    if (shipmentId === 'new') {
      console.log('data', data);
      const resp = await createShipment(project?.id as string, data);
      console.log('resp', resp);
      if (resp?.shipment?.id) {
        navigate(`/projects/${project?.id}/shipments/${resp?.shipment?.id}/`, { replace: true });
      }
      return;
    }
    const shipment = _.omit(data, ['id', 'projectId', 'createdAt', 'status', 'metadata', 'shipperId', 'shipper']);
    if (shipment.parcels && Array.isArray(shipment.parcels)) {
      shipment.parcels = shipment.parcels.map((parcel) =>
        _.omit(parcel, ['trackingNumber', 'statuses'])
      ) as FormParcelValues[];
    }
    updateShipment(project?.id as string, shipmentId as string, shipment);
  };

  const onDelete = async () => {
    if (project) {
      await deleteShipment(project.id, shipmentId as string);
      navigate(`/projects/${project.id}/shipments`);
    }
  };

  return (
    <AuthenticatedLayout sidebar={false}>
      <Breadcrumb mb={4}>
        <BreadcrumbItem>
          <BreadcrumbLink href={`/projects/${projectId}/shipments`}>
            {t('shipment:list.title') /* Shipments */}
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink href="#">
            {shipmentId === 'new' ? t('shipment:new.label') /*'Create new shipment'*/ : (watch('reference') as string)}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <PageHeading
        title={
          shipmentId === 'new' ? t('shipment:new.label') /*'Create new shipment'*/ : (watch('reference') as string)
        }
        icon={MdAdd}
      />
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            mb={10}
            width="100%"
            gap={6}
            templateColumns={{
              base: 'repeat(1, 1fr)',
              md: 'repeat(5, 1fr)',
            }}
          >
            <GridItem colSpan={3}>
              <RecipientSection allowsPickupPoint />
              {/* <ShipperSection /> */}
              <FormCard
                heading={t('shipment:carrier.carrier.label') /**Carrirer */}
                border={!!errors.carrier ? '1px solid red' : undefined}
              >
                <Controller
                  control={control}
                  name={'carrier'}
                  render={({ field }) => (
                    <FormControl mb={2}>
                      <FormLabel>{t('shipment:carrier.carrier.label') /*"Carrier"*/}</FormLabel>

                      <Select
                        {...field}
                        placeholder={t('shipment:carrier.carrier.placeholder') /*"Select carrier"*/}
                        mb={2}
                      >
                        {projectShippingCarriers?.map((item) => (
                          <option key={item.shippingCarrier.id} value={item.shippingCarrier.id}>
                            {item.shippingCarrier.name}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
                {allowService ? (
                  <FormControl mb={2}>
                    <FormLabel>{t('shipment:carrier.service.label') /** Service */}</FormLabel>
                    <Input
                      disabled={!privileges.PROJECT_SHIPMENTS.UPDATE}
                      {...register(`carrierService`, { required: false })}
                      placeholder="Service"
                    />
                    <FormHelperText>
                      {watch('carrier')
                        ? projectShippingCarriers?.find((item) => item.shippingCarrier.id === getValues('carrier'))
                            ?.shippingCarrier?.serviceHelperText
                        : ''}
                    </FormHelperText>
                  </FormControl>
                ) : null}
                {watch('carrier')
                  ? projectShippingCarriers
                      ?.find((item) => item.shippingCarrier.id === getValues('carrier'))
                      ?.shippingCarrier?.shipmentCarrierOptions?.map((option) => {
                        const isArray =
                          option?.isArrayOfValues &&
                          (watch(`carrierOptions.${option.key}`) === undefined ||
                            watch(`carrierOptions.${option.key}`) === null ||
                            Array.isArray(watch(`carrierOptions.${option.key}`)));
                        if (isArray) {
                          return (
                            <>
                              {(watch(`carrierOptions.${option.key}`) as string[])?.map((value, index) => (
                                <FormControl
                                  mb={2}
                                  key={`carrierOptions.${option.key}.${index}`}
                                  isInvalid={
                                    errors.carrierOptions &&
                                    errors.carrierOptions[option.key] &&
                                    errors.carrierOptions[option.key] instanceof Array
                                      ? // this TS hack is needed because the type of carrierOptions is string|string[] and errors are initialized as FieldError and not FieldErrors...
                                        !!(errors.carrierOptions[option.key] as unknown as FieldErrors)[index]
                                      : false
                                  }
                                >
                                  <FormLabel>
                                    {option.label} {index + 1}
                                  </FormLabel>
                                  <InputGroup size="md">
                                    <Input
                                      disabled={!privileges.PROJECT_SHIPMENTS.UPDATE}
                                      {...register(`carrierOptions.${option.key}.${index}`, {
                                        required: option.required,
                                      })}
                                      placeholder={option.label}
                                    />
                                    <InputRightElement width="4.5rem">
                                      <Button
                                        h="1.75rem"
                                        variant="ghost"
                                        size="sm"
                                        onClick={() => {
                                          const currentValues = getValues(`carrierOptions.${option.key}`) as string[];
                                          const updatedValues = currentValues.filter((_, i) => i !== index);
                                          setValue(`carrierOptions.${option.key}`, updatedValues);
                                        }}
                                      >
                                        <MdOutlineRemoveCircle />
                                      </Button>
                                    </InputRightElement>
                                  </InputGroup>
                                </FormControl>
                              ))}
                              <Button
                                mt={4}
                                onClick={() =>
                                  setValue(
                                    `carrierOptions.${option.key}.${
                                      getValues(`carrierOptions.${option.key}`)?.length || 0
                                    }`,
                                    ''
                                  )
                                }
                              >
                                {t('common:add.label') /**Add */} {option.label}
                              </Button>
                            </>
                          );
                        }
                        return (
                          <FormControl
                            mb={2}
                            isInvalid={errors.carrierOptions ? !!errors.carrierOptions[option.key] : false}
                          >
                            <FormLabel>{option.label}</FormLabel>
                            <Input
                              disabled={!privileges.PROJECT_SHIPMENTS.UPDATE}
                              {...register(`carrierOptions.${option.key}`, {
                                required: option.required,
                                pattern: option.valueRegex ? new RegExp(option.valueRegex) : undefined,
                              })}
                              placeholder={option.label}
                            />
                          </FormControl>
                        );
                      })
                  : null}
              </FormCard>
              <ParcelsSection />
              <CustomsSection />
            </GridItem>
            <GridItem
              colSpan={{
                base: 3,
                md: 2,
              }}
            >
              <FormCard
                heading={t('shipment:information.label') /** Information */}
                border={!!errors.reference || !!errors.note ? '1px solid red' : undefined}
              >
                <FormControl mb={2} isInvalid={!!errors.reference}>
                  <FormLabel>{t('shipment:information.reference.label') /* Reference */}</FormLabel>
                  <Input
                    disabled={!privileges.PROJECT_SHIPMENTS.UPDATE}
                    {...register('reference', { required: true })}
                    placeholder={t('shipment:information.reference.label') /* Reference */}
                  />
                </FormControl>
                <FormControl mb={2} isInvalid={!!errors.note}>
                  <FormLabel>{t('shipment:information.note.label') /* Note */}</FormLabel>
                  <Input
                    disabled={!privileges.PROJECT_SHIPMENTS.UPDATE}
                    {...register('note', { required: false })}
                    placeholder={t('shipment:information.note.label') /* Note */}
                  />
                </FormControl>

                <FormControl mb={2}>
                  <FormLabel>{t('shipment:information.seller.label') /* Seller */}</FormLabel>
                  <Controller
                    control={control}
                    name={'seller'}
                    render={({ field }) => (
                      <Select
                        {...field}
                        placeholder={t('shipment:information.seller.placeholder') /* Select seller */}
                        mb={2}
                      >
                        {sellers?.map((item) => (
                          <option key={item.id} value={item.name}>
                            {item.name}
                          </option>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
              </FormCard>
              <FormCard
                heading={t('shipment:payment.label') /* Payment */}
                border={!!errors.payment ? '1px solid red' : undefined}
              >
                <FormControl mb={2} isInvalid={!!errors.payment?.amount}>
                  <FormLabel>{t('common:amount.label') /* Amount */}</FormLabel>
                  <Input
                    disabled={!privileges.PROJECT_SHIPMENTS.UPDATE}
                    {...register('payment.amount', { required: false, valueAsNumber: true })}
                    placeholder={t('common:amount.label') /* Amount */}
                  />
                </FormControl>
                <Controller
                  control={control}
                  name={`payment.currency`}
                  render={({ field }) => (
                    <FormControl mb={2} isInvalid={!!errors.payment?.currency}>
                      <FormLabel>{t('common:currency.label') /* Currency */}</FormLabel>
                      <Select
                        disabled={!privileges.PROJECT_SHIPMENTS.UPDATE}
                        {...field}
                        placeholder={t('common:currency.placeholder') /* Select currency */}
                      >
                        {config?.currencies.map((currency: { name: string; code: string }) => (
                          <option key={currency.code} value={currency.code}>
                            {currency.name}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
                <Controller
                  control={control}
                  name={'payment.type'}
                  render={({ field }) => (
                    <FormControl mb={2}>
                      <FormLabel>{t('shipment:payment.type.label') /* Type */}</FormLabel>
                      <Select {...field} placeholder="Select payment type" mb={2}>
                        <option value={'prepaid'}>{t('shipment:payment.type.prepaid.label') /* Prepaid */}</option>
                        <option value="cashondelivery">
                          {t('shipment:payment.type.cod.label') /* Cash on delivery */}
                        </option>
                      </Select>
                    </FormControl>
                  )}
                />
                <FormControl mb={2} isInvalid={!!errors.payment?.paymentReference}>
                  <FormLabel>{t('shipment:payment.reference.label') /* Payment reference */}</FormLabel>
                  <Input
                    disabled={!privileges.PROJECT_SHIPMENTS.UPDATE}
                    {...register('payment.paymentReference', { required: false })}
                    placeholder={t('shipment:payment.reference.label') /* Payment reference */}
                  />
                </FormControl>
              </FormCard>
              <FormCard
                heading={t('shipment:insurance.label') /* Insurance */}
                border={!!errors.insurance ? '1px solid red' : undefined}
              >
                <FormControl mb={2} isInvalid={!!errors.insurance?.value}>
                  <FormLabel>{t('common:amount.label') /* Amount */}</FormLabel>
                  <Input
                    disabled={!privileges.PROJECT_SHIPMENTS.UPDATE}
                    {...register('insurance.value', { required: false, valueAsNumber: true })}
                    placeholder={t('common:amount.label') /* Amount */}
                  />
                </FormControl>
                <Controller
                  control={control}
                  name={`insurance.currency`}
                  render={({ field }) => (
                    <FormControl mb={2} isInvalid={!!errors.payment?.currency}>
                      <FormLabel>{t('common:currency.label') /* Currency */}</FormLabel>
                      <Select
                        disabled={!privileges.PROJECT_SHIPMENTS.UPDATE}
                        {...field}
                        placeholder={t('common:currency.placeholder') /* Select currency */}
                      >
                        {config?.currencies.map((currency: { name: string; code: string }) => (
                          <option key={currency.code} value={currency.code}>
                            {currency.name}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </FormCard>
            </GridItem>
          </Grid>
          <Flex
            direction={{ base: 'column', md: 'row' }} // Stack vertically on mobile, horizontally on desktop
            width="100%"
            justifyContent={{ base: 'center', md: 'flex-end' }}
            alignItems="center"
            p={4}
            background={useColorModeValue('white', 'gray.900')}
            boxShadow="lg"
            borderRadius="lg"
          >
            <PrivilegeContainer entity={Entity.PROJECT_SHIPMENTS} operation={Operation.DELETE}>
              <DangerButton
                minW={100}
                onClick={async () => await onDelete()}
                title={t('common:delete.label')}
                body={t('shipment:delete.body') /*'Are you sure you want to delete this shipment?'*/}
                width={{
                  base: '100%',
                  md: '100px',
                }}
              />
            </PrivilegeContainer>
            <Box display={{ base: 'none', md: 'block' }} width="20px"></Box>

            <PrivilegeContainer entity={Entity.PROJECT_SHIPMENTS} operation={Operation.UPDATE}>
              <Button
                rightIcon={<IoMdSave />}
                type="submit"
                width={{
                  base: '100%',
                  md: '250px',
                }}
                mt={{
                  base: 4,
                  md: 0,
                }}
              >
                {t('common:save.label')}
              </Button>
            </PrivilegeContainer>
          </Flex>
        </form>
      </FormProvider>
    </AuthenticatedLayout>
  );
};
