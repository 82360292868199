import { ProjectShippingCarrierGenericFieldJson, ShippingCarrierField } from '../../types/project-shipping-carrier';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Heading,
  FormLabel,
  Input,
  Box,
  FormErrorMessage,
  FormControl,
} from '@chakra-ui/react';
import { PrivilegeContainer } from '../generic/PrivilegeContainer';
import { Entity, Operation, usePrivileges } from '../../hooks/roles';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type TCarrierSettingsModal = {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: FormValues) => void;
  authenticationSettings: ProjectShippingCarrierGenericFieldJson | null | undefined;
  genericSettings: ProjectShippingCarrierGenericFieldJson | null | undefined;
  authenticationFields: ShippingCarrierField[] | null | undefined;
  genericFields: ShippingCarrierField[] | null | undefined;
};

export type FormValues = {
  authenticationFields: { [key: string]: string };
  genericFields: { [key: string]: string };
};

const CarrierSettingsModal = ({
  title,
  isOpen,
  onClose,
  onSubmit,
  authenticationFields,
  genericFields,
  authenticationSettings,
  genericSettings,
}: TCarrierSettingsModal) => {
  const { t } = useTranslation(['common', 'project']);
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<FormValues>({});

  console.log(errors);
  const privileges = usePrivileges();
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box pb={10}>
              <Heading size="md">
                {
                  t('project:shipping-carrier-settings.settings.authentication') //'Authentication'
                }
              </Heading>
              {authenticationFields?.map((field) => (
                <FormControl
                  key={field.key}
                  isInvalid={errors?.authenticationFields && field.key in errors.authenticationFields}
                  pb={4}
                >
                  <FormLabel htmlFor={field.key}>{field.label}</FormLabel>
                  <Input
                    disabled={!privileges.PROJECTS.UPDATE}
                    defaultValue={
                      authenticationSettings && field.key in authenticationSettings
                        ? authenticationSettings[field.key]
                        : ''
                    }
                    {...register(`authenticationFields.${field.key}`, { required: true })}
                  />
                  {errors?.authenticationFields && field.key in errors.authenticationFields ? (
                    <FormErrorMessage>
                      {
                        t('project:shipping-carrier-settings.settings.field-is-required', {
                          field: field.label,
                        }) /* {field.label} is required */
                      }
                      {/* {field.label} is required */}
                    </FormErrorMessage>
                  ) : (
                    ''
                  )}
                </FormControl>
              ))}
            </Box>
            {genericFields && genericFields?.length > 0 ? (
              <Box pb={10}>
                <Heading size="md">
                  {
                    t('project:shipping-carrier-settings.settings.details') //'Details'
                  }
                </Heading>
                {genericFields?.map((field) => (
                  <FormControl
                    key={field.key}
                    isInvalid={errors?.genericFields && field.key in errors.genericFields}
                    pb={4}
                  >
                    <FormLabel htmlFor={field.key}>{field.label}</FormLabel>
                    <Input
                      disabled={!privileges.PROJECTS.UPDATE}
                      defaultValue={genericSettings && field.key in genericSettings ? genericSettings[field.key] : ''}
                      {...register(`genericFields.${field.key}`, { required: true })}
                    />
                    {errors?.genericFields && field.key in errors.genericFields ? (
                      <FormErrorMessage>
                        {
                          t('project:shipping-carrier-settings.settings.field-is-required', {
                            field: field.label,
                          }) /* {field.label} is required */
                        }
                      </FormErrorMessage>
                    ) : (
                      ''
                    )}
                  </FormControl>
                ))}
              </Box>
            ) : null}
            <ModalFooter>
              <Button variant="ghost" mr={3} onClick={onClose}>
                {t('common:close.label') /* Close */}
              </Button>
              <PrivilegeContainer entity={Entity.PROJECTS} operation={Operation.UPDATE}>
                <Button type="submit" colorScheme="blue">
                  {t('common:save.label') /* Save */}
                </Button>
              </PrivilegeContainer>
            </ModalFooter>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CarrierSettingsModal;
