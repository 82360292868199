import { ProjectShippingCarrier, ShippingCarrier } from '../../types/project-shipping-carrier';
import { Image, Heading, Stack, Button, CardBody, CardFooter, Badge, Text, useToast } from '@chakra-ui/react';
import { SoftCard } from '../generic/SoftCard';
import { useState } from 'react';
import CarrierSettingsModal, { FormValues } from './CarrierSettingsModal';
import { useProjectShippingCarrierActions } from '../../hooks/project-shipping-carrier-actions';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

type TCarrierSettings = {
  title: string;
  logo: string;
  settings: ProjectShippingCarrier | null;
  carrier: ShippingCarrier;
  mutate: () => void;
  available: boolean;
  disabled: boolean;
};

const CarrierSettings = ({
  title,
  logo,
  settings,
  carrier,
  mutate,
  available = true,
  disabled = false,
}: TCarrierSettings) => {
  const { t } = useTranslation(['common', 'project']);
  const [isOpen, setIsOpen] = useState(false);
  const { createProjectShippingCarrier, updateProjectShippingCarrier } = useProjectShippingCarrierActions();
  const { id } = useParams();
  const toast = useToast();
  console.log('CarrierSettings', settings, carrier, available, disabled, id, !settings && !available);
  const onClose = () => setIsOpen(false);
  return (
    <SoftCard direction={{ base: 'column', sm: 'row' }} overflow="hidden" variant="outline">
      <Image borderRadius="full" objectFit="contain" maxW={{ base: '50%', sm: '100px' }} src={logo} />

      <Stack>
        <CardBody>
          <Heading size="md">{title}</Heading>
          <Badge colorScheme={settings ? 'green' : 'orange'} fontSize="small">
            {
              settings
                ? t('common.active.label')
                : // 'Active'
                  t('common.inacitve.label')
              // 'Ianctive'
            }
          </Badge>
          <Text variant="text">
            {
              settings
                ? t('project:shipping-carrier-settings.settings.correct') //Shipping carrier is setup correctly. You can now use this integration.
                : t('project:shipping-carrier-settings.settings.no-setup') //'In order to use this shipping carrier, you need to setup the connection.'
            }
          </Text>
        </CardBody>

        <CardFooter>
          <Button
            variant="solid"
            colorScheme={!!settings || available ? 'blue' : 'gray'}
            onClick={() => {
              if (settings || available) {
                setIsOpen(true);
              } else {
                toast({
                  title: t('common.error.label'), // 'Error',
                  description: t('project:shipping-carrier-settings.settings.limit-reached'), // 'You have reached the limit of active shipping carriers. Please upgrade your plan to add more.'
                  status: 'error',
                  duration: 5000,
                  isClosable: true,
                });
              }
            }}
            disabled={!!settings || available}
          >
            {
              !!settings
                ? t('project:shipping-carrier-settings.settings.edit-configuration') //'Edit configuration'
                : available
                ? t('project:shipping-carrier-settings.settings.create-configuration') //'Create configuration'
                : t('project:shipping-carrier-settings.settings.limit-reached') //'Limit reached'
            }
          </Button>
        </CardFooter>
      </Stack>
      <CarrierSettingsModal
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={async (data: FormValues) => {
          console.log(data);
          if (settings) {
            await updateProjectShippingCarrier(id as string, settings.id, { ...data });
          } else {
            await createProjectShippingCarrier(id as string, { ...data, shippingCarrierId: carrier.id });
          }
          onClose();
          mutate();
        }}
        authenticationSettings={settings?.authenticationFields}
        genericSettings={settings?.genericFields}
        genericFields={carrier.requiredGenericFields}
        authenticationFields={carrier.requiredAuthenticationFields}
        title={title}
      />
    </SoftCard>
  );
};

export default CarrierSettings;
