import React, { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import {
  Table,
  TableContainer,
  Thead,
  Th,
  Tr,
  Td,
  Tbody,
  Select,
  Button,
  Flex,
  ButtonGroup,
  Box,
  Checkbox,
  Text,
  Badge,
  useColorModeValue,
} from '@chakra-ui/react';
import { MdNavigateBefore, MdNavigateNext, MdFilterAltOff } from 'react-icons/md';
import { usePrivileges } from '../../../hooks/roles';
import { DangerButton } from '../../generic/DangerButton';
import { MdDelete, MdEdit } from 'react-icons/md';
import { IoMdEye } from 'react-icons/io';
import { useProjectsContext } from '../../../providers';
import { useParams, useSearchParams, Link } from 'react-router-dom';
import { ListShipmentResponse, TFilter, TPagination, useProjectShipmentActions } from '../../../hooks';
import { useShiftSelected } from '../../../hooks/use-shift-select';
import ShipmentActions from './ShipmentActions';
import { ShipmentStatus } from '../../../types/shipment';
import { useTranslation } from 'react-i18next';
import ThFilter, { FilterOpearator } from './ThFilter';
import { useMetadataActions } from '../../../hooks/use-metadata-actions';

type CarrierColumn = {
  color: string;
  background: string;
  name: string;
};

const ProjectShipmentList: React.FC = () => {
  const { t } = useTranslation(['shipment', 'common']);
  const { config } = useMetadataActions();
  const [carrierStyling, setCarrierStyling] = useState<{
    [key: string]: CarrierColumn;
  }>({});

  useEffect(() => {
    // convert config.carriers array to object with keys as carrier.id for easier access
    if (config?.carriers) {
      const carrierStyling = config.carriers.reduce((acc: any, carrier: any) => {
        acc[carrier.id] = {
          color: carrier.secondaryColor,
          background: carrier.mainColor,
          name: carrier.name,
        };
        return acc;
      }, {});
      setCarrierStyling(carrierStyling);
    }
  }, [config]);

  const privileges = usePrivileges();
  const [shipments, setShipments] = useState<ListShipmentResponse>({
    shipments: [],
    pagination: {
      page: 0,
      pageSize: 0,
      offset: 0,
      dataLength: 0,
      total: 0,
      links: {
        first: '',
        previous: null,
        next: null,
        last: '',
      },
    },
  });

  const PAGINATION_LIMIT = [
    { label: '50', value: 50 },
    { label: '100', value: 100 },
    { label: '150', value: 150 },
    { label: '200', value: 200 },
    { label: '300', value: 300 },
    { label: '400', value: 400 },
  ];

  const COLUMNS: {
    label: string;
    value: string | string[];
    filter: boolean;
    filterProps?: {
      filterKey: string;
      type: 'text' | 'select' | 'number' | 'date';
      options?: { key: string; label: string }[];
    };
  }[] = [
    {
      label: t('shipment:reference.label' /**Ref.*/),
      value: 'reference',
      filter: true,
      filterProps: {
        filterKey: 'reference',
        type: 'text',
      },
    },
    {
      label: t('shipment:carrier.label' /**Carrier*/),
      value: 'carrier',
      filter: true,
      filterProps: {
        filterKey: 'carrier',
        type: 'select',
        options: [
          { key: 'CESKAPOSTA', label: 'Česká Pošta' },
          { key: 'PPL', label: 'PPL' },
          { key: 'PACKETA', label: 'Packeta' },
        ],
      },
    },
    {
      label: t('common:person-name.label' /**Name*/),
      value: ['recipient.firstName', 'recipient.lastName'],
      filter: false,
    },
    {
      label: t('common:email.label'),
      value: 'recipient.email',
      filter: true,
      filterProps: {
        filterKey: 'recipient.email',
        type: 'text',
      },
    },
    {
      label: t('common:country.label'),
      value: 'recipient.country',
      filter: true,
      filterProps: {
        filterKey: 'recipient.country',
        type: 'select',
        options:
          config?.countries?.map((country: { name: string; code: string }) => ({
            key: country.code,
            label: country.name,
          })) || [],
      },
    },
    { label: t('shipment:cod.label') /* COD */, value: 'payment.amount', filter: false },
    {
      label: t('common:created.label' /**Created */),
      value: 'createdAt',
      filter: true,
      filterProps: {
        filterKey: 'shipments.createdAt',
        type: 'date',
      },
    },
    { label: t('shipment:parcels.label' /**Parcels */), value: 'parcels.length', filter: false },
    {
      label: t('shipment:status.label' /**Status */),
      value: 'status',
      filter: true,
      filterProps: {
        filterKey: 'status',
        type: 'select',
        options: [
          { key: 'IMPORTED', label: t('shipment:status.imported') },
          { key: 'PROCESSING', label: t('shipment:status.processing') },
          { key: 'SENT', label: t('shipment:status.sent') },
          { key: 'FINISHED', label: t('shipment:status.finished') },
          { key: 'ERROR', label: t('shipment:status.error') },
        ],
      },
    },
  ];

  const {
    onChange: onSelect,
    toggleSelectAll,
    isSelected,
    allSelected,
    selected: selectedShipments,
  } = useShiftSelected<string>(
    [],
    shipments?.shipments.map((s) => s.id)
  );

  const [searchParams, setSearchParams] = useSearchParams();

  // Get initial filter values from query params
  const initialFilters: TFilter = {};
  searchParams.forEach((value, key) => {
    if (key !== 'limit' && key !== 'page') {
      initialFilters[key] = JSON.parse(value) as {
        value: string | string[] | number | boolean | Date | Date[];
        operator?: FilterOpearator;
      };
    }
  });

  const [filters, setFilters] = useState<TFilter>(initialFilters);
  console.log('filters', filters);

  // define filters state and setter function as query params single source of truth

  const { selectedProject: project } = useProjectsContext();
  const { id: projectId } = useParams();

  const [pagination, setPagination] = useState<TPagination>({
    limit: parseInt(searchParams.get('limit') as string) || PAGINATION_LIMIT[0].value,
    page: parseInt(searchParams.get('page') as string) || 1,
  });

  const updateQueryParams = (newFilters: TFilter, newPagination: TPagination) => {
    const params = new URLSearchParams();
    Object.entries(newFilters).forEach(([key, { value, operator }]) => {
      params.set(key, JSON.stringify({ value, operator }));
    });
    params.set('limit', String(newPagination.limit));
    params.set('page', String(newPagination.page));
    setSearchParams(params);
  };

  useEffect(() => {
    updateQueryParams(filters, pagination);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, pagination]);

  const uiMode = useColorModeValue('light', 'dark');

  const { getProjectShipments, deleteShipment: deleteShipmentAction } = useProjectShipmentActions();
  // const navigate = useNavigate();

  const setLimit = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPagination({ ...pagination, limit: parseInt(e.target.value) });
  };

  const nextPage = () => {
    setPagination({ ...pagination, page: pagination.page + 1 });
  };

  const prevPage = () => {
    if (pagination.page > 1) {
      setPagination({ ...pagination, page: pagination.page - 1 });
    }
  };

  const getShipments = async () => {
    if (project && projectId) {
      const data = await getProjectShipments(projectId, pagination.page, pagination.limit, filters);
      setShipments(data as ListShipmentResponse);
    }
  };

  useEffect(() => {
    console.log('getShipments', projectId, project, pagination, filters);
    getShipments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, project, pagination, getProjectShipments, filters]);

  const calculateOffset = useCallback((page: number, limit: number) => {
    return (page - 1) * limit;
  }, []);

  // const editShipment = (shipmentId: string) => {
  //   navigate(`/projects/${projectId}/shipments/${shipmentId}`);
  // };
  // const viewShipment = (shipmentId: string) => {
  //   navigate(`/projects/${projectId}/shipments/${shipmentId}/view`);
  // };

  const deleteShipment = async (projectId: string, shipmentId: string) => {
    await deleteShipmentAction(projectId, shipmentId);
    setShipments({
      shipments: shipments?.shipments.filter((s) => s.id !== shipmentId),
      pagination: shipments?.pagination,
    });
  };

  const isToday = (date: Date) => {
    const today = new Date();
    date = new Date(date);
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };

  const tableColor = (status: string) => {
    switch (status) {
      case 'IMPORTED':
        return uiMode === 'light' ? 'yellow.200' : 'yellow.900';
      case 'SENT':
        return '';
      case 'FINISHED':
        return uiMode === 'light' ? 'green.200' : 'green.900';
      case 'ERROR':
        return uiMode === 'light' ? 'red.200' : 'red.900';
      default:
        return '';
    }
  };

  const localizeShipmentStatus = useCallback(
    (status: ShipmentStatus): string => {
      switch (status) {
        case ShipmentStatus.Imported:
          return t('shipment:status.imported');
        case ShipmentStatus.Sent:
          return t('shipment:status.sent');
        case ShipmentStatus.Error:
          return t('shipment:status.error');
        case ShipmentStatus.Processing:
          return t('shipment:status.processing');
        case ShipmentStatus.Finished:
          return t('shipment:status.finished');
        default:
          return '';
      }
    },
    [t]
  );

  const clearFilters = () => {
    setFilters({});
  };

  if (!project) return null;
  if (!shipments) return null;

  return (
    <>
      {/* <ShipmentFilters /> */}
      <Flex minWidth="max-content" alignItems="center" gap="2">
        <ButtonGroup></ButtonGroup>
      </Flex>
      <TableContainer w="100%" mt={6}>
        <Table size="sm">
          <Thead>
            <Tr>
              <Th>
                <Checkbox
                  isChecked={
                    /* Check if all shipments visible on the page are selected */
                    allSelected
                  }
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => toggleSelectAll()}
                />
              </Th>
              {COLUMNS.map((column) =>
                column.filter && column.filterProps ? (
                  <ThFilter
                    value={filters[column.filterProps.filterKey]?.value}
                    filterOperator={filters[column.filterProps.filterKey]?.operator}
                    set={(
                      value: string | string[] | number | boolean | Date | Date[] | undefined,
                      operator?: FilterOpearator
                    ) => {
                      if (!column?.filterProps?.filterKey) return;
                      if (value === '' || value === undefined) {
                        // remove filter from state
                        const { [column.filterProps.filterKey]: _, ...rest } = filters;
                        setFilters(rest);
                        return;
                      }
                      setFilters({ ...filters, [column.filterProps.filterKey]: { value: value, operator } });
                    }}
                    key={column.value as string}
                    title={column.label}
                    {...column.filterProps}
                  />
                ) : (
                  <Th key={column.value as string}>{column.label}</Th>
                )
              )}
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {shipments?.shipments?.map((shipment) => (
              <Tr key={shipment.id} backgroundColor={tableColor(shipment.status)}>
                <Td>
                  <Checkbox
                    isChecked={isSelected(shipment.id)}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => onSelect(event, shipment.id)}
                  />
                </Td>
                <Td pl={0}>
                  {isToday(shipment.createdAt) ? (
                    <Badge colorScheme="blue">{shipment.reference}</Badge>
                  ) : (
                    shipment.reference
                  )}
                </Td>
                <Td>
                  {carrierStyling && carrierStyling[shipment.carrier] ? (
                    <Badge
                      borderRadius={8}
                      p={0.5}
                      pl={1}
                      pr={1}
                      color={carrierStyling[shipment.carrier].color}
                      backgroundColor={carrierStyling[shipment.carrier].background}
                    >
                      {carrierStyling[shipment.carrier].name}
                    </Badge>
                  ) : (
                    shipment.carrier
                  )}
                </Td>
                <Td>
                  {shipment?.recipient?.firstName} {shipment?.recipient?.lastName}
                </Td>
                <Td>{shipment?.recipient?.email}</Td>
                <Td>{shipment?.recipient?.country}</Td>
                <Td>
                  {shipment?.payment?.type === 'cashondelivery'
                    ? `${shipment?.payment?.amount} ${shipment?.payment?.currency}`
                    : ``}
                </Td>
                <Td>{dayjs(shipment.createdAt).format('DD. MM. YYYY HH:mm:ss')}</Td>
                <Td pl={0}>{shipment.parcels?.length}</Td>

                <Td>{localizeShipmentStatus(shipment.status)}</Td>
                <Td style={{ textAlign: 'end' }}>
                  {privileges.PROJECT_SHIPMENTS.DELETE /* Check also state and block deleting of shipped orders */ && (
                    <DangerButton
                      onClick={() => {
                        deleteShipment(project.id, shipment.id);
                      }}
                      icon={<MdDelete />}
                      centerIcon
                      size="sm"
                      title={t('common:delete.label')}
                      showTitle={false}
                      variant="ghost"
                      body={t('shipment:delete.body') /*'Are you sure you want to delete this user?'*/}
                      mr={2}
                    />
                  )}
                  {privileges.PROJECT_SHIPMENTS.UPDATE &&
                  ![ShipmentStatus.Sent, ShipmentStatus.Finished].includes(shipment.status) ? (
                    <Link to={`/projects/${projectId}/shipments/${shipment.id}`}>
                      {/* <Button size="sm" variant="ghost" onClick={() => editShipment(shipment.id)}> */}
                      <Button size="sm" variant="ghost">
                        <MdEdit />
                      </Button>
                    </Link>
                  ) : null}
                  {privileges.PROJECT_SHIPMENTS.READ &&
                  ![ShipmentStatus.Imported, ShipmentStatus.Processing].includes(shipment.status) ? (
                    <Link to={`/projects/${projectId}/shipments/${shipment.id}/view`}>
                      {/* <Button size="sm" variant="ghost" onClick={() => viewShipment(shipment.id)}> */}
                      <Button size="sm" variant="ghost">
                        <IoMdEye />
                      </Button>
                    </Link>
                  ) : null}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Flex minWidth="max-content" alignItems="center" gap="2" mt={4}>
        {selectedShipments?.length > 0 && (
          <ShipmentActions
            selected={selectedShipments}
            shipments={shipments?.shipments}
            mutateShipments={() => getShipments}
          />
        )}
        {filters && Object.keys(filters).length > 0 && (
          <Button size="xs" variant="ghost" onClick={clearFilters} leftIcon={<MdFilterAltOff />}>
            {t('common:clear-all-filter.label')}
          </Button>
        )}

        <Text marginLeft="auto">{t('shipment:pagination.rows-per-page') /* Rows per page */}</Text>
        <Box mr={10}>
          <Select
            defaultValue={pagination.limit || PAGINATION_LIMIT[0].value}
            size="xs"
            onChange={setLimit}
            variant="flushed"
          >
            {
              // eslint-disable-next-line react/jsx-key
              PAGINATION_LIMIT.map(({ label, value }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))
            }
          </Select>
        </Box>
        <Text>
          {t('shipment:pagination.selected-out-of', {
            selected: `${calculateOffset(pagination.page, pagination.limit) + 1}-${
              calculateOffset(pagination.page, pagination.limit) + shipments?.pagination?.dataLength
            }`,
            total: shipments?.pagination?.total,
          })}
        </Text>
        <ButtonGroup>
          <Button
            variant="ghost"
            isDisabled={shipments?.pagination?.links?.previous == null ? true : false}
            onClick={() => prevPage()}
          >
            <MdNavigateBefore />
          </Button>

          <Button
            variant="ghost"
            isDisabled={shipments?.pagination?.links?.next == null ? true : false}
            onClick={() => nextPage()}
          >
            <MdNavigateNext />
          </Button>
        </ButtonGroup>
      </Flex>
    </>
  );
};

export default ProjectShipmentList;
