import { useCallback } from 'react';
import { IconButton, IconButtonProps } from '@chakra-ui/react';
import i18n from '../../i18n';
import ReactCountryFlag from 'react-country-flag';
type LangSwitcherProps = Omit<IconButtonProps, 'aria-label'>;

export const LanguageSwitcher: React.FC<LangSwitcherProps> = (props) => {
  const Icon = useCallback(() => {
    switch (i18n.language) {
      case 'cs':
        return (
          <IconButton
            size="md"
            fontSize="lg"
            variant="ghost"
            marginLeft="2"
            onClick={() => i18n.changeLanguage('en')}
            icon={<ReactCountryFlag className="emojiFlag" countryCode="GB" aria-label="English" />}
            aria-label={`Switch to EN`}
            {...props}
          />
        );
      case 'en':
        return (
          <IconButton
            size="md"
            fontSize="lg"
            variant="ghost"
            marginLeft="2"
            onClick={() => i18n.changeLanguage('cs')}
            icon={<ReactCountryFlag className="emojiFlag" countryCode="CZ" aria-label="Czech" />}
            aria-label={`Switch to CS`}
            {...props}
          />
        );
    }
  }, [props]);

  return <Icon />;
};
