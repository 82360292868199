import { User } from '../types';
import { useApiActions } from './api-actions';

export type ProvisionUserResponse = {
  me: User;
};

export type UserDetailResponse = User;

export const useUserActions = () => {
  const { executeApiAction } = useApiActions();

  const detail = () =>
    executeApiAction<User>({
      action: async ({ client }) => {
        const detail = await client.get('user/detail').json<UserDetailResponse>();
        return detail;
      },
      errorMessage: 'Failed to sign in',
    });

  const sendSupportMessage = (message: string) =>
    executeApiAction({
      action: ({ client }) => client.post('communications/support', { json: { message } }),
      successMessage: 'Thank you for your message!',
      errorMessage: 'Failed to send message',
    });

  return {
    detail,
    sendSupportMessage,
  };
};
