export type Payment = {
  amount: number;
  currency: string;
  type: 'prepaid' | 'cashondelivery';
  createdAt?: Date;
  updatedAt?: Date;
};

export const convertPaymentType = (type: Payment['type']): string => {
  switch (type) {
    case 'prepaid':
      return 'prepaid';
    case 'cashondelivery':
      return 'cod';
    default:
      return 'Prepaid';
  }
};
