import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

const KEY = 'locale';
i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(detector)
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: localStorage.getItem(KEY) || 'cs',
    fallbackLng: 'cs',
    load: 'languageOnly',
    debug: false,
    supportedLngs: ['cs', 'en'],
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    defaultNS: 'default',
    ns: [
      'default',
      'dashboard',
      'shipment',
      'common',
      'unauth',
      'project',
      'users',
      'support',
      'profile',
      'onboarding',
    ],
    detection: {
      order: ['localStorage', 'navigator'],
      lookupCookie: KEY,
      lookupQuerystring: KEY,
      lookupLocalStorage: KEY,
      caches: ['localStorage', 'cookie'],
    },
  });

export default i18n;
