import { Text, SimpleGrid, VStack } from '@chakra-ui/react';
import { MdDashboard } from 'react-icons/md';

import { PageHeading } from '../components/generic/PageHeading';
import { SoftCard } from '../components/generic/SoftCard';
import { StatCard, StatProps } from '../components/generic/StatCard';
import { AuthenticatedLayout } from '../layouts';
import { useAuthContext, useProjectsContext } from '../providers';
import { useTranslation } from 'react-i18next';
import { useProjectActions } from '../hooks';
import { useEffect, useState } from 'react';

export const DashboardPage: React.FC = () => {
  const { t } = useTranslation(['common', 'dashboard']);
  const [stats, setStats] = useState<StatProps[]>([]);
  const { selectedProject: project } = useProjectsContext();
  const { getProjectStats } = useProjectActions();
  const { user } = useAuthContext();

  const formatStatsNumber = (number: number): string => {
    if (isNaN(number)) {
      return `0`;
    }
    if (number === Infinity) {
      return `0`;
    }

    if (number > 1000000) {
      return `${(number / 1000000).toFixed(1)}M`;
    }
    if (number > 1000) {
      return `${(number / 1000).toFixed(1)}K`;
    }
    return `${number}`;
  };

  useEffect(() => {
    const fetchProjectStatus = async () => {
      if (!project) {
        return;
      }
      let dateFrom = new Date();
      dateFrom.setDate(dateFrom.getDate() - 30);
      const dateTo = new Date();

      const resp = await getProjectStats(project.id, dateFrom.toISOString(), dateTo.toISOString());
      if (!resp) return;

      const shipmentsPercentage = Math.round(
        ((resp.current.parcels - resp.previous.parcels) / resp.previous.parcels) * 100
      );
      const deliveredPercentage = Math.round(
        ((resp.current.delivered - resp.previous.delivered) / resp.previous.delivered) * 100
      );
      const returnedPercentage = Math.round(
        ((resp.current.returned - resp.previous.returned) / resp.previous.returned) * 100
      );
      const recipientsPercentage = Math.round(
        ((resp.current.recipients - resp.previous.recipients) / resp.previous.recipients) * 100
      );
      const inTransit = resp.current.parcels - resp.current.delivered - resp.current.returned;
      const inTransitPercentage = Math.round((inTransit / resp.current.parcels) * 100);

      setStats([
        {
          title: t('dashboard:stats.shipments.title'), //Shipments',
          number: `${resp.current.parcels}`,
          percentage: formatStatsNumber(shipmentsPercentage),
          type: shipmentsPercentage > 0 ? 'increase' : 'decrease',
        },
        {
          title: t('dashboard:stats.customers.title'), //'Customers',
          number: `${resp.current.recipients}`,
          percentage: formatStatsNumber(recipientsPercentage),
          type: 'increase',
        },
        {
          title: t('dashboard:stats.delivered.title'), //'Delivered',
          number: `${resp.current.delivered}`,
          percentage: formatStatsNumber(deliveredPercentage),
          type: deliveredPercentage > 0 ? 'increase' : 'decrease',
        },
        {
          title: t('dashboard:stats.awaiting-delivery.title'), //'Awaiting delivery',
          number: `${inTransit}`,
          percentage: formatStatsNumber(inTransitPercentage),
          type: 'increase',
        },
        {
          title: t('dashboard:stats.returned.title'), //'Returned',
          number: `${resp.current.returned}`,
          percentage: formatStatsNumber(returnedPercentage),
          type: returnedPercentage > 0 ? 'increase' : 'decrease',
        },
      ]);
    };
    fetchProjectStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  return (
    <AuthenticatedLayout>
      <VStack spacing={6} align="flex-start">
        <SoftCard w="100%">
          <PageHeading title={t('dashboard:title')} icon={MdDashboard} />
          <Text mt={6}>{t('dashboard:welcome', { name: user?.firstName }) /*`Welcome, ${user?.firstName} 👋`*/}</Text>
        </SoftCard>
        <SimpleGrid pt={2} minChildWidth="200px" width="100%" spacingX="40px" spacingY="20px">
          {stats.map((stat, index) => (
            <StatCard key={`card-${index}`} {...stat} />
          ))}
        </SimpleGrid>
      </VStack>
    </AuthenticatedLayout>
  );
};
