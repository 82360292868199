import { Table, Tbody, Tr, Td, TableContainer } from '@chakra-ui/react';
import { Payment, convertPaymentType } from '../../../types/shipment';
import { useTranslation } from 'react-i18next';

type PaymentSectionProps = {
  data: Payment;
};

export const PaymentSection: React.FC<PaymentSectionProps> = ({ data }) => {
  /** Tabs view of parcels with basic data and tracking */
  const { t } = useTranslation(['shipment', 'common']);
  const { amount, currency, type } = data;
  return (
    <TableContainer>
      <Table size="sm">
        <Tbody>
          <Tr>
            <Td width="10%">{t('common:amount.label')}</Td>
            <Td>
              {amount} {currency}
            </Td>
          </Tr>
          <Tr>
            <Td width="10%">{t('shipment:payment.type.label')}</Td>
            <Td>{t(`shipment:payment.type.${convertPaymentType(type)}.label`)}</Td>
          </Tr>
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default PaymentSection;
