import { Box } from '@chakra-ui/react';
import { OnDateSelected, RangeCalendarPanel } from 'chakra-dayzed-datepicker';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

const RangeCalendar = ({
  selectedDates,
  setSelectedDates,
}: {
  selectedDates: Date[];
  setSelectedDates: (dates: Date[]) => void;
}) => {
  const { t } = useTranslation('common');

  const handleOnDateSelected: OnDateSelected = ({ selectable, date }) => {
    let newDates = [...selectedDates];
    if (selectedDates.length) {
      if (selectedDates.length === 1) {
        let firstTime = selectedDates[0];
        if (firstTime < date) {
          newDates.push(date);
        } else {
          newDates.unshift(date);
        }
        setSelectedDates(newDates);
        return;
      }

      if (newDates.length === 2) {
        setSelectedDates([date]);
        return;
      }
    } else {
      newDates.push(date);
      setSelectedDates(newDates);
    }
  };

  const today = new Date();
  const isFirstDayOfMonth = today.getDate() === 1;

  // If today is not the first day of the month, calculate the first day of the previous month
  let initialDate;
  if (!isFirstDayOfMonth) {
    initialDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
  } else {
    // If today is the first day of the month, start from today
    initialDate = today;
  }

  // eventually we want to allow user to freely type their own input and parse the input
  let intVal = selectedDates[0] ? `${format(selectedDates[0], 'dd-MM-yyyy')}` : '';
  intVal += selectedDates[1] ? ` - ${format(selectedDates[1], 'dd-MM-yyyy')}` : '';

  return (
    <Box>
      <Box>{intVal}</Box>
      <RangeCalendarPanel
        selected={selectedDates}
        dayzedHookProps={{
          showOutsideDays: false,
          onDateSelected: handleOnDateSelected,
          selected: selectedDates,
          monthsToDisplay: 2,
          maxDate: new Date(),
          date: initialDate,
        }}
        configs={{
          dateFormat: 'dd/MM/yyyy',
          monthNames: [
            t('common:calendar.months.january'),
            t('common:calendar.months.february'),
            t('common:calendar.months.march'),
            t('common:calendar.months.april'),
            t('common:calendar.months.may'),
            t('common:calendar.months.june'),
            t('common:calendar.months.july'),
            t('common:calendar.months.august'),
            t('common:calendar.months.september'),
            t('common:calendar.months.october'),
            t('common:calendar.months.november'),
            t('common:calendar.months.december'),
          ],
          dayNames: [
            t('common:calendar.days.sunday'),
            t('common:calendar.days.monday'),
            t('common:calendar.days.tuesday'),
            t('common:calendar.days.wednesday'),
            t('common:calendar.days.thursday'),
            t('common:calendar.days.friday'),
            t('common:calendar.days.saturday'),
          ],
          firstDayOfWeek: 1,
        }}
        propsConfigs={{
          dayOfMonthBtnProps: {
            defaultBtnProps: {
              _hover: {
                background: 'blue.300',
              },
            },
            isInRangeBtnProps: {
              background: '#0085f230',
            },

            selectedBtnProps: {
              background: '#0085f230',
              _hover: {
                background: '#0085f230',
              },
              _selected: {
                background: '#0085f230',
              },
            },
          },
          dateNavBtnProps: {
            _hover: {
              background: '#0085f230',
            },
          },
        }}
      />
    </Box>
  );
};

export default RangeCalendar;
