import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  ButtonProps,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

export const DangerButton = ({
  title,
  icon,
  centerIcon,
  onClick,
  body,
  showTitle = true,
  ...rest
}: {
  title: string;
  icon?: ReactElement<any>;
  centerIcon?: boolean;
  onClick: () => void;
  body: string;
  showTitle?: boolean;
} & ButtonProps) => {
  const { t } = useTranslation('common');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  return (
    <>
      <Button
        {...rest}
        w={'auto'}
        onClick={onOpen}
        leftIcon={!centerIcon ? icon : undefined}
        colorScheme={rest.colorScheme ? rest.colorScheme : 'red'}
      >
        {showTitle && title ? title : icon}
      </Button>
      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {title}
            </AlertDialogHeader>

            <AlertDialogBody>{body}</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                {t('common:cancel.label') /**Cancel */}
              </Button>
              <Button
                onClick={() => {
                  onClick();
                  onClose();
                }}
                ml={3}
                colorScheme="red"
              >
                {title}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
