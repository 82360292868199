import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SimpleGrid, Text } from '@chakra-ui/react';
import { MdOutlineLocalShipping } from 'react-icons/md';

import { AuthenticatedLayout } from '../layouts';
import { ProjectShippingCarrierRequest } from '../types';
import { PageHeading } from '../components/generic/PageHeading';
import { useProjectShippingCarrierActions } from '../hooks/project-shipping-carrier-actions';
import CarrierSettings from '../components/project-shipping-carrier/CarrierSettings';
import { LoadingBar } from '../components/generic/LoadingBar';
import { useTranslation } from 'react-i18next';
import { usePlanLimitations } from '../hooks/plans';

export const ProjectShippingCarrierSettingsPage: React.FC = () => {
  const { t } = useTranslation(['common', 'project']);
  const [carriers, setCarriers] = useState<ProjectShippingCarrierRequest[]>([]);
  const [activeCarriers, setActiveCarriers] = useState<number>();
  const { listProjectShippingCarriers } = useProjectShippingCarrierActions();

  const { id } = useParams();

  const mutate = () => {
    if (!id) return;
    listProjectShippingCarriers(id).then((data) => (data ? setCarriers(data) : null));
  };

  useEffect(() => {
    mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const planLimitations = usePlanLimitations();
  const projectCarrierLimit = planLimitations.PROJECT_CARRIERS as number;

  useEffect(() => {
    // set active carriers
    if (carriers) {
      const active = carriers.filter((carrier) => carrier.pojectShippingCarrier).length;
      setActiveCarriers(active);
    }
  }, [carriers]);

  if (!id) return null;

  console.log('Limits', planLimitations, projectCarrierLimit, activeCarriers);

  console.log(carriers);
  return (
    <AuthenticatedLayout>
      <PageHeading
        title={t('project:shipping-carrier-settings.label') /* Shipping Carrier Settings */}
        icon={MdOutlineLocalShipping}
      />
      <Text variant="text">
        {
          t(
            'project:shipping-carrier-settings.page-text'
          ) /* Setup shipping carrier connections such as API authentications and data which are necessary for each shipping
        carrier. */
        }
      </Text>

      {carriers ? (
        <SimpleGrid pt={8} minChildWidth="400px" width="100%" spacingX="40px" spacingY="20px">
          {carriers?.map((carrier) => (
            <CarrierSettings
              key={carrier.shippingCarrier.id}
              title={carrier.shippingCarrier.name}
              logo={carrier.shippingCarrier.logo}
              settings={carrier.pojectShippingCarrier}
              carrier={carrier.shippingCarrier}
              mutate={mutate}
              available={activeCarriers ? (activeCarriers || 0) < projectCarrierLimit : true}
              disabled={activeCarriers ? (activeCarriers || 0) >= projectCarrierLimit : false}
            />
          ))}
        </SimpleGrid>
      ) : (
        <LoadingBar full />
      )}
    </AuthenticatedLayout>
  );
};
