import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Box,
  SimpleGrid,
  Text,
  Table,
  TableContainer,
  Thead,
  Th,
  Tr,
  Td,
  Tbody,
  IconButton,
  useToast,
} from '@chakra-ui/react';
import { SoftCard } from '../components/generic/SoftCard';

import { MdShoppingCart, MdDelete, MdEdit, MdComputer, MdAdd } from 'react-icons/md';

import { AuthenticatedLayout } from '../layouts';
import { DangerButton } from '../components/generic/DangerButton';

import { PageHeading } from '../components/generic/PageHeading';
import { useProjectsSellersContext } from '../providers';
import { ProjectSeller } from '../types';
import { useProjectSellersActions } from '../hooks/project-sellers-actions';
import { usePrivileges } from '../hooks/roles';
import { CreateProjectSellers } from '../components/project-sellers/CreateProjectSellers';
import ProjectSellersModal from '../components/project-sellers/ProjectSellersModal';
import { useMetadataActions } from '../hooks/use-metadata-actions';
import { useTranslation } from 'react-i18next';
import { usePlanLimitations } from '../hooks/plans';

export const ProjectSellersSettingsPage: React.FC = () => {
  const { t } = useTranslation(['common', 'project']);
  const { sellers, mutate } = useProjectsSellersContext();
  const { deleteProjectSeller, updateProjectSeller, createProjectSeller } = useProjectSellersActions();
  const privileges = usePrivileges();
  const navigate = useNavigate();
  const { config } = useMetadataActions();
  const { id } = useParams();
  const planLimitations = usePlanLimitations();
  const sellersLimit = planLimitations?.PROJECT_SELLERS as number;

  const [sellerModal, setSellerModal] = useState<{
    title: string;
    isOpen: boolean;
    onSubmit: (name: string, language: string) => void;
    projectSeller: ProjectSeller | null;
  } | null>(null);
  const toast = useToast();

  if (!id) return null;
  console.log(sellersLimit, sellers?.length, sellers ? !(sellers.length < sellersLimit) : false);
  return (
    <AuthenticatedLayout>
      <PageHeading
        title={t('project:project-sellers.title') /*"Project Sellers"*/}
        icon={MdShoppingCart}
        cta={
          <CreateProjectSellers
            onSubmit={() => {
              setSellerModal({
                title: t('project:project-sellers.create'), //'Create Seller',
                isOpen: true,
                onSubmit: (name: string, language: string) => {
                  createProjectSeller({
                    name: name,
                    language: language,
                  });
                  setSellerModal(null);
                },
                projectSeller: null,
              });
            }}
          />
        }
        disabled={sellers ? !(sellers.length < sellersLimit) : false}
        disabledCta={
          <IconButton
            colorScheme="gray"
            style={{
              cursor: 'not-allowed',
            }}
            aria-label="Create project sellers"
            icon={<MdAdd />}
            px={6}
            size="md"
            onClick={() => {
              toast({
                title: t('project:project-sellers.limit-reached'), //'Limit reached',
                description: t('project:project-sellers.limit-reached-description'), //'You have reached the limit of sellers for this project.',
                status: 'error',
                duration: 5000,
                isClosable: true,
              });
            }}
          />
        }
      />
      <Text variant="text">
        {
          t('project:project-sellers.text')

          // Setup sellers for this project. It is possible to add multiple sellers to a project. Each shipment can be
          // attached to a seller and influence the customer's UI experience.
        }
      </Text>
      <SimpleGrid pt={8} minChildWidth="400px" width="100%" spacingX="40px" spacingY="20px">
        <Box>
          <SoftCard>
            <Box>
              <TableContainer w="100%" mt={2}>
                <Table size="md">
                  <Thead>
                    <Tr>
                      <Th>{t('common:name.label')}</Th>
                      <Th>{t('common:language.label')}</Th>
                      <Th>{t('common:actions.label')}</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {sellers?.map((seller: ProjectSeller) => {
                      return (
                        <Tr>
                          <Td>{seller.name}</Td>
                          <Td>
                            {
                              config?.languages?.find(
                                (lang: { code: string; name: string }) => lang.code === seller.language
                              )?.name
                            }
                          </Td>
                          <Td>
                            {privileges.PROJECTS.DELETE /* Check also state and block deleting of shipped orders */ && (
                              <DangerButton
                                onClick={() => {
                                  deleteProjectSeller(seller.id);
                                }}
                                icon={<MdDelete />}
                                centerIcon
                                size="sm"
                                title={t('common:delete.label') /*"Delete"*/}
                                showTitle={false}
                                variant="ghost"
                                body={
                                  t(
                                    'project:project-sellers.delete.body'
                                  ) /*'Are you sure you want to delete this seller?'*/
                                }
                                mr={2}
                              />
                            )}
                            {privileges.PROJECTS.UPDATE ? (
                              <Button
                                size="sm"
                                variant="ghost"
                                onClick={() => {
                                  setSellerModal({
                                    title: t('project:project-sellers.edit.label'), //'Edit Seller',
                                    isOpen: true,
                                    onSubmit: (name: string, language: string) => {
                                      updateProjectSeller({
                                        id: seller.id,
                                        name: name,
                                        language: language,
                                      });
                                      setSellerModal(null);
                                    },
                                    projectSeller: seller,
                                  });
                                }}
                              >
                                <MdEdit />
                              </Button>
                            ) : null}
                            {privileges.PROJECTS.UPDATE ? (
                              <Button
                                size="sm"
                                variant="ghost"
                                onClick={() => {
                                  navigate(`/projects/${id}/settings/sellers/${seller.id}/ui`);
                                }}
                              >
                                <MdComputer />
                              </Button>
                            ) : null}
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              </TableContainer>
            </Box>
          </SoftCard>
        </Box>
        <ProjectSellersModal
          title={sellerModal?.title || ''}
          isOpen={sellerModal?.isOpen || false}
          onClose={() => {
            setSellerModal(null);
          }}
          onSubmit={(data: { name: string; language: string }) => {
            const { name, language } = data;
            sellerModal?.onSubmit(name, language);
            setSellerModal(null);
            mutate();
          }}
          projectSeller={sellerModal?.projectSeller || null}
        />
      </SimpleGrid>
    </AuthenticatedLayout>
  );
};
