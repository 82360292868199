import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import uniq from 'lodash/uniq';
import difference from 'lodash/difference';

export const useShiftSelected = <P>(
  initialState: Array<P>,
  selectableItems: Array<P>
): {
  isSelected: (item: P) => boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>, item: P) => void;
  toggleSelectAll: () => void;
  allSelected: boolean;
  selected: Array<P>;
} => {
  const [selected, setSelected] = useState(initialState);
  const [previousSelected, setPreviousSelected] = useState<P | null>(null);
  const [previousChecked, setPreviousChecked] = useState<boolean>(false);
  const [currentSelected, setCurrentSelected] = useState<P | null>(null);

  const add = useCallback(
    (items: Array<P>) => {
      setSelected((oldList) => uniq([...oldList, ...items]));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setSelected]
  );

  const remove = useCallback(
    (items: Array<P>) => {
      setSelected((oldList) => difference(oldList, items));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setSelected]
  );

  const change = useCallback(
    (addOrRemove: boolean, items: Array<P>) => {
      if (addOrRemove) {
        add(items);
      } else {
        remove(items);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [add, remove]
  );

  const clear = useCallback(() => {
    setSelected([]);
    setPreviousSelected(null);
    setPreviousChecked(false);
    setCurrentSelected(null);
  }, [setSelected]);

  const selectAll = useCallback(() => {
    setSelected(selectableItems);
    setPreviousSelected(null);
    setPreviousChecked(true); // As all items are now selected
    setCurrentSelected(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectableItems]);

  const toggleSelectAll = useCallback(() => {
    if (selected.length === selectableItems.length) {
      clear();
    } else {
      selectAll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, selectableItems, clear, selectAll]);

  const isSelected = useCallback(
    (item: P): boolean => {
      return selected.includes(item);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selected, selectableItems]
  );

  const allSelected = useMemo(() => {
    return selected.length === selectableItems.length;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, selectableItems, toggleSelectAll]);

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, item: P) => {
      // @ts-ignore shiftKey is defined for click events
      if (event.nativeEvent.shiftKey) {
        const current = selectableItems.findIndex((x) => x === item);
        const previous = selectableItems.findIndex((x) => x === previousSelected);
        const previousCurrent = selectableItems.findIndex((x) => x === currentSelected);
        const start = Math.min(current, previous);
        const end = Math.max(current, previous);
        if (start > -1 && end > -1) {
          change(previousChecked, selectableItems.slice(start, end + 1));
          if (previousCurrent > end) {
            change(!previousChecked, selectableItems.slice(end + 1, previousCurrent + 1));
          }
          if (previousCurrent < start) {
            change(!previousChecked, selectableItems.slice(previousCurrent, start));
          }
          setCurrentSelected(item);
          return;
        }
      } else {
        setPreviousSelected(item);
        setCurrentSelected(null);
        setPreviousChecked(event.target.checked);
      }
      change(event.target.checked, [item]);
    },
    [
      change,
      selectableItems,
      previousSelected,
      setPreviousSelected,
      previousChecked,
      setPreviousChecked,
      currentSelected,
      setCurrentSelected,
    ]
  );

  return { onChange, toggleSelectAll, isSelected, allSelected, selected };
};
