import React, { useContext, useEffect, useState } from 'react';
import { createContext } from 'react';
import { ProjectSeller } from '../types';
import { useApiActions } from '../hooks/api-actions';
import { LoadingPage } from '../pages';
import { useProjectsContext } from './projects-provider';

interface ProjectsSellersProviderProps {
  sellers: ProjectSeller[] | null;
  setSellers: (sellers: ProjectSeller[]) => void;
  mutate: () => void;
}

export type ListProjectSellersResponse = {
  sellers: ProjectSeller[];
};

const ProjectsSellersContext = createContext<ProjectsSellersProviderProps | null>(null);

export const ProjectsSellersProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [sellers, setSellers] = useState<ProjectSeller[] | null>([]);
  const [loading, setLoading] = useState(true);
  const { executeApiAction } = useApiActions();

  const { selectedProject } = useProjectsContext();

  const mutate = () => {
    setLoading(true);
    listProjectSellers();
  };

  const listProjectSellers = async () =>
    executeApiAction<ProjectSeller[]>({
      action: async ({ client }) =>
        (await client.get(`projects/${selectedProject?.id}/sellers/`).json<ListProjectSellersResponse>()).sellers,
      onSuccess: (res: ProjectSeller[]) => {
        setSellers(res);
        setLoading(false);
      },
    });

  useEffect(() => {
    if (selectedProject) {
      listProjectSellers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProject]);

  return (
    <ProjectsSellersContext.Provider
      value={{
        sellers,
        setSellers,
        mutate,
      }}
    >
      {loading ? <LoadingPage /> : children}
    </ProjectsSellersContext.Provider>
  );
};

export const useProjectsSellersContext = (): ProjectsSellersProviderProps => {
  const context = useContext(ProjectsSellersContext);

  if (!context) {
    throw new Error('Failed to load projects context. Make sure you are consuming the context within a provider block');
  }

  return context;
};
