import { useCallback } from 'react';
import {
  ProjectShippingCarrierAuthenticationJson,
  ProjectShippingCarrierGenericFieldJson,
  ProjectShippingCarrierRequest,
} from '../types';
import { useApiActions } from './api-actions';

export type ListProjectShippingCarriersResponse = {
  projectShippingCarriers: ProjectShippingCarrierRequest[];
};

type CreateProjectShippingCarrierResponse = {
  id: string;
};

type UpdateProjectShippingCarrierResponse = {
  id: string;
};

export const useProjectShippingCarrierActions = () => {
  const { executeApiAction } = useApiActions();

  const listProjectShippingCarriers = (projectId: string) =>
    executeApiAction<ProjectShippingCarrierRequest[]>({
      action: async ({ client }) =>
        (await client.get(`projects/${projectId}/shipping-carriers`).json<ListProjectShippingCarriersResponse>())
          .projectShippingCarriers,
      errorMessage: 'Failed to load project shipping carriers',
    });

  const createProjectShippingCarrier = async (
    projectId: string,
    {
      shippingCarrierId,
      authenticationFields,
      genericFields,
    }: {
      shippingCarrierId: string;
      authenticationFields: ProjectShippingCarrierAuthenticationJson;
      genericFields: ProjectShippingCarrierGenericFieldJson;
    }
  ) =>
    await executeApiAction({
      action: async ({ client }) =>
        (
          await client
            .post(`projects/${projectId}/shipping-carriers`, {
              json: { shippingCarrierId, authenticationFields, genericFields },
            })
            .json<CreateProjectShippingCarrierResponse>()
        ).id,
      onSuccess: (id: string) => {
        return id;
      },
      errorMessage: 'Failed to create shipping carrier',
      successMessage: 'Project shipping carrier created',
    });

  const updateProjectShippingCarrier = async (
    projectId: string,
    projectShippingCarrierId: string,
    {
      authenticationFields,
      genericFields,
    }: {
      authenticationFields: ProjectShippingCarrierAuthenticationJson;
      genericFields: ProjectShippingCarrierGenericFieldJson;
    }
  ) =>
    await executeApiAction({
      action: ({ client }) =>
        client
          .put(`projects/${projectId}/shipping-carriers/${projectShippingCarrierId}`, {
            json: { authenticationFields, genericFields },
          })
          .json<UpdateProjectShippingCarrierResponse>(),

      errorMessage: 'Failed to update project',
      successMessage: 'Project saved',
    });

  const deleteProjectShippingCarrier = async (id: string) =>
    await executeApiAction({
      action: ({ client }) => client.delete(`projects/${id}`),
      errorMessage: 'Failed to delete project',
      successMessage: 'Project deleted',
    });

  return {
    listProjectShippingCarriers: useCallback(listProjectShippingCarriers, [executeApiAction]),
    createProjectShippingCarrier: useCallback(createProjectShippingCarrier, [executeApiAction]),
    updateProjectShippingCarrier: useCallback(updateProjectShippingCarrier, [executeApiAction]),
    deleteProjectShippingCarrier: useCallback(deleteProjectShippingCarrier, [executeApiAction]),
  };
};
