import React, { useEffect, useState } from 'react';
import {
  Heading,
  Button,
  Text,
  Alert,
  AlertTitle,
  AlertDescription,
  AlertIcon,
  VStack,
  useColorModeValue,
  Card,
  Flex,
  List,
  ListItem,
  ListIcon,
  Box,
  useToast,
} from '@chakra-ui/react';
import { Select } from '@chakra-ui/react';
import { AuthenticatedLayout } from '../layouts';
import { useSearchParams } from 'react-router-dom';
import { useSubscriptionActions } from '../hooks';
import { MdCheckCircle, MdRocketLaunch, MdRemoveCircle, MdAddCircle, MdWarningAmber } from 'react-icons/md';
import { Plan, User } from '../types';
import { useAuthContext } from '../providers';
import { useTranslation } from 'react-i18next';

export interface PricingCardData {
  features: string[];
  name: string;
  price: string;
}

type PriceTier = {
  minOrders: number;
  maxOrders: number;
  discountRate: number;
};

enum FeatureType {
  ADVANTAGE = 'advantage',
  WARNING = 'warning',
  DISADVANTAGE = 'disadvantage',
}

export const priceTiers: PriceTier[] = [
  { minOrders: 1, maxOrders: 300, discountRate: 0.0 },
  { minOrders: 301, maxOrders: 500, discountRate: 0.05 },
  { minOrders: 501, maxOrders: 1000, discountRate: 0.1 },
  { minOrders: 1001, maxOrders: 2000, discountRate: 0.15 },
  { minOrders: 2001, maxOrders: 3000, discountRate: 0.3 },
  { minOrders: 3001, maxOrders: 4000, discountRate: 0.45 },
  { minOrders: 4001, maxOrders: 5000, discountRate: 0.5 },
  { minOrders: 5001, maxOrders: 10000, discountRate: 0.55 },
  { minOrders: 10001, maxOrders: 15000, discountRate: 0.58 },
  { minOrders: 15001, maxOrders: 20000, discountRate: 0.59 },
  { minOrders: 20001, maxOrders: Infinity, discountRate: 0.6 },
];
export const PricingCard = ({
  plan,
  onActivate,
  user,
  active,
  orders,
}: {
  plan: Plan;
  onActivate: (id: string) => Promise<void>;
  user: User;
  active: boolean;
  orders: number;
}) => {
  const { t } = useTranslation('onboarding');

  const commonFeatures = [
    { text: t('onboarding:plan.notify_email_feature_text'), type: FeatureType.ADVANTAGE },
    { text: t('onboarding:plan.infinite_users_feature_text'), type: FeatureType.ADVANTAGE },
    { text: t('onboarding:plan.creating_shipments_feature_text'), type: FeatureType.ADVANTAGE },
    { text: t('onboarding:plan.sending_data_to_carriers_feature_text'), type: FeatureType.ADVANTAGE },
    { text: t('onboarding:plan.generating_labels_feature_text'), type: FeatureType.ADVANTAGE },
    { text: t('onboarding:plan.tracking_feature_text'), type: FeatureType.ADVANTAGE },
    { text: t('onboarding:plan.multiple_packages_in_one_shipment_feature_text'), type: FeatureType.ADVANTAGE },
  ];

  const plans = {
    BASIC: {
      title: t('onboarding:plan.basic.title'), //'Basic',
      description: t('onboarding:plan.basic.description'), //'A plan that fits your customers basic needs',
      price: 1.09,
      minOrders: 300,
      maxOrders: 1000,
      features: [
        ...commonFeatures,
        { text: t('onboarding:plan.api_access_feature_text'), type: FeatureType.DISADVANTAGE },
        { text: t('onboarding:plan.tracking_page_localizations'), type: FeatureType.DISADVANTAGE },
        { text: t('onboarding:plan.project_feature_text', { count: 1 }), type: FeatureType.WARNING },
        { text: t('onboarding:plan.shipping_company_feature_text', { count: 1 }), type: FeatureType.WARNING },
        { text: t('onboarding:plan.seller_feature_text', { count: 1 }), type: FeatureType.WARNING },
      ],
    },
    PLUS: {
      title: t('onboarding:plan.plus.title'), //'Plus',
      description: t('onboarding:plan.plus.description'), //'A plan that fits most of your customers needs',
      price: 1.49,
      minOrders: 300,
      maxOrders: 20000,
      features: [
        ...commonFeatures,
        { text: t('onboarding:plan.api_access_feature_text'), type: FeatureType.ADVANTAGE },
        { text: t('onboarding:plan.tracking_page_localizations'), type: FeatureType.ADVANTAGE },
        { text: t('onboarding:plan.project_feature_text', { count: 1 }), type: FeatureType.WARNING },
        { text: t('onboarding:plan.shipping_company_feature_text', { count: 1 }), type: FeatureType.WARNING },
        { text: t('onboarding:plan.seller_feature_text', { count: 1 }), type: FeatureType.WARNING },
      ],
    },
    PREMIUM: {
      title: t('onboarding:plan.premium.title'), //'Premium',
      description: t('onboarding:plan.premium.description'), //'A plan that fits all your customers needs',
      price: 1.99,
      minOrders: 2000,
      maxOrders: Infinity,
      features: [
        ...commonFeatures,
        { text: t('onboarding:plan.api_access_feature_text'), type: FeatureType.ADVANTAGE },
        { text: t('onboarding:plan.tracking_page_localizations'), type: FeatureType.ADVANTAGE },
        { text: t('onboarding:plan.infinite_project_feature_text'), type: FeatureType.ADVANTAGE },
        { text: t('onboarding:plan.infinite_shipping_company_feature_text'), type: FeatureType.ADVANTAGE },
        { text: t('onboarding:plan.infinite_seller_feature_text'), type: FeatureType.ADVANTAGE },
      ],
    },
  };

  // calculate price based on the number of orders and the price per order
  const calculatePrice = (orders: number, baseRate: number) => {
    console.log('orders', orders, 'baseRate', baseRate);
    const tier = priceTiers.find((t) => orders >= t.minOrders && orders <= t.maxOrders);
    if (!tier) {
      console.error('Invalid number of orders.');
      return 0;
    }
    const pricePerOrder = baseRate - baseRate * tier.discountRate;
    return Math.round(orders * pricePerOrder).toLocaleString();
  };

  const background = useColorModeValue('white', 'gray.900');
  if (!(plan.id in plans)) return null;
  const { features, title, description, price, minOrders } = plans[plan.id as keyof typeof plans];

  return (
    <Card
      rounded={{ sm: 'xl' }}
      background={background}
      display="flex"
      p="10"
      overflow="hidden"
      mb={8}
      w="380px"
      border={active ? '2px solid #3182ce' : '1px solid #e2e8f0'}
      shadow={active ? 'lg' : 'sm'}
    >
      <VStack spacing={6}>
        <Heading size="lg">{title}</Heading>
        <Text fontWeight="500" mb={2} fontSize="ml" color="grey" lineHeight={'1.2em'}>
          {description}
        </Text>
      </VStack>
      <Flex direction="column" align="center" justify="center" fontWeight="extrabold" my="8">
        <Text fontWeight="500" mb={2} fontSize="ml" color="grey" lineHeight={'1.2em'} ml={1}>
          {t('onboarding:plan.price_monthly') /** monthly */}
        </Text>
        <Heading size="3xl" fontWeight="inherit" lineHeight="0.9em">
          {calculatePrice(orders <= minOrders ? minOrders : orders, price)} Kč
        </Heading>
        <Text fontWeight="500" mb={2} mt={2} fontSize="ml" color="grey" lineHeight={'1.2em'} ml={1}>
          {t('onboarding:plan.price_upto', {
            orders: orders < minOrders ? minOrders.toLocaleString() : orders.toLocaleString(),
          })}
        </Text>
      </Flex>
      <List spacing="1" mb="8" maxW="28ch" textAlign="left">
        {features.map((feature, index) =>
          feature.type === FeatureType.ADVANTAGE ? (
            <ListItem key={index}>
              <ListIcon color="green.500" fontSize={'xl'} as={MdAddCircle} />
              {feature.text}
            </ListItem>
          ) : feature.type === FeatureType.WARNING ? (
            <ListItem key={index}>
              <ListIcon color="orange.500" fontSize={'xl'} as={MdWarningAmber} />
              {feature.text}
            </ListItem>
          ) : (
            <ListItem key={index}>
              <ListIcon color="red.500" as={MdRemoveCircle} />
              {feature.text}
            </ListItem>
          )
        )}
      </List>
      {active ? (
        <Button size="lg" w="full" fontWeight="extrabold" py={{ md: '8' }} variant="ghost" leftIcon={<MdCheckCircle />}>
          {t('onboarding:plan.active') /** Active */}
        </Button>
      ) : (
        <Button
          size="lg"
          w="full"
          fontWeight="extrabold"
          py={{ md: '8' }}
          leftIcon={<MdRocketLaunch />}
          onClick={() => onActivate(plan.id)}
        >
          {user.freeTrialActivated ? `${t('onboarding:plan.request')}` : `${t('onboarding:start_trial')}`}
        </Button>
      )}
    </Card>
  );
};

export const SubscribePage: React.FC = () => {
  const { t } = useTranslation('onboarding');

  const [searchParams] = useSearchParams();
  const { user } = useAuthContext();
  const { listPlans } = useSubscriptionActions();
  const [plans, setPlans] = useState<Plan[]>([]);
  const [orders, setOrders] = useState<number>(100);
  const toast = useToast();

  useEffect(() => {
    const getPlans = async () => {
      const data = await listPlans();
      setPlans(data || []);
    };
    getPlans();
  }, [listPlans]);

  const onActivate = async (id: string) => {
    toast({
      title: t('onboarding:currently_unavailable'),
      status: 'info',
      duration: 5000,
      isClosable: true,
    });
  };
  const background = useColorModeValue('white', 'gray.900');

  return (
    <>
      {searchParams.get('status') === 'warning' && (
        <Alert status="warning">
          <AlertIcon />
          <AlertTitle>Payment failed</AlertTitle>
          <AlertDescription>Something went wrong while processing your payment. Please try again.</AlertDescription>
        </Alert>
      )}
      <AuthenticatedLayout sidebar={false}>
        <VStack spacing={4} w="100%" textAlign="center">
          <Box>
            <Heading>
              {!user?.freeTrialActivated
                ? `${t('onboarding:welcome_to_parcelsync')}`
                : `${t('onboarding:welcome_back')}`}
            </Heading>
            <Text>{t('onboarding:choose_a_plan')}</Text>
          </Box>
          <Text as="b">{t('onboarding:start_with_a_free_trial')}</Text>
          <Flex mt={4} mb={4} maxW="400px" justifyContent="center" direction="row">
            <Text as="b">{t('onboarding:how_many_orders_do_you_ship_per_month')}</Text>
            <Select
              background={background}
              defaultValue={orders}
              w="50%"
              onChange={(e) => setOrders(parseInt(e.target.value))}
              value={orders}
            >
              {priceTiers.map((tier, index) => (
                <option key={index} value={tier.maxOrders}>
                  {tier.maxOrders === Infinity ? `${tier.minOrders}+` : tier.maxOrders}
                </option>
              ))}
            </Select>
          </Flex>
          <Flex justifyContent={'center'} columnGap={6} flexDir={{ sm: 'column', lg: 'row' }}>
            {user &&
              plans.map((plan) => (
                <PricingCard
                  orders={orders}
                  active={user?.planId === plan.id}
                  key={plan.id}
                  plan={plan}
                  onActivate={onActivate}
                  user={user}
                />
              ))}
          </Flex>
          <Text fontWeight="500" color="grey" size="xs">
            {t('onboarding:no_tax')}
          </Text>
        </VStack>
      </AuthenticatedLayout>
    </>
  );
};
