import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Heading, Box, SimpleGrid, Text } from '@chakra-ui/react';
import { SoftCard } from '../components/generic/SoftCard';

import { MdCode } from 'react-icons/md';

import { AuthenticatedLayout } from '../layouts';
import { DangerButton } from '../components/generic/DangerButton';

import { useProjectActions } from '../hooks/project-actions';
import { PageHeading } from '../components/generic/PageHeading';
import { useProjectsContext } from '../providers';
import CodeTextField from '../components/generic/CodeTextField';
import { Project } from '../types';
import { usePlanLimitations } from '../hooks/plans';
import { useTranslation } from 'react-i18next';

export const ProjectIntegrationsSettingsPage: React.FC = () => {
  const { t } = useTranslation(['project']);
  const { selectedProject } = useProjectsContext();
  const [project, setProject] = useState<Project | null>(selectedProject);
  const planLimitations = usePlanLimitations();
  const { regenerateApiKey } = useProjectActions();
  console.log('planLimitations', planLimitations);
  const { id } = useParams();
  if (!id) return null;

  const onRegenerateApiKey = async () => {
    const apiKey = await regenerateApiKey(id);
    if (apiKey) {
      setProject({ ...(project as Project), apiKey });
    }
  };

  return (
    <AuthenticatedLayout>
      <PageHeading title={t('project:integrations.label')} icon={MdCode} />
      <Text variant="text">{t('project:integrations.description')}</Text>
      {project ? (
        <SimpleGrid pt={8} minChildWidth="400px" width="100%" spacingX="40px" spacingY="20px">
          <Box>
            <Heading as="h2" size="md" mb={4}>
              {t('project:integrations.api.label')}
            </Heading>

            <SoftCard>
              {planLimitations.API_KEYS === true ? (
                <Box>
                  <Heading as="h3" size="sm" mb={2}>
                    {t('project:integrations.api.label')}
                  </Heading>
                  <Text variant="text">
                    {t('project:integrations.api.usage')} <code>key:&lt;API Key&gt;</code> header.
                  </Text>
                  <CodeTextField text={project.apiKey} />
                  <DangerButton
                    onClick={onRegenerateApiKey}
                    title={t('project:integrations.api.regenerate')}
                    // title={'Regenerate'}
                    mt={4}
                    colorScheme="blue"
                    body={'Are you sure you want to regenerate the API key? This will invalidate the old key.'}
                  />
                </Box>
              ) : (
                <Text variant="text">
                  {
                    t(
                      'project:integrations.api.not_avalailable_for_plan'
                    ) /* API Key is not available for your current plan */
                  }
                </Text>
              )}
            </SoftCard>
          </Box>
        </SimpleGrid>
      ) : (
        <Text variant="text">Project not found</Text>
      )}
    </AuthenticatedLayout>
  );
};
