import _ from 'lodash';
import { Input, FormControl, FormLabel, Button, InputGroup, InputRightAddon } from '@chakra-ui/react';
import { useFormContext, WatchObserver } from 'react-hook-form';
import { FormCard } from './FormCard';
import { FormNestedCardModal } from './FormNestedCardModal';
import { usePrivileges } from '../../../../hooks/roles';
import { FormParcelValues, FormShipmentValues } from '../../../../pages/project-shipment-detail-edit';
import { useTranslation } from 'react-i18next';

export const ParcelsSection = () => {
  const { t } = useTranslation(['shipment', 'common']);
  const privileges = usePrivileges();
  const {
    setValue,
    watch,
    getValues,
    register,
    unregister,
    formState: { errors },
  } = useFormContext<FormShipmentValues>();

  return (
    <FormCard heading={t('shipment:parcels.label')}>
      {watch('parcels')?.map((parcel: FormParcelValues, index: number) => (
        <FormNestedCardModal
          heading={`${watch(`parcels.${index}.referenceNumber`)}`}
          key={index}
          name={`parcels.${index}` as unknown as WatchObserver<FormShipmentValues>}
          errors={errors?.parcels?.[index]}
          onRemove={async () => {
            // Unregister the fields associated with the removed item
            unregister(`parcels.${index}`);
            // Get the current list of parcels
            const currentParcels = getValues('parcels');
            // Create a new array without the item to be removed
            const updatedParcels = currentParcels?.filter((_, i) => i !== index);
            // Update the form value with the new array
            setValue('parcels', updatedParcels);
          }}
        >
          <FormControl mb={2} isInvalid={errors?.parcels ? !!errors.parcels[index]?.referenceNumber : false}>
            <FormLabel>{t('shipment:parcels.reference.label') /* Reference */}</FormLabel>
            <Input
              disabled={!privileges.PROJECT_SHIPMENTS.UPDATE}
              {...register(`parcels.${index}.referenceNumber`, { required: true })}
              placeholder={t('shipment:parcels.reference.label') /* Reference */}
            />
          </FormControl>
          <FormControl mb={2} isInvalid={errors?.parcels ? !!errors.parcels[index]?.weight : false}>
            <FormLabel>{t('shipment:parcels.weight.label') /* Weight */}</FormLabel>
            <InputGroup>
              <Input
                disabled={!privileges.PROJECT_SHIPMENTS.UPDATE}
                {...register(`parcels.${index}.weight`, { required: true, valueAsNumber: true })}
                placeholder={t('shipment:parcels.weight.label') /* Weight */}
              />
              <InputRightAddon children="g" />
            </InputGroup>
          </FormControl>
          <FormControl mb={2} isInvalid={errors?.parcels ? !!errors.parcels[index]?.height : false}>
            {' '}
            <FormLabel>{t('shipment:parcels.height.label') /* Height */}</FormLabel>
            <InputGroup>
              <Input
                disabled={!privileges.PROJECT_SHIPMENTS.UPDATE}
                {...register(`parcels.${index}.height`, { required: true, valueAsNumber: true })}
                placeholder={t('shipment:parcels.height.label') /* Height */}
              />
              <InputRightAddon children="cm" />
            </InputGroup>
          </FormControl>
          <FormControl mb={2} isInvalid={errors?.parcels ? !!errors.parcels[index]?.width : false}>
            {' '}
            <FormLabel>{t('shipment:parcels.width.label') /* Width */}</FormLabel>
            <InputGroup>
              <Input
                disabled={!privileges.PROJECT_SHIPMENTS.UPDATE}
                {...register(`parcels.${index}.width`, { required: true, valueAsNumber: true })}
                placeholder={t('shipment:parcels.width.label')}
              />
              <InputRightAddon children="cm" />
            </InputGroup>
          </FormControl>
          <FormControl mb={2} isInvalid={errors?.parcels ? !!errors.parcels[index]?.length : false}>
            <FormLabel>{t('shipment:parcels.length.label')}</FormLabel>
            <InputGroup>
              <Input
                disabled={!privileges.PROJECT_SHIPMENTS.UPDATE}
                {...register(`parcels.${index}.length`, { required: true, valueAsNumber: true })}
                placeholder={t('shipment:parcels.length.label')}
              />
              <InputRightAddon children="cm" />
            </InputGroup>
          </FormControl>
        </FormNestedCardModal>
      ))}
      <Button
        onClick={() => {
          const currentParcels = getValues('parcels');
          const emptyParcel = {} as FormParcelValues;
          if (_.isEmpty(currentParcels)) {
            setValue('parcels', [emptyParcel]);
            return;
          }
          const updatedParcels = [...currentParcels, emptyParcel];
          setValue('parcels', updatedParcels);
        }}
      >
        {t('shipment:parcels.add-parcel.label') /* Add parcel */}
      </Button>
    </FormCard>
  );
};
