import { Tabs, TabList, TabPanels, Tab, TabPanel, Table, Tbody, Tr, Td, TableContainer } from '@chakra-ui/react';
import { Customs } from '../../../types/shipment';
import { useTranslation } from 'react-i18next';

type CustomsSectionProps = {
  data: Customs;
};

export const CustomsSection: React.FC<CustomsSectionProps> = ({ data }) => {
  /** Tabs view of parcels with basic data and tracking */
  const { t } = useTranslation(['shipment', 'common']);
  const { description, totalValue, currency, commodities } = data;

  return (
    <>
      <TableContainer mb={4}>
        <Table size="sm">
          <Tbody>
            <Tr>
              <Td width="10%">{t('shipment:customs.description.label') /* Description */}</Td>
              <Td>{description}</Td>
            </Tr>
            <Tr>
              <Td width="10%">{t('shipment:customs.value.label') /* Value */}</Td>
              <Td>
                {totalValue} {currency}
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
      <Tabs variant="soft-rounded" colorScheme="blue">
        <TabList>
          {commodities.map((commodity, index) => (
            <Tab key={`${index}_tab`}>{commodity.title}</Tab>
          ))}
        </TabList>
        <TabPanels>
          {commodities.map((commodity, index) => (
            <TabPanel key={`${index}_panel`}>
              <TableContainer>
                <Table size="sm">
                  <Tbody>
                    <Tr>
                      <Td width="10%">{t('shipment:customs.commodities.sku.label') /* SKU */}</Td>
                      <Td>{commodity.sku}</Td>
                    </Tr>
                    <Tr>
                      <Td width="10%">{t('shipment:customs.commodities.title.label') /* Title */}</Td>
                      <Td>{commodity.title}</Td>
                    </Tr>
                    <Tr>
                      <Td width="10%">{t('shipment:customs.commodities.hs-code.label') /* HS code */}</Td>
                      <Td>{commodity.hsCode}</Td>
                    </Tr>
                    <Tr>
                      <Td width="10%">{t('shipment:customs.commodities.quantity.label') /* Quantity */}</Td>
                      <Td>{commodity.quantity}</Td>
                    </Tr>
                    <Tr>
                      <Td width="10%">{t('shipment:customs.commodities.weight.label') /* Weight */}</Td>
                      <Td>{commodity.weight}</Td>
                    </Tr>
                    <Tr>
                      <Td width="10%">{t('shipment:customs.commodities.value.label') /* Value */}</Td>
                      <Td>
                        {commodity.value} {currency}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td width="10%">
                        {t('shipment:customs.commodities.origin-country.label') /* Country of origin */}
                      </Td>
                      <Td>{commodity.originCountry}</Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </>
  );
};
