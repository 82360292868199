import { Box, Image } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

const AdBanner = ({ src, link }: { src?: string; link?: string }) => {
  if (!src) return null;
  const content = <Image height="auto" width="100%" maxWidth="550px" ml="auto" mr="auto" src={src} />;
  return (
    <Box display="flex" justifyContent="center" alignItems="center" width="80%" ml="auto" mr="auto" pt={4}>
      {link ? (
        <Link to={link} rel="noopener noreferrer" target="_blank">
          {content}
        </Link>
      ) : (
        content
      )}
    </Box>
  );
};

export default AdBanner;
