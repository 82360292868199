import { Button, Checkbox, Flex, Input, List, ListItem, Stack, Th, useDisclosure } from '@chakra-ui/react';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  Text,
  Select,
} from '@chakra-ui/react';
import { IoMdRemoveCircleOutline, IoMdAddCircleOutline } from 'react-icons/io';
import { MdOutlineFilterAlt, MdFilterListAlt } from 'react-icons/md';

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import RangeCalendar from '../../generic/RangeCalendar';
import dayjs from 'dayjs';

export enum FilterOpearator {
  IN = 'IN',
  CONTAINS = 'CONTAINS',
  STARTS_WITH = 'STARTS_WITH',
  ENDS_WITH = 'ENDS_WITH',
  EQUALS = 'EQUALS',
  BETWEEN = 'BETWEEN',
}

type TableHeaderFilterProps = {
  title: string;
  set: (value: string | string[] | number | boolean | Date | Date[] | undefined, operator?: FilterOpearator) => void;
  value: string | string[] | number | boolean | Date | Date[] | undefined;
  type?: 'text' | 'select' | 'number' | 'date';
  options?: { key: string; label: string }[];
  filterOperator?: FilterOpearator;
};

const ThFilter = ({ title, type, options, set, value, filterOperator }: TableHeaderFilterProps) => {
  if (type === 'select') return <ThFilterSelect filterSet={set} filterValue={value} title={title} options={options} />;
  if (type === 'date') return <ThFilterDateRange filterSet={set} filterValue={value} title={title} />;
  return <ThFilterText filterSet={set} filterValue={value} title={title} filterOperator={filterOperator} />;
};

type ThFilterExactProps = {
  title: string;
  filterSet: (
    value: string | string[] | number | boolean | Date | Date[] | undefined,
    operator?: FilterOpearator
  ) => void;
  filterValue: string | string[] | number | boolean | Date | Date[] | undefined;
  filterOperator?: FilterOpearator;
  options?: { key: string; label: string }[];
  children?: React.ReactNode;
};

const ThFilterText = ({ title, filterSet, filterValue, filterOperator }: ThFilterExactProps) => {
  const { t } = useTranslation('common');
  const [value, setValue] = useState<string | undefined>(undefined);
  const [operation, setOperation] = useState<FilterOpearator>(filterOperator || FilterOpearator.EQUALS);
  const { isOpen, onToggle, onClose } = useDisclosure();
  const _filterOnClose = () => {
    if (value && value.length === 0) filterSet(undefined);
    else filterSet(value || undefined, operation);
    onClose();
  };

  useEffect(() => {
    setValue(filterValue as string);
  }, [filterValue]);

  return (
    <Th>
      <Popover returnFocusOnClose={false} isOpen={isOpen} onClose={_filterOnClose} placement="bottom">
        <PopoverTrigger>
          <Flex alignItems="center" onClick={onToggle}>
            <Text
              style={{
                cursor: 'pointer',
              }}
            >
              {title}
            </Text>
            {value ? <MdFilterListAlt /> : <MdOutlineFilterAlt />}
          </Flex>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader>{title}</PopoverHeader>
          <PopoverBody>
            <Flex alignItems="center">
              <Select
                size="sm"
                maxWidth="35%"
                value={operation}
                onChange={(e) => setOperation(e.target.value as FilterOpearator)}
              >
                <option value={FilterOpearator.EQUALS}>{t('common:equals.label')}</option>
                <option value={FilterOpearator.CONTAINS}>{t('common:contains.label')}</option>
                <option value={FilterOpearator.STARTS_WITH}>{t('common:starts_with.label')}</option>
                <option value={FilterOpearator.ENDS_WITH}>{t('common:ends_with.label')}</option>
              </Select>
              <Input
                size="sm"
                ml={2}
                value={value || ''}
                onChange={(e) => {
                  setValue(e.target.value);
                }}
              />
            </Flex>
          </PopoverBody>
          <PopoverFooter>
            {' '}
            <Stack spacing={4} direction="row" align="center">
              {/* {value && value === filterValue ? (
                <Button
                  size="sm"
                  variant="ghost"
                  onClick={() => {
                    setValue(undefined);
                  }}
                  leftIcon={<IoMdRemoveCircleOutline />}
                >
                  Clear
                </Button>
              ) : value && value !== filterValue ? ( */}
              <>
                <Button
                  size="sm"
                  variant="outline"
                  onClick={() => _filterOnClose()}
                  leftIcon={<IoMdAddCircleOutline />}
                >
                  Filter
                </Button>
                <Button
                  size="sm"
                  variant="ghost"
                  onClick={() => {
                    setValue(undefined);
                  }}
                  leftIcon={<IoMdRemoveCircleOutline />}
                >
                  Clear
                </Button>
              </>
              {/* ) : (
                <Button
                  size="sm"
                  variant="outline"
                  onClick={() => _filterOnClose()}
                  leftIcon={<IoMdAddCircleOutline />}
                >
                  Filter
                </Button>
              )} */}
            </Stack>
          </PopoverFooter>{' '}
        </PopoverContent>
      </Popover>
    </Th>
  );
};

const ThFilterSelect = ({ title, options, filterSet, filterValue }: ThFilterExactProps) => {
  const { t } = useTranslation('common');

  const [value, setValue] = useState<string[] | undefined>(undefined);
  const { isOpen, onToggle, onClose } = useDisclosure();

  const _filterOnClose = () => {
    if (value && value.length === 0) filterSet(undefined);
    else filterSet(value || undefined, FilterOpearator.IN);
    onClose();
  };

  useEffect(() => {
    setValue(filterValue as string[]);
  }, [filterValue]);

  console.log('value', value);

  return (
    <Th>
      <Popover returnFocusOnClose={false} isOpen={isOpen} onClose={_filterOnClose} placement="bottom">
        <PopoverTrigger>
          <Flex alignItems="center" onClick={onToggle}>
            <Text
              style={{
                cursor: 'pointer',
              }}
            >
              {title}
            </Text>
            <span
              style={{
                width: '10px',
              }}
            >
              {value && value.length > 0 ? `(${value.length})` : <MdOutlineFilterAlt />}
            </span>
          </Flex>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader>{title}</PopoverHeader>
          <PopoverBody
            style={{
              maxHeight: '300px',
              overflowY: 'auto',
            }}
          >
            <List spacing={3}>
              {options?.map((option) => (
                <ListItem key={option.key}>
                  <Checkbox
                    value={option.key}
                    isChecked={!!value?.includes(option.key)}
                    onChange={(e) => {
                      if (e.target.checked) setValue([...(value || []), option.key]);
                      else setValue(value?.filter((v) => v !== option.key));
                    }}
                  >
                    <Text key={option.key} fontWeight={600} fontSize={'sm'}>
                      {option.label}
                    </Text>
                  </Checkbox>
                </ListItem>
              ))}
            </List>
          </PopoverBody>
          <PopoverFooter>
            <Stack spacing={4} direction="row" align="center">
              {value && value === filterValue ? (
                <Button
                  size="sm"
                  variant="ghost"
                  onClick={() => {
                    setValue(undefined);
                  }}
                  leftIcon={<IoMdRemoveCircleOutline />}
                >
                  {t('common:clear-filter.label')}
                </Button>
              ) : value && value !== filterValue ? (
                <>
                  <Button
                    size="sm"
                    variant="outline"
                    onClick={() => _filterOnClose()}
                    leftIcon={<IoMdAddCircleOutline />}
                  >
                    {t('common:filter.label')}
                  </Button>
                  <Button
                    size="sm"
                    variant="ghost"
                    onClick={() => {
                      setValue(undefined);
                    }}
                    leftIcon={<IoMdRemoveCircleOutline />}
                  >
                    {t('common:clear-filter.label')}
                  </Button>
                </>
              ) : (
                <Button
                  size="sm"
                  variant="outline"
                  onClick={() => _filterOnClose()}
                  leftIcon={<IoMdAddCircleOutline />}
                >
                  {t('common:filter.label')}
                </Button>
              )}
            </Stack>
          </PopoverFooter>
        </PopoverContent>
      </Popover>
    </Th>
  );
};

const ThFilterDateRange = ({ title, filterSet, filterValue }: ThFilterExactProps) => {
  const { t } = useTranslation('common');

  const [value, setValue] = useState<Date[] | undefined>(undefined);
  const { isOpen, onToggle, onClose } = useDisclosure();

  const _filterOnClose = () => {
    if (value && value.length === 0) filterSet(undefined);
    else if (value && value.length === 2) {
      const start = dayjs(value[0]).format('YYYY-MM-DD');
      // add 1 day to the end date to include the whole day
      const end = dayjs(value[1]).add(1, 'day').format('YYYY-MM-DD');
      filterSet([start, end], FilterOpearator.BETWEEN);
    } else {
      filterSet(value || undefined, FilterOpearator.BETWEEN);
    }
    onClose();
  };

  useEffect(() => {
    setValue(filterValue as Date[]);
  }, [filterValue]);

  return (
    <Th>
      <Popover
        isLazy
        returnFocusOnClose={false}
        isOpen={isOpen}
        onClose={_filterOnClose}
        placement="bottom-start"
        variant="responsive"
      >
        <PopoverTrigger>
          <Flex alignItems="center" onClick={onToggle}>
            <Text
              style={{
                cursor: 'pointer',
              }}
            >
              {title}
            </Text>
            <span
              style={{
                width: '10px',
              }}
            >
              {value ? <MdFilterListAlt /> : <MdOutlineFilterAlt />}
            </span>
          </Flex>
        </PopoverTrigger>
        <PopoverContent width="100%">
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader>{title}</PopoverHeader>
          <PopoverBody>
            <RangeCalendar
              selectedDates={value || []}
              setSelectedDates={(dates: Date[]) => {
                setValue(dates);
              }}
            />
          </PopoverBody>
          <PopoverFooter>
            <Stack spacing={4} direction="row" align="center">
              {value && value === filterValue ? (
                <Button
                  size="sm"
                  variant="ghost"
                  onClick={() => {
                    setValue(undefined);
                  }}
                  leftIcon={<IoMdRemoveCircleOutline />}
                >
                  {t('common:clear-filter.label')}
                </Button>
              ) : value && value !== filterValue ? (
                <>
                  <Button
                    size="sm"
                    variant="outline"
                    onClick={() => _filterOnClose()}
                    leftIcon={<IoMdAddCircleOutline />}
                  >
                    {t('common:filter.label')}
                  </Button>
                  <Button
                    size="sm"
                    variant="ghost"
                    onClick={() => {
                      setValue(undefined);
                    }}
                    leftIcon={<IoMdRemoveCircleOutline />}
                  >
                    {t('common:clear-filter.label')}
                  </Button>
                </>
              ) : (
                <Button
                  size="sm"
                  variant="outline"
                  onClick={() => _filterOnClose()}
                  leftIcon={<IoMdAddCircleOutline />}
                >
                  {t('common:filter.label')}
                </Button>
              )}
            </Stack>
          </PopoverFooter>
        </PopoverContent>
      </Popover>
    </Th>
  );
};

export default ThFilter;
