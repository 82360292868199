import Cookies from 'js-cookie';
import { useApiActions } from './api-actions';
import { useMetadataActions } from './use-metadata-actions';

export type LoginResponse = {
  accessToken: string;
  refreshToken: string;
};

export type ForgotPasswordResponse = {
  sent: boolean;
};

export type ForgotPasswordSubmitResponse = {
  reset: boolean;
};

export type SignupResponse = {
  verified: boolean;
};
export const ACCESS_TOKEN_COOKIE_NAME = 'accessToken';
export const REFRESH_TOKEN_COOKIE_NAME = 'refreshToken';

export const createDateFromSeconds = (seconds: number) => {
  /** Purpose of this function is to generate date which is exactly now + seconds */
  const date = new Date();
  date.setSeconds(date.getSeconds() + seconds);
  return date;
};

export const useAuthActions = () => {
  const { executeApiAction, getNewAccessToken } = useApiActions();
  const { config } = useMetadataActions();

  const signIn = ({ email, password }: { email?: string; password?: string }) => {
    return executeApiAction<LoginResponse>({
      action: async ({ publicClient }) => {
        const { accessToken, refreshToken } = await publicClient
          .post('authentication/signin', { json: { email, password } })
          .json<LoginResponse>();
        Cookies.set(ACCESS_TOKEN_COOKIE_NAME, accessToken, {
          expires: createDateFromSeconds(config.authentication.jwt.accessTokenLifespan),
        });
        Cookies.set(REFRESH_TOKEN_COOKIE_NAME, refreshToken, {
          expires: createDateFromSeconds(config.authentication.jwt.refreshTokenLifespan),
        });
        return { accessToken, refreshToken };
      },
      onError: (error: any) => {
        throw error;
      },
      // errorMessage: errorMessage ? errorMessage : 'Failed to sign in',
    });
  };

  const removeTokens = () => {
    Cookies.remove(ACCESS_TOKEN_COOKIE_NAME);
    Cookies.remove(REFRESH_TOKEN_COOKIE_NAME);
  };

  const signUp = ({
    email,
    password,
    firstName,
    lastName,
  }: {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
  }) =>
    executeApiAction<SignupResponse>({
      action: async ({ publicClient }) => {
        const { verified } = await publicClient
          .post('authentication/signup', { json: { email, password, firstName, lastName } })
          .json<SignupResponse>();
        return { verified };
      },
      errorMessage: 'Failed to sign in',
    });
  const verificationSignUp = ({ email, code }: { email: string; code: string }) =>
    executeApiAction<SignupResponse>({
      action: async ({ publicClient }) => {
        const { verified } = await publicClient
          .post('authentication/signup/verification', { json: { email, code } })
          .json<SignupResponse>();
        return { verified };
      },
      errorMessage: 'Failed to verify',
    });

  const resendVerificationSignUp = ({ email }: { email: string }) =>
    executeApiAction<SignupResponse>({
      action: async ({ publicClient }) => {
        const { verified } = await publicClient
          .post('authentication/signup/resend', { json: { email } })
          .json<SignupResponse>();
        return { verified };
      },
      errorMessage: 'Failed to resend',
    });

  const forgotPassword = (email: string) =>
    executeApiAction<ForgotPasswordResponse>({
      action: async ({ publicClient }) => {
        const { sent } = await publicClient
          .post('authentication/password/reset', { json: { email } })
          .json<ForgotPasswordResponse>();
        return { sent };
      },
      errorMessage: 'Failed to resend',
    });

  const forgotPasswordSubmit = (email: string, code: string, password: string) =>
    executeApiAction<ForgotPasswordSubmitResponse>({
      action: async ({ publicClient }) => {
        const { reset } = await publicClient
          .post('authentication/password/reset/submit', { json: { email, code, password } })
          .json<ForgotPasswordSubmitResponse>();
        return { reset };
      },
      errorMessage: 'Failed to resend',
    });

  const currentSession = async () => {
    /** This might be worth refactoring and refreshing token.. */
    const accessToken = await getAccessToken();
    if (!accessToken || accessToken === 'undefined' || accessToken === 'null') {
      throw new Error('No access token found');
    }
    return true;
  };

  const getAccessToken = async () => {
    /** Read current accessToken from cookies */
    let accessToken: string | undefined | null = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
    const refreshToken = Cookies.get(REFRESH_TOKEN_COOKIE_NAME);
    if (!accessToken && refreshToken) {
      accessToken = await getNewAccessToken(refreshToken);
      if (!accessToken) {
        throw new Error('accessToken not found');
      }
    }
    return accessToken;
  };

  return {
    signIn,
    signUp,
    verificationSignUp,
    resendVerificationSignUp,
    forgotPassword,
    forgotPasswordSubmit,
    removeTokens,
    getAccessToken,
    currentSession,
    getNewAccessToken,
  };
};
