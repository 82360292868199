import { Table, Tbody, Tr, Td, TableContainer, Heading, Text } from '@chakra-ui/react';
import { Parcel } from '../../../types/shipment';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

type ParcelTableProps = {
  parcel: Parcel;
};

export const ParcelTable: React.FC<ParcelTableProps> = ({ parcel }) => {
  /** Tabs view of parcels with basic data and tracking */
  const { t } = useTranslation(['shipment', 'common']);
  const { id, trackingNumber, referenceNumber, weight, length, width, height, statuses } = parcel;

  const formatMetadataForTable = (metadata?: { [key: string]: string | string[] } | undefined): string => {
    if (!metadata) {
      return '';
    }

    let formattedString: string[] = [];
    for (const [key, value] of Object.entries(metadata)) {
      formattedString.push(`${key}: ${JSON.stringify(value, null, 2)}`);
    }
    return formattedString.join(', ');
  };

  return (
    <>
      <Heading as="h3" size="md" mb={4}>
        {t('common:detail.label')}
      </Heading>

      <TableContainer mb={6}>
        <Table size="sm">
          <Tbody>
            <Tr>
              <Td width="10%">{t('shipment:parcels.id.label') /**Id */}</Td>
              <Td>{id}</Td>
            </Tr>
            <Tr>
              <Td width="10%">{t('shipment:parcels.tracking-number.label') /**Tracking number */}</Td>
              <Td>{trackingNumber}</Td>
            </Tr>
            <Tr>
              <Td width="10%">{t('shipment:parcels.reference.label') /**Reference */}</Td>
              <Td>{referenceNumber}</Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
      <Heading as="h3" size="md" mb={4}>
        {t('shipment:parcels.dimensions.label') /**Dimensions */}
      </Heading>
      <TableContainer mb={6}>
        <Table size="sm">
          <Tbody>
            <Tr>
              <Td width="10%">{t('shipment:parcels.weight.label') /* Weight */}</Td>
              <Td>{weight}</Td>
            </Tr>
            <Tr>
              <Td width="10%">{t('shipment:parcels.width.label') /* Width */}</Td>
              <Td>{width}</Td>
            </Tr>
            <Tr>
              <Td width="10%">{t('shipment:parcels.height.label') /* Height */}</Td>
              <Td>{height}</Td>
            </Tr>
            <Tr>
              <Td width="10%">{t('shipment:parcels.length.label')}</Td>
              <Td>{length}</Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
      <Heading as="h3" size="md" mb={4}>
        {t('shipment:parcels.tracking.label')}
      </Heading>
      <TableContainer mb={6}>
        <Table size="sm">
          <Tbody>
            {statuses ? (
              statuses?.map((status) => (
                <Tr>
                  <Td>{dayjs(status.createdAt).format('DD. MM. YYYY, HH:mm')}</Td>
                  <Td width="10%">{status.statusObj.title.en}</Td>
                  <Td width="10%">{formatMetadataForTable(status.metadata)}</Td>
                </Tr>
              ))
            ) : (
              <Text size="sd" mb={4}>
                {t('shipment:parcels.tracking.no-data') /* No tracking data available */}
              </Text>
            )}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
};
