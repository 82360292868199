import { useCallback } from 'react';
import { useToast } from '@chakra-ui/react';
import ky, { NormalizedOptions } from 'ky';

import { useLoadingContext } from '../providers';
import { KyInstance } from 'ky/distribution/types/ky';
import Cookies from 'js-cookie';
import { ACCESS_TOKEN_COOKIE_NAME, createDateFromSeconds } from './authentication-actions';

type ApiErrorInterface = {
  name: string;
  message: string;
  status: number;
};

export type RefreshTokenResponse = {
  accessToken: string;
};

type ExecuteApiCallAction<T> = ({
  client,
  publicClient,
}: {
  client: KyInstance;
  publicClient: KyInstance;
}) => Promise<T>;

export class ApiError extends Error implements ApiErrorInterface {
  public readonly name: string;
  public readonly status: number;

  constructor({ name, message, status }: { name: string; message: string; status: number }) {
    super(message);
    this.name = name;
    this.status = status;
  }
}

export const useApiActions = () => {
  const toast = useToast();
  const { setLoading } = useLoadingContext();
  const errorHook = useCallback(async (_request: Request, _options: NormalizedOptions, response: Response) => {
    if (!response.ok) {
      const body = await response.json();

      if (body && isCustomApiError(body)) {
        throw new ApiError({ name: body.name, message: body.message, status: response.status });
      }

      throw new ApiError({
        name: 'UNKNOWN_ERROR',
        message: 'An unknown error occurred, please try again later',
        status: 500,
      });
    }
  }, []);

  const getNewAccessToken = (refreshToken: string) => {
    /** Obtain new accessToken */
    return executeApiAction<string>({
      action: async ({ publicClient }) => {
        const { accessToken } = await publicClient
          .post('authentication/refresh', { json: { refreshToken } })
          .json<RefreshTokenResponse>();
        return accessToken;
      },
      errorMessage: 'Failed to refresh token',
    });
  };

  const executeApiCall = useCallback(
    async <T>({ action }: { action: ExecuteApiCallAction<T> }): Promise<T> => {
      const publicClient = ky.create({
        prefixUrl: process.env.REACT_APP_API_URL,
        credentials: 'include',
        mode: 'cors',
        timeout: 60000,
        hooks: {
          afterResponse: [errorHook],
        },
      });

      const refreshTokenHook = async ({ request }: { request: Request }) => {
        const refreshToken = Cookies.get('refreshToken' || null);
        if (!refreshToken) {
          throw new Error('refreshToken not found');
        }
        const accessToken = await getNewAccessToken(refreshToken);
        if (!accessToken) {
          throw new Error('accessToken not found');
        }
        Cookies.set(ACCESS_TOKEN_COOKIE_NAME, accessToken, {
          expires: createDateFromSeconds(3600),
        });
        request.headers.set('authorization', `Bearer ${accessToken}`);
      };

      const tokenHook = async (request: Request) => {
        let accessToken: string | null | undefined = Cookies.get('accessToken' || null);
        const refreshToken = Cookies.get('refreshToken' || null);
        if (!refreshToken) {
          throw new Error('refreshToken not found');
        }
        request.headers.set('authorization', `Bearer ${accessToken}`);
      };

      const client = publicClient.extend({
        hooks: {
          beforeRequest: [tokenHook],
          beforeRetry: [refreshTokenHook],
        },
        retry: {
          limit: 2,
          statusCodes: [401],
        },
      });

      try {
        return action({ client, publicClient });
      } catch (error: unknown) {
        if (error instanceof ApiError && error.status === 401) {
          // signOut();
        }
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errorHook]
  );

  const executeApiAction = async <T>({
    action,
    onError,
    onSuccess,
    errorMessage = null,
    successMessage = null,
    errorMessageDuration = 9000,
  }: {
    action: ExecuteApiCallAction<T>;
    onError?: Function;
    onSuccess?: Function;
    errorMessage?: string | null;
    successMessage?: string | null;
    errorMessageDuration?: number | null;
  }): Promise<T | null> => {
    setLoading(true);
    try {
      const result = await executeApiCall<T>({ action });
      if (successMessage) {
        toast({
          title: 'Success',
          description: successMessage,
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      }
      if (onSuccess) await onSuccess(result);
      setLoading(false);
      return result;
    } catch (error: any) {
      if (onError) await onError(error);
      if (error.message) {
        toast({
          title: 'Error',
          description: error.message,
          status: 'error',
          duration: errorMessageDuration,
          isClosable: true,
        });
      } else if (errorMessage) {
        toast({
          title: 'Error',
          description: errorMessage,
          status: 'error',
          duration: errorMessageDuration,
          isClosable: true,
        });
      }
    }
    setLoading(false);
    return null;
  };

  const isCustomApiError = (error: Record<string, unknown>) =>
    ['name', 'message'].every((item) => error.hasOwnProperty(item));

  return {
    executeApiAction: useCallback(executeApiAction, [setLoading, toast, executeApiCall]),
    getNewAccessToken,
  };
};
