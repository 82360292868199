import { Link } from 'react-router-dom';
import { Box, Text, VStack, Icon, HStack, useColorModeValue } from '@chakra-ui/react';
import { FaFacebook, FaTiktok, FaInstagram } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import { ProjectSeller } from '../../../types/project-seller';

const ContactSocial = ({ seller }: { seller: ProjectSeller | undefined }) => {
  const bgColor = useColorModeValue('#FFFFFF', '#000000');
  const textColor = useColorModeValue('#000000', '#FFFFFF');

  return (
    <>
      <Box
        backgroundColor={bgColor}
        color={textColor}
        pt={10}
        maxWidth="550px"
        width="80%"
        ml="auto"
        mr="auto"
        pb={4}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <VStack spacing={2} align="center">
          <Text fontSize="xl" as="b">
            Kontaktujte nás
          </Text>
          {seller?.customLayout?.contactInfo?.url && <Text>{seller?.customLayout?.contactInfo?.url}</Text>}
          {seller?.customLayout?.contactInfo?.phone && <Text>{seller?.customLayout?.contactInfo?.phone}</Text>}
          {seller?.customLayout?.contactInfo?.email && <Text>{seller?.customLayout?.contactInfo?.email}</Text>}
        </VStack>
      </Box>
      <Box
        backgroundColor={bgColor}
        color={textColor}
        pt={10}
        maxWidth="550px"
        width="80%"
        ml="auto"
        mr="auto"
        pb={4}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <HStack justifyContent="center" spacing={4} pb={4}>
          {seller?.customLayout?.socialMedia?.facebook && (
            <Link to={seller?.customLayout?.socialMedia?.facebook} target="_blank">
              <Icon as={FaFacebook} w={6} h={6} />
            </Link>
          )}
          {seller?.customLayout?.socialMedia?.twitter && (
            <Link to={seller?.customLayout?.socialMedia?.twitter} target="_blank">
              <Icon as={FaXTwitter} w={6} h={6} />
            </Link>
          )}
          {seller?.customLayout?.socialMedia?.instagram && (
            <Link to={seller?.customLayout?.socialMedia?.instagram} target="_blank">
              <Icon as={FaInstagram} w={6} h={6} />
            </Link>
          )}
          {seller?.customLayout?.socialMedia?.tiktok && (
            <Link to={seller?.customLayout?.socialMedia?.tiktok} target="_blank">
              <Icon as={FaTiktok} w={6} h={6} />
            </Link>
          )}
        </HStack>
      </Box>
    </>
  );
};

export default ContactSocial;
