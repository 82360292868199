import {
  Heading,
  Text,
  Flex,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
} from '@chakra-ui/react';
import { BorderCard } from '../../../generic/BorderCard';
import { useEffect, useState } from 'react';
import { WatchObserver, useFormContext } from 'react-hook-form';
import { FormShipmentValues } from '../../../../pages';
import { useTranslation } from 'react-i18next';

type FormNestedCardModalProps = {
  heading: string;
  name: WatchObserver<FormShipmentValues>;
  formType?: any;
  errors?: any;
  onRemove: () => void;
  placeholder?: React.ReactNode;
  children?: React.ReactNode;
};

export const FormNestedCardModal = ({ heading, name, onRemove, placeholder, children }: FormNestedCardModalProps) => {
  const { t } = useTranslation(['shipment', 'common']);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [visible, setVisible] = useState(false);

  const {
    watch,
    trigger,
    formState: { errors },
  } = useFormContext<FormShipmentValues>();

  useEffect(() => {
    // check if data is empty or {}, if so, open modal
    if (
      watch(name) &&
      (Object.keys(watch(name)).length === 0 || Object.values(watch(name)).every((v) => v === null || v === undefined))
    ) {
      onOpen();
    } else {
      setVisible(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch(name)]);

  return (
    <>
      {visible ? (
        <BorderCard mb={6}>
          <Flex justifyContent={'space-between'}>
            <Heading as="h2" size="md">
              {heading}
            </Heading>
            <Text
              as="u"
              size="sm"
              sx={{
                cursor: 'pointer',
              }}
              onClick={onOpen}
            >
              {t('common:edit.label')}
            </Text>
          </Flex>
          {placeholder}
        </BorderCard>
      ) : null}
      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} closeOnEsc={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{visible ? heading : ``}</ModalHeader>
          <ModalBody>{children}</ModalBody>
          <ModalFooter>
            <Button
              colorScheme="red"
              variant="ghost"
              mr={10}
              onClick={() => {
                onRemove();
                onClose();
              }}
            >
              {t('common:remove.label')}
            </Button>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={async () => {
                await trigger(name as any);
                console.log('errors save', errors);
                if (!errors || Object.keys(errors).length === 0) {
                  setVisible(true);
                  onClose();
                }
              }}
            >
              {t('common:save.label')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
