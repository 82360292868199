export const email =
  () => `<!DOCTYPE html PUBLIC "-//W3C//DTD HTML 4.0 Transitional//EN" "http://www.w3.org/TR/REC-html40/loose.dtd">
  <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office" style="width: 100%; max-width: 600px; height: auto; font-family: Arial, sans-serif; font-weight: 400; font-style: normal; background-color: #fbfaf9; color: black;">
        <head>
          <!--[if gte mso 9]><xml>
          <o:OfficeDocumentSettings>
          <o:AllowPNG/>
          <o:PixelsPerInch>96</o:PixelsPerInch>
          </o:OfficeDocumentSettings>
          </xml><![endif]-->
          <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1">
          <meta http-equiv="X-UA-Compatible" content="IE=edge">
          <meta name="format-detection" content="date=no">
          <meta name="format-detection" content="telephone=no">
          <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
          <style>html {
    width: 100%;
    max-width: 600px;
    height: auto;
    font-family: Arial, sans-serif;
    font-weight: 400;
    font-style: normal;
    background-color: #fbfaf9;
    color: black;
  }
  body {
    width: 100%;
    height: auto;
    margin: 0px auto;
    padding: 0;
    background-color: #fbfaf9;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }
  body * {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    text-rendering: geometricPrecision;
  }
  .preheader {
    font-size: 0px;
    line-height: 1px;
    mso-line-height-rule: exactly;
    display: none;
    max-width: 0px;
    max-height: 0px;
    opacity: 0;
    overflow: hidden;
    mso-hide: all;
  }
  .body {
    width: 100%;
    background-color: #fbfaf9;
    padding: 30px 0;
    font-family: Arial, sans-serif;
    padding: 0px;
    -webkit-text-size-adjust: none;
  }
  .body .email_template_holder {
    width: auto;
    max-width: 600px;
    margin: 0px auto;
    padding: 20px;
    background-color: white;
    border-radius: 5px;
  }
  .body .email_template_holder .logo {
    width: 120px;
    height: 45px;
    margin-bottom: 30px;
  }
  .body .email_template_holder .logo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: left center;
  }
  .body .email_template_holder h1 {
    padding: 0;
    margin: 0;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 26px;
  }
  .body .email_template_holder h2 {
    padding: 0;
    margin: 0;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 5px;
  }
  .body .email_template_holder h2.review_product_title {
    margin-top: 20px;
  }
  .body .email_template_holder p {
    padding: 0;
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
  }
  .body .email_template_holder p.heading_desc {
    margin-bottom: 15px;
  }
  .body .email_template_holder .footer_greetings {
    text-align: center;
  }
  .body .email_template_holder a {
    text-decoration: none;
    color: inherit;
  }
  .body .email_template_holder a :hover {
    text-decoration: none;
  }
  .body .email_template_holder .bold {
    font-weight: 700;
  }
  .body .email_template_holder .small {
    font-size: 85%;
  }
  .body .email_template_holder .clearfix {
    clear: both;
  }
  .body .email_template_holder .section_separator {
    width: 100%;
    max-width: 600px;
    height: 1px;
    background-color: {{primary_color}};
    margin-top: 25px;
    margin-bottom: 25px;
  }
  .body .email_template_holder .big_banner {
    width: 100%;
    height: auto;
    margin-bottom: 40px;
  }
  .body .email_template_holder .contact_info_holder .info_item {
    width: 33%;
    max-width: 600px;
    float: left;
  }
  .body .email_template_holder .contact_info_holder .info_item.side_padding {
    padding: 0 5px;
  }
  .body .email_template_holder .contact_info_holder table {
    width: 100%;
    max-width: 600px;
  }
  .body .email_template_holder .contact_info_holder table tr td {
    vertical-align: top;
  }
  .body .email_template_holder .products_list {
    width: 100%;
    margin-top: 15px;
  }
  .body .email_template_holder .products_list .product_image_td {
    width: 60px;
  }
  .body .email_template_holder .products_list .product_image_td .product_image {
    width: 60px;
    height: 77px;
    background-color: grey;
    border-radius: 3px;
  }
  .body .email_template_holder .products_list .product_image_td .product_image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
  .body .email_template_holder .products_list table {
    width: 100%;
  }
  .body .email_template_holder .products_list table tr td {
    padding-bottom: 10px;
  }
  .body .email_template_holder .products_list table tr td p {
    line-height: normal;
  }
  .body .email_template_holder .products_list table tr td.price_td {
    width: 10px;
    white-space: nowrap;
  }
  .body .email_template_holder .products_list table tr td .price {
    border-left: 1px solid black;
    padding-left: 10px;
    text-align: right;
  }
  .body .email_template_holder .products_list table tr td .price .standard_price {
    text-decoration: line-through;
    color: #6f6f6f;
    padding-left: 5px;
  }
  .body .email_template_holder .products_list table tr td .breakLine_mobile {
    display: none;
  }
  .body .email_template_holder .price_summary {
    width: 100%;
    padding: 20px;
    border-radius: 8px;
    background-color: #ebebeb;
    float: right;
    margin-top: 20px;
  }
  .body .email_template_holder .price_summary table {
    width: 100%;
    max-width: 600px;
  }
  .body .email_template_holder .price_summary table tr td {
    font-size: 16px;
    vertical-align: baseline;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .body .email_template_holder .price_summary table tr td.value {
    font-weight: 700;
    padding-left: 10px;
    text-align: right;
  }
  .body .email_template_holder .price_summary table tr td.value.total_price {
    font-size: 24px;
  }
  @media screen and (max-width: 500px) {
    .body .email_template_holder .price_summary table tr td.value.total_price {
      font-size: 18px !important;
    }
  }
  .body .email_template_holder .frequent_questions .column {
    width: 100%;
    max-width: calc($maxWidth - 125px);
    float: left;
  }
  .body .email_template_holder .frequent_questions .column.heading {
    width: 125px;
    padding-right: 50px;
  }
  .body .email_template_holder .info_table table {
    width: 100%;
    max-width: 600px;
    border-radius: 10px;
    box-shadow: 0 0 0 1px #979797;
    overflow: hidden;
  }
  .body .email_template_holder .info_table table tr th {
    padding-left: 20px;
  }
  .body .email_template_holder .info_table table tr td {
    height: 50px;
    text-align: right;
    padding-right: 20px;
  }
  .body .email_template_holder .info_table table tr td.qr_img img {
    width: 120px;
    height: 120px;
    object-fit: contain;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .body .email_template_holder .info_table table tr:nth-child(even) th, .body .email_template_holder .info_table table tr:nth-child(even) td {
    background-color: #F0F0F0;
  }
  .body .email_template_holder .info_table_compact {
    padding: 10px;
    background-color: #f0f0f0;
    border-radius: 8px;
  }
  .body .email_template_holder .info_table_compact table tr th {
    font-weight: 400;
    text-align: left;
    width: 10px;
    white-space: nowrap;
    padding-right: 15px;
  }
  .body .email_template_holder .info_table_compact table tr td {
    font-weight: 700;
  }
  .body .email_template_holder .buttons_holder .button {
    border: none;
    width: 100%;
    max-width: 330px;
    height: 64px;
    font-size: 24px;
    font-weight: 700;
    color: white;
    display: block;
    margin: 5px auto;
    border-radius: 8px;
    background-color: black;
  }
  .body .email_template_holder .buttons_holder .button.primary {
    background-color: {{primary_color}};
  }
  @media screen and (max-width: 480px) {
    .body .email_template_holder .contact_info_holder .info_item {
      width: 100% !important;
      max-width: 600px;
      margin-bottom: 10px !important;
    }
    .body .email_template_holder .contact_info_holder .info_item.side_padding {
      padding: 0 !important;
    }
    .body .email_template_holder .products_list table tr td .standard_price {
      display: block !important;
    }
    .body .email_template_holder .price_summary {
      width: 100% !important;
      max-width: 600px;
    }
    .body .email_template_holder .frequent_questions .column {
      width: 100% !important;
      max-width: 600px;
    }
    .body .email_template_holder .frequent_questions .column.heading {
      width: 100% !important;
      max-width: 600px;
      padding-right: 0px !important;
    }
  }
  </style>
        </head>
        <body style="width: 100%; height: auto; margin: 0px auto; padding: 0; background-color: #fbfaf9; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;"><div class="body" style="-moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box; text-rendering: geometricPrecision; width: 100%; background-color: #fbfaf9; padding: 0px; font-family: Arial, sans-serif; -webkit-text-size-adjust: none;">
    <div class="email_template_holder" style="-moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box; text-rendering: geometricPrecision; width: auto; max-width: 600px; margin: 0px auto; padding: 20px; background-color: white; border-radius: 5px;">
      <div class="logo" style="-moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box; text-rendering: geometricPrecision; width: 120px; height: 45px; margin-bottom: 30px;">
        <img src="{{logo}}" width="120" height="45" style="-moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box; text-rendering: geometricPrecision; width: 100%; height: 100%; object-fit: contain; object-position: left center;">
      </div>
      <h1 style="-moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box; text-rendering: geometricPrecision; padding: 0; margin: 0; font-size: 24px; font-weight: 700; margin-bottom: 26px;">{{email_title}}</h1>
      <p class="heading_desc" style="-moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box; text-rendering: geometricPrecision; padding: 0; margin: 0; font-size: 14px; font-weight: 500; line-height: 22px; margin-bottom: 15px;">
        {{email_content}}
      </p>
      <div class="info_table_compact" style="-moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box; text-rendering: geometricPrecision; padding: 10px; background-color: #f0f0f0; border-radius: 8px;">
        <table style="-moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box; text-rendering: geometricPrecision;">
          <tr style="-moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box; text-rendering: geometricPrecision;">
            <th style="-moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box; text-rendering: geometricPrecision; font-weight: 400; text-align: left; width: 10px; white-space: nowrap; padding-right: 15px;">Odesílatel</th>
            <td style="-moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box; text-rendering: geometricPrecision; font-weight: 700;">{{sender_name}}</td>
          </tr>
          <tr style="-moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box; text-rendering: geometricPrecision;">
            <th style="-moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box; text-rendering: geometricPrecision; font-weight: 400; text-align: left; width: 10px; white-space: nowrap; padding-right: 15px;">Číslo zásilky</th>
            <td style="-moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box; text-rendering: geometricPrecision; font-weight: 700;">{{tracking_number}}</td>
          </tr>
          <tr style="-moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box; text-rendering: geometricPrecision;">
            <th style="-moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box; text-rendering: geometricPrecision; font-weight: 400; text-align: left; width: 10px; white-space: nowrap; padding-right: 15px;">Platba</th>
            <td style="-moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box; text-rendering: geometricPrecision; font-weight: 700;">{{payment_info}}</td>
          </tr>
        </table>
      </div>
  
      <div class="buttons_holder" style="margin-top: 20px; margin-bottom: 20px; -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box; text-rendering: geometricPrecision;">
        <a href="{{tracking_link}}" style="-moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box; text-rendering: geometricPrecision; text-decoration: none; color: inherit;"><button class="button primary" style="-moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box; text-rendering: geometricPrecision; border: none; width: 100%; max-width: 330px; height: 64px; font-size: 24px; font-weight: 700; color: white; display: block; margin: 5px auto; border-radius: 8px; background-color: {{primary_color}};">Přejít na sledování zásilky</button></a>
      </div>
  
      <img src="{{banner_url}}" class="big_banner" style="-moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box; text-rendering: geometricPrecision; width: 100%; height: auto; margin-bottom: 40px;">
  
      <p class="heading_desc footer_greetings" style="-moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box; text-rendering: geometricPrecision; padding: 0; margin: 0; font-size: 14px; font-weight: 500; line-height: 22px; margin-bottom: 15px; text-align: center;">
        <b style="-moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box; text-rendering: geometricPrecision;">Kontaktujte nás</b><br>
        {{store_url}}<br>
        {{store_phone}}<br>
        {{store_email}}
      </p>
  
    </div>
  </div></body>
  </html>
    `;
