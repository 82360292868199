import {
  Button,
  useDisclosure,
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from '@chakra-ui/react';
import { RxHamburgerMenu } from 'react-icons/rx';
import { Shipment, ShipmentStatus } from '../../../types/shipment';
import { useCallback, useState } from 'react';
import { useProjectShipmentActions } from '../../../hooks';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useLoadingContext } from '../../../providers';
import { LoadingPage } from '../../../pages';

const Step = ({ stepNumber, children }: { stepNumber: number; children: React.ReactNode }) => (
  <Box>
    <Text fontSize="lg" fontWeight="bold" mb={2}>
      Step {stepNumber}
    </Text>
    {children}
  </Box>
);

const ModalProgressItem = ({ title, description }: { title: string; description?: string }) => (
  // Container with flex and gap and separator
  <Table variant="simple" size="sm" mb={10}>
    <Thead>
      <Tr>
        <Th>Action</Th>
        <Th>Detail</Th>
      </Tr>
    </Thead>
    <Tbody>
      <Tr>
        <Td>
          <Text fontWeight="bold">{title}</Text>
        </Td>
        <Td>
          <Box>
            <Text fontWeight="normal">{description}</Text>
          </Box>
        </Td>
      </Tr>
    </Tbody>
  </Table>
);

type ModalShippedParcelTableProps = {
  id: string;
  status: string;
  message?: string;
  referenceNumber?: string;
  showEmoji?: boolean;
}[];

const ModalShippedParcelTable = ({ data }: { data: ModalShippedParcelTableProps }) => {
  const { t } = useTranslation(['shipment']);
  return (
    <Table variant="simple" size="sm" mb={10}>
      <Thead>
        <Tr>
          <Th>{t('shipment:actions.table.parcel') /**Parcel */}</Th>
          <Th>{t('shipment:actions.table.details') /**Details */}</Th>
        </Tr>
      </Thead>
      <Tbody>
        {data.map((parcel) => (
          <Tr key={parcel.id}>
            <Td>
              <Text fontWeight="bold">{parcel.referenceNumber || parcel.id}</Text>
            </Td>
            <Td>
              <Box>
                <Text fontWeight="bold">
                  {parcel.showEmoji ? (parcel.status === 'success' ? '✅' : ' ❌') : parcel.status}
                </Text>
                <Text fontWeight="normal">{parcel.message || ''}</Text>
              </Box>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};
type ShipmentActionsProps = {
  selected: string[];
  shipments: Shipment[];
  mutateShipments: () => void;
};

const ShipmentActions = ({ selected, shipments, mutateShipments }: ShipmentActionsProps) => {
  const { onToggle } = useDisclosure();
  const { id: projectId } = useParams();
  const { generateShipmentLabels, generateShipmentWaybill, shipShipment, pickupShipment, statusShipment } =
    useProjectShipmentActions();
  const { isOpen, onToggle: onToggleModal } = useDisclosure();
  const { t } = useTranslation(['shipment', 'common']);
  const [modalData, setModalData] = useState<React.ReactNode[]>([]);
  const { setLoading, loading } = useLoadingContext();
  const downloadFile = async (base64: string, name: string) => {
    console.log('downloading file', name);
    base64 = `data:application/pdf;base64,${base64}`;
    const response = await fetch(base64);
    const blob = await response.blob();
    const downloadUrl = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = downloadUrl;
    a.download = `${name}_${dayjs().format('DDMMYYYYHHmm')}.pdf`; // specify the download file name
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(downloadUrl);
  };

  const filteredShipments = useCallback(
    (status: ShipmentStatus | ShipmentStatus[]) => {
      if (!shipments) return null;
      const selectedShipments = shipments.filter((shipment) => selected.includes(shipment.id));
      return selectedShipments.filter((shipment) =>
        !Array.isArray(status) ? shipment.status === status : status.includes(shipment.status)
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selected, shipments]
  );

  const generateLabels = useCallback(
    async (shipments: Shipment[]) => {
      console.log(shipments);
      const ids = shipments.map((shipment) => shipment.id);
      setLoading(true);
      const response = await generateShipmentLabels(projectId as string, ids);
      setLoading(false);
      console.log(response);
      if (!response) throw new Error(t('shipment:actions.error.no-response') /*"No respose from the server"*/);
      const keys = Object.keys(response.responsesByCarrier);
      keys.forEach((key) => {
        const base64 = response.responsesByCarrier[key];
        downloadFile(base64, `${key}_label`);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [shipments, selected]
  );

  const generateWaybill = useCallback(
    async (shipments: Shipment[]) => {
      console.log('shipments', shipments);

      const ids = shipments.map((shipment) => shipment.id);
      setLoading(true);
      console.log('ids', ids);
      const response = await generateShipmentWaybill(projectId as string, ids);
      setLoading(false);
      console.log(response);
      if (!response) throw new Error(t('shipment:actions.error.no-response') /*"No respose from the server"*/);
      const keys = Object.keys(response.responsesByCarrier);
      for (const key of keys) {
        const base64 = response.responsesByCarrier[key];
        await downloadFile(base64, `${key}_waybill`);
        // little delay to allow the download to finish
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [shipments, selected]
  );

  const shipShipments = useCallback(
    async (shipments: Shipment[]) => {
      console.log(shipments);
      onToggleModal();
      const parcels = shipments.flatMap((shipment) => shipment.parcels.map((parcel) => parcel));
      setModalData((prev) => [
        ...prev,
        <ModalProgressItem
          key="sending"
          title={t('shipment:actions.action.ship-shipment.sending') /*"Sending parcels to carrier"*/}
          description={`${t('shipment:actions.action.count')}: ${parcels?.length}`}
        />,
      ]);
      const ids = shipments.map((shipment) => shipment.id);
      const response = await shipShipment(projectId as string, ids).then((data) => data);
      console.log(response);
      if (!response) return;
      // response should be in the form of { responsesByCarrier: { [carrier]: {id: string; status: string; message?: string} }
      const keys = Object.keys(response.responsesByCarrier);
      const items: ModalShippedParcelTableProps = [];
      keys.forEach((key) => {
        response.responsesByCarrier[key].forEach((parcel) => {
          const { id, status, message } = parcel;
          const referenceNumber = parcels.find((parcel) => parcel.id === id)?.referenceNumber;
          items.push({ id, status, message, referenceNumber });
        });
      });
      setModalData((prev) => [...prev, <ModalShippedParcelTable key="shipped" data={items} />]);
      setModalData((prev) => [
        ...prev,
        <ModalProgressItem
          key="done"
          title={t('shipment:actions.action.done') /**Done */}
          description={`${t('shipment:actions.action.count')}: ${items?.length}`}
        />,
      ]);
      await mutateShipments();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [shipments, selected]
  );

  const pickupShipments = useCallback(
    async (shipments: Shipment[]) => {
      console.log(shipments);
      onToggleModal();
      const parcels = shipments.flatMap((shipment) => shipment.parcels.map((parcel) => parcel));
      setModalData((prev) => [
        ...prev,
        <ModalProgressItem
          key="sending"
          title={t('shipment:actions.action.pickup-shipment.sending') /*"Sending request to carrier"*/}
          description={`${t('shipment:actions.action.count')}: ${parcels?.length}`}
        />,
      ]);
      const ids = shipments.map((shipment) => shipment.id);
      const response = await pickupShipment(projectId as string, ids).then((data) => data);
      console.log(response);
      if (!response) return;
      // response should be in the form of { responsesByCarrier: { [carrier]: {id: string; status: string; message?: string} }
      const keys = Object.keys(response.responsesByCarrier);
      const items: ModalShippedParcelTableProps = [];
      keys.forEach((key) => {
        response.responsesByCarrier[key].forEach((parcel) => {
          const { id, status, message } = parcel;
          const referenceNumber = parcels.find((parcel) => parcel.id === id)?.referenceNumber;
          items.push({ id, status, message, referenceNumber });
        });
      });
      setModalData((prev) => [...prev, <ModalShippedParcelTable key="shipped" data={items} />]);
      setModalData((prev) => [
        ...prev,
        <ModalProgressItem
          key="done"
          title={t('shipment:actions.action.done') /**Done */}
          description={`${t('shipment:actions.action.count')}: ${items?.length}`}
        />,
      ]);
      await mutateShipments();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [shipments, selected]
  );

  const updateShipments = useCallback(
    async (shipments: Shipment[]) => {
      console.log(shipments);
      onToggleModal();
      const parcels = shipments.flatMap((shipment) => shipment.parcels.map((parcel) => parcel));
      setModalData((prev) => [
        ...prev,
        <ModalProgressItem
          key="sending"
          title={t('shipment:actions.action.update-shipment.sending') /*"Sending update request to carrier"*/}
          description={`${t('shipment:actions.action.count')}:: ${parcels?.length}`}
        />,
      ]);
      const ids = shipments.map((shipment) => shipment.id);
      const response = await statusShipment(projectId as string, ids);
      console.log(response);
      if (!response) throw new Error(t('shipment:actions.error.no-response') /*"No respose from the server"*/);
      // response should be in the form of { responsesByCarrier: { [carrier]: {id: string; status: string; message?: string} }
      const keys = Object.keys(response.responsesByCarrier);
      const items: ModalShippedParcelTableProps = [];
      keys.forEach((key) => {
        response.responsesByCarrier[key].forEach((parcel) => {
          const { id, status, message } = parcel;
          const referenceNumber = parcels.find((parcel) => parcel.id === id)?.referenceNumber;
          items.push({ id, status, message, referenceNumber, showEmoji: false });
        });
      });
      setModalData((prev) => [...prev, <ModalShippedParcelTable key="statuses" data={items} />]);
      setModalData((prev) => [
        ...prev,
        <ModalProgressItem
          key="done"
          title={t('shipment:actions.action.done') /**Done */}
          description={`${t('shipment:actions.action.count')}: ${items?.length}`}
        />,
      ]);
      await mutateShipments();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [shipments, selected]
  );

  const actions: {
    label: string;
    value: string;
    allowedStatus: ShipmentStatus | ShipmentStatus[];
    action: (shipments: Shipment[]) => void;
  }[] = [
    {
      label: t('shipment:actions.send-to-carrier'), //'Send to carrier',
      value: 'send-to-carrier',
      allowedStatus: [ShipmentStatus.Error, ShipmentStatus.Imported, ShipmentStatus.Processing],
      action: shipShipments,
    },
    {
      label: t('shipment:actions.pickup-data-from-carrier'), //'Pick up data from carrier',
      value: 'send-to-carrier',
      allowedStatus: [ShipmentStatus.Processing],
      action: pickupShipments,
    },
    {
      label: t('shipment:actions.generate-label'), //'Generate label',
      value: 'generate-label',
      allowedStatus: ShipmentStatus.Sent,
      action: generateLabels,
    },
    {
      label: t('shipment:actions.generate-waybill'), //'Generate waybill',
      value: 'generate-waybill',
      allowedStatus: ShipmentStatus.Sent,
      action: generateWaybill,
    },
    {
      label: t('shipment:actions.update-status'), //'Update status',
      value: 'update-status',
      allowedStatus: ShipmentStatus.Sent,
      action: updateShipments,
    },
  ];

  const modalClose = () => {
    setModalData([]);
    onToggleModal();
  };

  if (loading) return <LoadingPage />;

  return (
    <>
      <Menu>
        <Flex minWidth="max-content" alignItems="center" gap="2">
          <MenuButton
            as={Button}
            aria-label="Actions"
            size="xs"
            variant="ghost"
            leftIcon={<RxHamburgerMenu />}
            onClick={onToggle}
          >
            {t('shipment:actions.label') /* Actions */}
          </MenuButton>
        </Flex>
        <MenuList>
          {actions.map(({ label, value, allowedStatus, action }) => {
            const shipments = filteredShipments(allowedStatus);
            return (
              <MenuItem
                key={value}
                isDisabled={shipments?.length === 0}
                onClick={async () => {
                  if (!shipments) return;
                  await action(shipments);
                }}
              >
                {label} ({shipments?.length})
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>
      <Modal isOpen={isOpen} onClose={modalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('shipment:actions.progress') /* Progress */}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {modalData.map((data, index) => (
              <Step key={`step-${index}`} stepNumber={index + 1}>
                {data}
              </Step>
            ))}
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={modalClose}>
              {t('common:close.label') /* Close */}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ShipmentActions;
