import { useMemo, useCallback } from 'react';
import { Box, Text, Image, Link } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Shipment } from '../../../../types/shipment';

const TrackingNumber = ({ shipment }: { shipment: Shipment }) => {
  const carrierLogo = useMemo(() => {
    switch (shipment?.carrier) {
      case 'PACKETA':
        return '/images/carriers/packeta.png';
      case 'PPL':
        return '/images/carriers/ppl.png';
      case 'CESKAPOSTA':
        return '/images/carriers/ceska-posta.png';
      default:
        return '';
    }
  }, [shipment?.carrier]);

  const trackingLink = useCallback(
    (trackingNumber: string) => {
      switch (shipment?.carrier) {
        case 'PACKETA':
          return `https://tracking.app.packeta.com/cs/${trackingNumber}`;
        case 'PPL':
          return `https://www.ppl.cz/vyhledat-zasilku?shipmentId=${trackingNumber}`;
        case 'CESKAPOSTA':
          return `https://www.postaonline.cz/trackandtrace/-/zasilka/cislo?parcelNumbers=${trackingNumber}`;
        default:
          return '';
      }
    },
    [shipment?.carrier]
  );

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center" mt={20} px={2} py={2}>
        <Image src={carrierLogo} height="30px" />
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" px={2} py={2}>
        <Text fontSize="ml" as="b">
          {shipment?.parcels.length || 0 > 1 ? 'Sledovací čísla' : 'Sledovací číslo'}
        </Text>
        {shipment?.parcels?.map((p) => {
          if (!p.trackingNumber) {
            return null;
          }
          return (
            <Link href={trackingLink(p.trackingNumber)} isExternal>
              {p.trackingNumber} <ExternalLinkIcon mx="2px" />
            </Link>
          );
        })}
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" px={2} py={2}>
        <Text fontSize="ml" as="b">
          Zásilek
        </Text>
        <Text fontSize="ml">{shipment?.parcels?.length}</Text>
      </Box>
    </>
  );
};

export default TrackingNumber;
