import React from 'react';
import { ProjectSeller } from '../../../types/project-seller';
import { Shipment } from '../../../types/shipment';
import { TrackingEmailGenerator } from '../../../utils/tracking-email';
import { Payment } from '../../../types/shipment';

const EmailData = ({ shipment, seller }: { shipment: Shipment; seller: ProjectSeller }) => {
  const emailGenerator = new TrackingEmailGenerator({
    email: {
      title: 'Vaše zásilka byla odeslána',
      content: 'Vaše zásilka byla odeslána a je na cestě k vám.',
    },
    recipient: shipment.recipient,
    trackingNumber: '123456789',
    trackingLink: `#`,
    shipmentId: shipment.id,
    payment: shipment.payment || ({} as Payment),
    locale: seller.language,
    seller,
  });

  const htmlBody = emailGenerator.getHtml();
  const iframeRef = React.useRef<HTMLIFrameElement>(null);

  React.useEffect(() => {
    if (iframeRef.current && iframeRef.current.contentDocument) {
      const doc = iframeRef.current.contentDocument;
      const style = doc.createElement('style');
      style.textContent = `
      body, html {
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 40px;
      }
    `;
      doc.head.appendChild(style);
      doc.body.innerHTML = htmlBody;
    }
  }, [htmlBody]);

  return (
    <>
      <iframe
        ref={iframeRef}
        style={{
          width: '100%',
          height: '100vh',
          border: 'none',
        }}
        title="Email Content"
      />
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'transparent',
          zIndex: 10, // Ensure this is above the iframe
        }}
      ></div>
    </>
  );
};

export default EmailData;
