import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  InputLeftAddon,
  InputGroup,
  InputRightAddon,
  Heading,
  Switch,
  useDisclosure,
  SimpleGrid,
} from '@chakra-ui/react';
import { FaFacebook, FaTiktok, FaInstagram, FaYoutube } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import { MdShoppingCart } from 'react-icons/md';
import { IoMdSave } from 'react-icons/io';

import { AuthenticatedLayout } from '../layouts';
import { FileInput } from '../components/forms/inputs/FileInput';
import { PageHeading } from '../components/generic/PageHeading';
import { FormCard } from '../components/project-shipments/detail/create/FormCard';
import { PrivilegeContainer } from '../components/generic/PrivilegeContainer';
import { CustomLayoutJson, ProjectSeller } from '../types';
import { useProjectsSellersContext } from '../providers';
import { usePrivileges, Entity, Operation } from '../hooks/roles';
import { useProjectSellersActions } from '../hooks/project-sellers-actions';
import TrackingPageData from '../components/seller-ui/tracking/TrackingPageData';
import { DonutState } from '../components/seller-ui/tracking/DonutCircle';
import { ParcelStatusTypeEnum, Shipment, ShipmentStatus } from '../types/shipment';
import { useMetadataActions } from '../hooks/use-metadata-actions';
import SimpleModal from '../components/generic/SimpleModal';
import EmailData from '../components/seller-ui/tracking/EmailData';
import { useTranslation } from 'react-i18next';

type FormCustomLayoutValues = {
  title: string;
  logo_dark?: string;
  logo_light?: string;
  primaryColor?: string;
  backgroundImage?: string;
  banner?: string;
  bannerLink?: string;
  contactInfo?: {
    name?: string;
    url?: string;
    phone?: string;
    email?: string;
  };
  socialMedia?: {
    facebook?: string;
    instagram?: string;
    tiktok?: string;
    twitter?: string;
    youtube?: string;
  };
  allowedEmailNotifications?: string[];
  allowDarkMode?: boolean;
};

type FormProjectSellerValues = {
  name: string;
  language: string;
  customLayout: FormCustomLayoutValues;
};

export const ProjectSellersUISettingsPage: React.FC = () => {
  const { t } = useTranslation(['common', 'project']);
  const { sellers } = useProjectsSellersContext();
  const { id, sellerId } = useParams();
  const { updateProjectSeller } = useProjectSellersActions();
  const [selectedSeller, setSelectedSeller] = useState<ProjectSeller | null>(null);
  const privileges = usePrivileges();
  const [UI, setUI] = useState<'WEB' | 'EMAIL'>('WEB');
  const {
    isOpen: emailNotificationModalIsOpen,
    onOpen: emailNotificationModalOnOpen,
    onClose: emailNotificationModalOnClose,
  } = useDisclosure();
  const { config } = useMetadataActions();
  useEffect(() => {
    if (sellerId) {
      const seller = sellers?.find((seller: ProjectSeller) => seller.id === sellerId);
      setSelectedSeller(seller || null);
    } else {
      setSelectedSeller(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellerId]);
  console.log('selectedSeller', selectedSeller);
  const methods = useForm<FormProjectSellerValues>(
    selectedSeller
      ? {
          defaultValues: selectedSeller as FormProjectSellerValues,
        }
      : {}
  );

  // update form values when selectedSeller changes
  useEffect(() => {
    if (!selectedSeller) return;
    methods.reset(selectedSeller as FormProjectSellerValues);
  }, [selectedSeller, methods]);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = methods;

  const allowDarkMode = watch('customLayout.allowDarkMode');

  console.log('errors', errors);

  if (!id || !sellerId) return null;

  const onSubmit: SubmitHandler<FormProjectSellerValues> = async (data: FormProjectSellerValues) => {
    console.log('data', data);
    if (!selectedSeller) return;
    const resp = await updateProjectSeller({
      id: selectedSeller.id,
      customLayout: data.customLayout as CustomLayoutJson,
    });
    console.log('resp', resp);
  };

  // create mock shipment based on Shipment entity type
  // to be able to render TrackingPageData component
  const mockShipment: Shipment = {
    id: '1',
    createdAt: new Date(),
    updatedAt: new Date(),
    projectId: '1',
    reference: '1',
    carrier: 'PACKETA',
    status: ShipmentStatus.Sent,
    carrierService: '',
    carrierOptions: {},
    shipper: {
      firstName: 'John',
      lastName: 'Doe',
      email: 'j.doe@company.com',
      phone: '+420 123 456 789',
      street: 'Street',
      houseNumber: '1',
      city: 'City',
      country: 'CZ',
      postalCode: '12345',
    },
    recipient: {
      firstName: 'Jane',
      lastName: 'Doe',
      email: 'jane.doe@email.com',
      phone: '+420 123 456 789',
      street: 'Street',
      houseNumber: '1',
      city: 'City',
      country: 'CZ',
      postalCode: '12345',
    },
    parcels: [
      {
        id: '1',
        createdAt: new Date(),
        updatedAt: new Date(),
        weight: 1,
        height: 1,
        length: 1,
        width: 1,
        trackingNumber: '123456789',
        referenceNumber: '123456789',
        statuses: [
          {
            createdAt: new Date(),
            updatedAt: new Date(),
            type: 'SENT',
            statusObj: {
              type: ParcelStatusTypeEnum.DATA_SENT,
              title: {
                cs: 'Data přijata',
                en: 'Date received',
              },
              description: {
                cs: 'Data o zásilce byla přijata',
                en: 'Shipment data was received',
              },
              icon: 'https://example.com',
            },
          },
          {
            createdAt: new Date(),
            updatedAt: new Date(),
            type: 'HANDED_OVER',
            statusObj: {
              type: ParcelStatusTypeEnum.HANDED_OVER,
              title: {
                cs: 'Předáno dopravci',
                en: 'Handed over to carrier',
              },
              description: {
                cs: 'Zásilka byla předána dopravci',
                en: 'Shipment was handed over to carrier',
              },
              icon: 'https://example.com',
            },
          },
          {
            createdAt: new Date(),
            updatedAt: new Date(),
            type: 'OUT_FOR_DELIVERY',
            statusObj: {
              type: ParcelStatusTypeEnum.OUT_FOR_DELIVERY,
              title: {
                cs: 'Doručováno',
                en: 'Out for delivery',
              },
              description: {
                cs: 'Zásilka je doručována',
                en: 'Shipment is being delivered',
              },
              icon: 'https://example.com',
            },
          },
          {
            createdAt: new Date(),
            updatedAt: new Date(),
            type: 'DELIVERED',
            statusObj: {
              type: ParcelStatusTypeEnum.DELIVERED,
              title: {
                cs: 'Doručeno',
                en: 'Delivered',
              },
              description: {
                cs: 'Zásilka byla doručena',
                en: 'Shipment was delivered',
              },
              icon: 'https://example.com',
            },
          },
        ],
      },
    ],
  };

  const updateAllowedEmailNotifications = (event: string, checked: boolean) => {
    const currentValues = watch('customLayout.allowedEmailNotifications') || [];
    let newValues;
    if (checked) {
      // Add event if it's not already in the array
      newValues = currentValues.includes(event) ? currentValues : [...currentValues, event];
    } else {
      // Remove event if it's in the array
      newValues = currentValues.filter((e) => e !== event);
    }
    setValue('customLayout.allowedEmailNotifications', newValues, { shouldValidate: true });
  };

  return (
    <AuthenticatedLayout sidebar={false}>
      <Breadcrumb mb={4}>
        <BreadcrumbItem>
          <BreadcrumbLink href={`/projects/${id}/settings/sellers`}>
            {t('project:project-sellers.title') /* Sellers */}
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink href="#">{selectedSeller?.name}</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <PageHeading
        title={`${selectedSeller?.name} ${t('project:project-sellers.config.title') /**UI config*/}`}
        icon={MdShoppingCart}
      />
      <Flex flexDir="row">
        <FormCard heading={t('project:project-sellers.config.form.custom-ui.label') /**Custom UI*/}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl mb={2}>
              <FormLabel>{t('project:project-sellers.config.form.title.label') /**Title */}</FormLabel>
              <Input
                disabled={!privileges.PROJECTS.UPDATE}
                {...register(`customLayout.title`, { required: true })}
                placeholder="Company"
              />
            </FormControl>
            <FormControl mb={2} isInvalid={!!errors?.customLayout?.primaryColor?.type}>
              <FormLabel>{t('project:project-sellers.config.form.primary-color.label') /**Primary color */}</FormLabel>
              <InputGroup borderColor={errors?.customLayout?.primaryColor?.type ? 'red.500' : undefined}>
                <InputLeftAddon children="#" />
                <Input
                  disabled={!privileges.PROJECTS.UPDATE}
                  {...register(`customLayout.primaryColor`, {
                    required: false,
                    pattern: /^([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/,
                  })}
                  placeholder="000000"
                  defaultValue={`000000`}
                />
                <InputRightAddon
                  children={
                    <Box w="4" h="4" borderRadius="md" bg={`#${watch('customLayout.primaryColor')}` || '#000000'} />
                  }
                />
              </InputGroup>
              <FormHelperText>
                {
                  t(
                    'project:project-sellers.config.form.primary-color.helpertext'
                  ) /**Hexadecimal color code, example: */
                }{' '}
                <code>#000000</code>
              </FormHelperText>
            </FormControl>
            <FormControl mb={2}>
              <FormLabel>{t('project:project-sellers.config.form.logo.light.label') /**Logo (light) */}</FormLabel>
              <FormHelperText maxWidth="280px" pb={4}>
                {
                  t(
                    'project:project-sellers.config.form.logo.light.helper-text'
                  ) /**     This logo will be used for light backgrounds. It's not influenced by the dark mode setting. However,
                since e-mails have white background, this logo will be used. */
                }
              </FormHelperText>
              <FileInput
                level={'public'}
                existingImage={{ url: selectedSeller?.customLayout?.logo_light }}
                namePrefix={`${sellerId}/logo/light/`}
                onUpload={({ url }) => {
                  setValue('customLayout.logo_light', url);
                }}
              />
            </FormControl>
            <FormControl mb={2}>
              <FormLabel>{t('project:project-sellers.config.form.logo.dark.label') /**Logo (dark) */}</FormLabel>
              <FormHelperText maxWidth="280px" pb={4}>
                {
                  t(
                    'project:project-sellers.config.form.logo.dark.helper-text'
                  ) /**     This logo will be used for dark backgrounds. It's not influenced by the dark mode setting. However,
                  since tracking page have darker background behid logo, this logo will be used. */
                }
              </FormHelperText>
              <FileInput
                level={'public'}
                existingImage={{ url: selectedSeller?.customLayout?.logo_dark }}
                namePrefix={`${sellerId}/logo/dark/`}
                onUpload={({ url }) => {
                  setValue('customLayout.logo_dark', url);
                }}
              />
            </FormControl>
            <FormControl mb={2}>
              <FormLabel>
                {t('project:project-sellers.config.form.background-image.label') /**Background image */}
              </FormLabel>
              <FileInput
                level={'public'}
                existingImage={{ url: selectedSeller?.customLayout?.backgroundImage }}
                namePrefix={`${sellerId}/background-image/`}
                onUpload={({ url }) => {
                  setValue('customLayout.backgroundImage', url);
                }}
              />
            </FormControl>
            <FormControl mb={2}>
              <FormLabel>{t('project:project-sellers.config.form.banner.label') /**Banner */}</FormLabel>
              <FileInput
                disabled={!privileges.PROJECTS.UPDATE}
                existingImage={{ url: selectedSeller?.customLayout?.banner }}
                level={'public'}
                namePrefix={`${sellerId}/banner/`}
                onUpload={({ url }) => {
                  setValue('customLayout.banner', url);
                }}
              />
            </FormControl>
            {watch('customLayout.banner') && (
              <FormControl mb={2}>
                <FormLabel>{t('project:project-sellers.config.form.banner-link.label') /**Banner link */}</FormLabel>
                <Input
                  disabled={!privileges.PROJECTS.UPDATE}
                  {...register(`customLayout.bannerLink`, { required: false })}
                  placeholder="https://examplestore.com/offer"
                />
              </FormControl>
            )}
            <FormControl mb={2}>
              <FormLabel>{t('project:project-sellers.config.form.dark-mode.label') /**Allow dark mode*/}</FormLabel>
              <FormHelperText maxWidth="280px" pb={4}>
                {
                  t(
                    'project:project-sellers.config.form.dark-mode.helper-text'
                  ) /**Allow users to switch to dark mode. Works only in the WebUI. If you want to preview UI in different
                mode, switch the whole dashboard in the upper menu. */
                }
              </FormHelperText>
              <Switch
                disabled={!privileges.PROJECTS.UPDATE}
                colorScheme="green"
                isChecked={allowDarkMode} // Control the Switch with form state
                {...register(`customLayout.allowDarkMode`, { required: false })}
              />
            </FormControl>
            <Heading as="h2" size="md" mt={6} mb={4}>
              {t('project:project-sellers.config.form.contact.label') /**Contact*/}
            </Heading>
            <FormControl mb={2}>
              <FormLabel>{t('common:name.label') /**Name*/}</FormLabel>
              <Input
                disabled={!privileges.PROJECTS.UPDATE}
                {...register(`customLayout.contactInfo.name`, { required: false })}
                placeholder="Store Name"
              />
            </FormControl>
            <FormControl mb={2}>
              <FormLabel>{t('project:project-sellers.config.form.url.label') /**URL*/}</FormLabel>
              <Input
                disabled={!privileges.PROJECTS.UPDATE}
                {...register(`customLayout.contactInfo.url`, { required: false })}
                placeholder="https://examplestore.com"
              />
            </FormControl>
            <FormControl mb={2}>
              <FormLabel>{t('common:email.label')}</FormLabel>
              <Input
                disabled={!privileges.PROJECTS.UPDATE}
                {...register(`customLayout.contactInfo.email`, { required: false })}
                placeholder="support@examplestore.com"
              />
            </FormControl>
            <FormControl mb={2}>
              <FormLabel>{t('common:phone.label')}</FormLabel>
              <Input
                disabled={!privileges.PROJECTS.UPDATE}
                {...register(`customLayout.contactInfo.phone`, { required: false })}
                placeholder="+420 123 456 789"
              />
            </FormControl>
            <Heading as="h2" size="md" mt={6} mb={4}>
              {t('project:project-sellers.config.form.social.label') /**URL*/}
            </Heading>
            <FormControl mb={2}>
              <FormLabel>Facebook</FormLabel>
              <InputGroup>
                <InputLeftAddon children={<FaFacebook />} />
                <Input
                  disabled={!privileges.PROJECTS.UPDATE}
                  {...register(`customLayout.socialMedia.facebook`, {
                    required: false,
                  })}
                  placeholder="https://facebook.com/examplestore"
                />
              </InputGroup>
            </FormControl>
            <FormControl mb={2}>
              <FormLabel>Instagram</FormLabel>
              <InputGroup>
                <InputLeftAddon children={<FaInstagram />} />
                <Input
                  disabled={!privileges.PROJECTS.UPDATE}
                  {...register(`customLayout.socialMedia.instagram`, {
                    required: false,
                  })}
                  placeholder="https://instagram.com/examplestore"
                />
              </InputGroup>
            </FormControl>
            <FormControl mb={2}>
              <FormLabel>X</FormLabel>
              <InputGroup>
                <InputLeftAddon children={<FaXTwitter />} />
                <Input
                  disabled={!privileges.PROJECTS.UPDATE}
                  {...register(`customLayout.socialMedia.twitter`, {
                    required: false,
                  })}
                  placeholder="https://x.com/examplestore"
                />
              </InputGroup>
            </FormControl>
            <FormControl mb={2}>
              <FormLabel>TikTok</FormLabel>
              <InputGroup>
                <InputLeftAddon children={<FaTiktok />} />
                <Input
                  disabled={!privileges.PROJECTS.UPDATE}
                  {...register(`customLayout.socialMedia.tiktok`, {
                    required: false,
                  })}
                  placeholder="https://tiktok.com/examplestore"
                />
              </InputGroup>
            </FormControl>
            <FormControl mb={2}>
              <FormLabel>YouTube</FormLabel>
              <InputGroup>
                <InputLeftAddon children={<FaYoutube />} />
                <Input
                  disabled={!privileges.PROJECTS.UPDATE}
                  {...register(`customLayout.socialMedia.youtube`, {
                    required: false,
                  })}
                  placeholder="https://youtube.com/examplestore"
                />
              </InputGroup>
            </FormControl>
            <Heading as="h2" size="md" mt={6} mb={4}>
              {t('project:project-sellers.config.form.notifications.label') /**Notifications*/}
            </Heading>
            <FormControl mb={2}>
              <FormLabel>
                {t('project:project-sellers.config.form.notifications.form-label') /**Allowed notifications*/}
              </FormLabel>
              <FormHelperText maxWidth="280px" pb={4}>
                {
                  t(
                    'project:project-sellers.config.form.notifications.helper-text'
                  ) /**Select which notifications should be sent to the customer. If empty, all notifications will be sent.*/
                }
              </FormHelperText>
              <Button onClick={emailNotificationModalOnOpen} variant="ghost">
                {
                  t(
                    'project:project-sellers.config.form.notifications.setup-email-events-button'
                  ) /* Setup email events */
                }
              </Button>
              <SimpleModal
                isOpen={emailNotificationModalIsOpen}
                onOpen={emailNotificationModalOnOpen}
                onClose={emailNotificationModalOnClose}
              >
                <FormControl
                  as={SimpleGrid}
                  columns={{
                    base: 2,
                    lg: 2,
                  }}
                  gap={4}
                >
                  {config?.pracelStatusTypes?.map((event: string) => (
                    <>
                      <FormLabel>{`${event[0]}${event.toLowerCase().split('_').join(' ').slice(1)}`}</FormLabel>
                      <Switch
                        justifySelf="right"
                        mr={4}
                        colorScheme="green"
                        isChecked={watch('customLayout.allowedEmailNotifications')?.includes(event)}
                        onChange={(e) => updateAllowedEmailNotifications(event, e.target.checked)}
                      />
                    </>
                  ))}
                </FormControl>
              </SimpleModal>
            </FormControl>
            <PrivilegeContainer entity={Entity.PROJECTS} operation={Operation.UPDATE}>
              <Button
                rightIcon={<IoMdSave />}
                type="submit"
                mt={{
                  base: 8,
                  md: 8,
                }}
              >
                Save
              </Button>
            </PrivilegeContainer>
          </form>
        </FormCard>
        <Flex
          pl="4"
          overflow="auto"
          position="relative"
          transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
          transitionDuration=".2s, .2s, .35s"
          transitionProperty="top, bottom, width"
          transitionTimingFunction="linear, linear, ease"
          flexDir="column"
          flexGrow="1"
        >
          <Box alignItems="center" display="flex" justifyContent="center" mb="4" zIndex={20}>
            <Button mr="2" isActive={UI === 'WEB'} onClick={() => setUI('WEB')}>
              Web UI
            </Button>
            <Button isActive={UI === 'EMAIL'} onClick={() => setUI('EMAIL')}>
              Email UI
            </Button>
          </Box>
          {UI === 'WEB' ? (
            <TrackingPageData
              shipment={mockShipment}
              seller={
                {
                  ...selectedSeller,
                  customLayout: watch('customLayout') as CustomLayoutJson,
                } as ProjectSeller
              }
              donutStatus={DonutState.DELIVERED}
            />
          ) : (
            <EmailData
              shipment={mockShipment}
              seller={
                {
                  ...selectedSeller,
                  customLayout: watch('customLayout') as CustomLayoutJson,
                } as ProjectSeller
              }
            />
          )}
        </Flex>
      </Flex>
    </AuthenticatedLayout>
  );
};
