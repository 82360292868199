import { Box, Flex, Link, Text } from '@chakra-ui/react';
import { DragEventHandler, useEffect, useRef, useState } from 'react';
import { MdCloudUpload, MdFileCopy, MdWarning } from 'react-icons/md';
import { useProjectActions } from '../../../hooks';
import { useProjectsContext } from '../../../providers';

type ExistingImage = {
  url?: string;
  file?: File;
};

type FileInputProps = {
  description?: string;
  accept?: string;
  disabled?: boolean;
  level?: 'public' | 'protected' | 'private';
  children?: React.ReactNode;
  onUpload: ({ key, url }: { key?: string; url?: string }) => void;
  maxSize?: number;
  allowedDimension?: {
    width: number;
    height: number;
  };
  existingImage?: ExistingImage;
  namePrefix?: string;
  sx?: Object;
};

enum FileUploadState {
  UPLOAD = 'UPLOAD',
  UPLOADING = 'UPLOADING',
  COMPLETED = 'COMPLETED',
  ERROR = 'ERROR',
}

type ImagePreviewProps = {
  file?: File;
  url?: string;
} & ({ file: File } | { url: string });

const ImagePreview: React.FC<ImagePreviewProps> = ({ file, url }) => {
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);

  useEffect(() => {
    if (url) {
      setPreviewUrl(url);
      return;
    } else if (file) {
      const objectUrl = URL.createObjectURL(file);
      setPreviewUrl(objectUrl);
      // Cleanup
      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [file, url]);

  if (!previewUrl) return null;

  return (
    <img
      src={previewUrl}
      alt={file?.name || 'Image preview'}
      style={{ maxWidth: '200px', maxHeight: '150px', marginBottom: '20px' }}
    />
  );
};

const UploadContent = ({
  handleClick,
  description,
  accept,
  maxSize,
  allowedDimension,
  existingImage,
}: {
  handleClick: () => void | undefined;
  description?: string;
  accept?: string;
  maxSize?: number;
  allowedDimension?: { width: number; height: number };
  existingImage?: ExistingImage;
}) => (
  <>
    {existingImage && existingImage.url ? (
      <ImagePreview url={existingImage.url} />
    ) : existingImage && existingImage.file ? (
      <ImagePreview file={existingImage.file} />
    ) : null}
    <MdCloudUpload size={30} />
    <Text fontSize="sm" mt={2}>
      <Link onClick={handleClick} color="teal.500" as="b">
        Click to upload
      </Link>{' '}
      or drag and drop
    </Text>
    <Text fontSize="xs">{description}</Text>
    <Text fontSize="xs" mt={2}>
      {accept && `Accepted types: ${accept}`}
      {maxSize && ` Max size: ${maxSize / 1024 / 1024} MB`}
      {allowedDimension && ` Required dimensions: ${allowedDimension.width}x${allowedDimension.height}px`}
    </Text>
  </>
);

const UploadInProgressContent = ({ progress }: { progress: number }) => (
  <>
    <Text fontSize="lg" mt={2}>
      {`${progress}%`}
    </Text>
    <Text fontSize="sm" mt={2}>
      Upoading file..
    </Text>
  </>
);

const UploadCompletedContent = ({
  handleClick,
  fileName,
  onReset,
  file,
  allow,
  maxSize,
  allowedDimension,
}: {
  handleClick: () => void | undefined;
  fileName: string;
  onReset: () => void;
  file?: File;

  allow?: string;
  maxSize?: number;
  allowedDimension?: { width: number; height: number };
}) => (
  <>
    {file && file.type.startsWith('image/') && <ImagePreview file={file} />}
    <MdFileCopy size={30} />
    <Text fontSize="md" mt={2}>
      {fileName}
    </Text>
    <Text fontSize="sm" mt={2}>
      <Link onClick={handleClick} color="teal.500" as="b">
        Click to upload a new file
      </Link>{' '}
      or drag and drop
    </Text>
    <Text fontSize="xs" mt={2}>
      <Link onClick={onReset} color="teal.500" as="b">
        Reset
      </Link>
    </Text>
    <Text fontSize="xs" mt={2}>
      {allow && `Allowed types: ${allow}`}
      {maxSize && ` Max size: ${maxSize / 1024 / 1024} MB`}
      {allowedDimension && ` Required dimensions: ${allowedDimension.width}x${allowedDimension.height}px`}
    </Text>
  </>
);

const UploadErrorContent = () => (
  <>
    <MdWarning size={30} />
    <Text fontSize="md" mt={2}>
      Upload failed
    </Text>
    <Text fontSize="sm" mt={2}>
      Failed to upload the file
    </Text>
  </>
);

export const FileInput = ({
  accept,
  disabled,
  description,
  level,
  onUpload,
  maxSize,
  allowedDimension,
  existingImage,
  sx,
  namePrefix = '',
}: FileInputProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [dragActive, setDragActive] = useState(false);
  const [currentState, setCurrentState] = useState<FileUploadState>(FileUploadState.UPLOAD);
  const [progress, setProgress] = useState<number>(0);
  const [file, setFile] = useState<File | undefined>();
  const [, setError] = useState<string | undefined>();

  const { generateSignedS3Url } = useProjectActions();
  const { selectedProject } = useProjectsContext();
  const handleClick = () => inputRef.current?.click();

  const uploadFile = async (file: File) => {
    if (disabled) return;
    if (!selectedProject?.id) return;
    // const key = `${uuid()}.${file.name.split('.').pop()}`;
    const key = `${namePrefix}${file.name}`;
    setFile(file);
    setCurrentState(FileUploadState.UPLOADING);
    setProgress(0);

    const isValidTypeAndSize = validateFile(file);
    if (!isValidTypeAndSize) {
      setCurrentState(FileUploadState.ERROR);
      return;
    }

    const isValidDimensions = await validateImageDimensions(file);
    if (!isValidDimensions) {
      setCurrentState(FileUploadState.ERROR);
      return;
    }

    try {
      // Get the signed URL from your custom function
      const signedUrl = await generateSignedS3Url(selectedProject.id, key);
      console.log('signedUrl', signedUrl);
      if (!signedUrl) {
        setError('Failed to upload, could not obtain URL');
        setCurrentState(FileUploadState.ERROR);
        return;
      }
      // Create a new XMLHttpRequest
      const xhr = new XMLHttpRequest();

      // Set up the request
      xhr.open('PUT', signedUrl, true);
      xhr.setRequestHeader('Content-Type', file.type);
      console.log('about to send file', file);
      console.log('file.type', file.type);
      // Handle progress events
      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const percentage = Math.round((event.loaded / event.total) * 100);
          setProgress(percentage); // Update progress
          console.log('percentage', percentage);
        }
      };

      // remove all the query params from the signed url
      const mediaUrl = signedUrl.split('?')[0];

      // Handle the response
      xhr.onload = () => {
        console.log('handling response', xhr.status);
        if (xhr.status === 200) {
          setCurrentState(FileUploadState.COMPLETED);
          onUpload({ key, url: mediaUrl });
        } else {
          setError('Failed to upload');
          setCurrentState(FileUploadState.ERROR);
        }
      };

      // Handle errors
      xhr.onerror = () => {
        console.log('handling error');
        setError('Failed to upload');
        setCurrentState(FileUploadState.ERROR);
      };

      console.log('sending file');
      // Send the file
      xhr.send(file);
    } catch (error) {
      console.error('Error during file upload:', error);
      setError('Failed to upload');
      setCurrentState(FileUploadState.ERROR);
    }
  };

  const onReset = () => {
    setCurrentState(FileUploadState.UPLOAD);
    setProgress(0);
    setError(undefined);
  };

  const handleDrag: DragEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.type === 'dragenter' || event.type === 'dragover') {
      setDragActive(true);
    } else if (event.type === 'dragleave') {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop: DragEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(false);
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      uploadFile(event.dataTransfer.files[0]);
    }
  };

  const handleFileChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const files = event.target.files;
    if (files && files[0]) {
      const file = files[0];
      uploadFile(file);
    }
  };

  const renderContent = () => {
    if (disabled) {
      return (
        <>
          <MdCloudUpload size={30} color="gray" />
          <Text fontSize="sm" mt={2} color="gray">
            Uploads are disabled
          </Text>
        </>
      );
    }
    switch (currentState) {
      case FileUploadState.UPLOAD:
        return (
          <UploadContent
            handleClick={handleClick}
            description={description}
            accept={accept}
            allowedDimension={allowedDimension}
            maxSize={maxSize}
            existingImage={existingImage}
          />
        );
      case FileUploadState.UPLOADING:
        return <UploadInProgressContent progress={progress} />;
      case FileUploadState.COMPLETED:
        return (
          <UploadCompletedContent
            handleClick={handleClick}
            fileName={file?.name || 'Unknown file'}
            onReset={onReset}
            file={file}
          />
        );
      case FileUploadState.ERROR:
        return <UploadErrorContent />;
    }
  };

  const validateFile = (file: File): boolean => {
    // Check file type
    if (accept && !accept.includes(file.type)) {
      setError(`File type not allowed. Allowed types: ${accept}`);
      return false;
    }

    // Check file size
    if (maxSize && file.size > maxSize) {
      setError(`File is too large. Maximum size allowed is ${maxSize / 1024 / 1024} MB`);
      return false;
    }

    return true;
  };

  const validateImageDimensions = (file: File): Promise<boolean> => {
    return new Promise((resolve) => {
      if (!file.type.startsWith('image/')) {
        resolve(true);
        return;
      }

      const img = new Image();
      img.src = URL.createObjectURL(file);

      img.onload = () => {
        URL.revokeObjectURL(img.src);
        if (allowedDimension && (img.width !== allowedDimension.width || img.height !== allowedDimension.height)) {
          setError(`Image dimensions must be ${allowedDimension.width}x${allowedDimension.height}`);
          resolve(false);
        } else {
          resolve(true);
        }
      };

      img.onerror = () => {
        setError('Error loading image');
        resolve(false);
      };
    });
  };

  return (
    <Flex direction="column">
      <Box>
        <input type={'file'} multiple={false} hidden accept={accept} ref={inputRef} onChange={handleFileChange} />
        <Flex
          direction="column"
          justifyContent="center"
          alignItems="center"
          border="1px"
          boxSizing="border-box"
          borderStyle="solid"
          borderColor="inherit"
          borderRadius="md"
          outline={dragActive ? '2px solid' : undefined}
          padding={4}
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
          style={sx}
        >
          {renderContent()}
        </Flex>
      </Box>
    </Flex>
  );
};
