import { Shipper } from './shipper';
import { Recipient } from './recipient';
import { Parcel } from './parcel';
import { Payment } from './payment';
import { Insurance } from './insurance';
import { Customs } from './customs';

export enum ParcelStatusTypeEnum {
  EXCEPTION = 'EXCEPTION',
  DATA_SENT = 'DATA_SENT',
  ACCEPTED_BY_CARRIER = 'ACCEPTED_BY_CARRIER',
  HANDED_OVER = 'HANDED_OVER',
  IN_TRANSIT = 'IN_TRANSIT',
  OUT_FOR_DELIVERY = 'OUT_FOR_DELIVERY',
  STORED_FOR_PICKUP = 'STORED_FOR_PICKUP',
  PICKED_UP = 'PICKED_UP',
  DELIVERED = 'DELIVERED',
  DELIVERY_FAILED = 'DELIVERY_FAILED',
  RETURNED = 'RETURNED',
  CANCELED = 'CANCELED',
}

export enum ShipmentStatus {
  Imported = 'IMPORTED',
  Sent = 'SENT',
  Error = 'ERROR',
  Processing = 'PROCESSING',
  Finished = 'FINISHED',
}

export type Shipment = {
  id: string;
  projectId: string;
  carrier: string;
  carrierService: string;
  reference: string;
  note?: string;
  seller?: string;
  sellerId?: string;
  shipper: Shipper;
  recipient: Recipient;
  parcels: Parcel[];
  payment?: Payment;
  insurance?: Insurance;
  customs?: Customs;
  carrierOptions: { [key: string]: string | string[] } | undefined;
  status: ShipmentStatus;
  metadata?: { [key: string]: string | string[] } | undefined;
  createdAt: Date;
  updatedAt: Date;
};
