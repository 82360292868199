import { Table, Tbody, Tr, Td, TableContainer } from '@chakra-ui/react';
import { Shipment } from '../../../types/shipment';
import { ProjectShippingCarrierRequest } from '../../../types';
import { useTranslation } from 'react-i18next';

type GeneralSectionProps = {
  data: Shipment;
  projectShippingCarriers: ProjectShippingCarrierRequest[] | null;
};

export const GeneralSection: React.FC<GeneralSectionProps> = ({ data, projectShippingCarriers }) => {
  /** Tabs view of parcels with basic data and tracking */
  const { t } = useTranslation(['shipment', 'common']);

  const { id, reference, carrier, carrierService, note, carrierOptions, seller } = data;
  return (
    <TableContainer>
      <Table size="sm">
        <Tbody>
          <Tr>
            <Td width="10%">{t('shipment:information.id.label')}</Td>
            <Td>{id}</Td>
          </Tr>
          <Tr>
            <Td width="10%">{t('shipment:information.reference.label')}</Td>
            <Td>{reference}</Td>
          </Tr>
          <Tr>
            <Td width="10%">{t('shipment:carrier.carrier.label')}</Td>
            <Td>{carrier}</Td>
          </Tr>
          {carrierService && (
            <Tr>
              <Td width="10%">{t('shipment:carrier.service.label')}</Td>
              <Td>{carrierService}</Td>
            </Tr>
          )}

          {carrierOptions
            ? projectShippingCarriers
                ?.find((item) => item.shippingCarrier.id === carrier)
                ?.shippingCarrier?.shipmentCarrierOptions?.map((option) => {
                  const isArray = option.isArrayOfValues;
                  if (isArray) {
                    const values = carrierOptions[option.key] as string[];
                    return (
                      <Tr>
                        <Td width="10%">{option.label}</Td>
                        <Td>{values.join(', ')}</Td>
                      </Tr>
                    );
                  }
                  return (
                    <Tr>
                      <Td width="10%">{option.label}</Td>
                      <Td>{carrierOptions[option.key]}</Td>
                    </Tr>
                  );
                })
            : null}
          {note && (
            <Tr>
              <Td width="10%">{t('shipment:information.note.label') /* Note */}</Td>
              <Td>{note}</Td>
            </Tr>
          )}
          {seller && (
            <Tr>
              <Td width="10%">{t('shipment:information.seller.label') /* Seller */}</Td>
              <Td>{seller}</Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
