import { Input, InputGroup, InputRightElement, Box, useClipboard, Button } from '@chakra-ui/react';
import { useToast } from '@chakra-ui/react';
import { MdFileCopy } from 'react-icons/md';

type CodeTextFieldProps = {
  text: string;
};

const CodeTextField = (props: CodeTextFieldProps) => {
  const { text } = props;
  const { onCopy } = useClipboard(text);
  const toast = useToast();
  const handleClick = () => {
    onCopy();
    toast({
      title: 'Copied!',
      description: 'Your API key has been copied to the clipboard.',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <Box pt={2}>
      <InputGroup size="md">
        <Input
          type="text"
          value={text}
          pointerEvents={'none'}
          readOnly
          pr="4rem" // Add some padding to prevent text from overlapping the "copy" button
          fontSize="sm"
        />
        <InputRightElement color="gray.600" fontSize="sm" mr={2}>
          <Button h="1.75rem" size="sm" onClick={handleClick} variant={'outline'}>
            <MdFileCopy />
          </Button>
        </InputRightElement>
      </InputGroup>
    </Box>
  );
};

export default CodeTextField;
